import {
  campaignDetailsEvent,
  createdCampaignEvent,
  createdFirstCampaignEvent,
} from 'src/Helpers/TagManager'
import { CampaignPlatformTypeEnum } from 'src/Types/index'
import { createTiktokCampaignEvent } from 'src/Redux/googleAnalytics-process/api-actions'
import { AppDispatch } from 'src/Redux/types'
import { CreateCampaignWithoutSignupResponse } from 'src/Redux/auth-process/credentialsSlice/api-actions'
export const spotifyEventsHandler = (params: {
  res: CreateCampaignWithoutSignupResponse
}): void => {
  createdFirstCampaignEvent(CampaignPlatformTypeEnum.SPOTIFY, params.res)

  campaignDetailsEvent(
    CampaignPlatformTypeEnum.SPOTIFY,
    1,
    params.res.spotify_campaign.type,
  )

  createdCampaignEvent({
    isFirstCampaign: true,
    platformName: CampaignPlatformTypeEnum.SPOTIFY,
    campaignId: params.res.spotify_campaign.id,
    trackId: params.res.spotify_campaign.Track.id,
    userId: params.res.user.id,
    campaignNumber: 1,
    danceRequired: false,
    is_feed: Boolean(params.res.spotify_campaign.is_feed),
  })
}

export const tiktokEventsHandler = (params: {
  res: CreateCampaignWithoutSignupResponse
  dispatch: AppDispatch
}): void => {
  createdFirstCampaignEvent(CampaignPlatformTypeEnum.TIKTOK, params.res)

  campaignDetailsEvent(
    CampaignPlatformTypeEnum.TIKTOK,
    1,
    params.res.tiktok_campaign.type,
  )

  createdCampaignEvent({
    isFirstCampaign: true,
    platformName: CampaignPlatformTypeEnum.TIKTOK,
    campaignId: params.res.tiktok_campaign.id,
    trackId: params.res.tiktok_campaign.id,
    userId: params.res.user.id,
    campaignNumber: 1,
    is_feed: false,
  })

  void params.dispatch(
    createTiktokCampaignEvent({
      campaignID: Number(params.res.tiktok_campaign.id),
      url: window.location.href,
      token: params.res.token,
    }),
  )
}
