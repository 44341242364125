import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const SoundsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SoonContainer = styled.div`
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  svg {
    width: 57px;
    height: 57px;
  }
`

export const Text = styled.span`
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  text-align: center;
`
