import React, { useRef, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { balance, campaignsList } from 'src/Router/routes'
import IconSuccessfully from 'src/Assets/Svg/successfully.svg?react'
import IconProcessing from 'src/Assets/Svg/clock_icon.svg?react'
import Button from 'src/Components/Buttons/Button'
import { useHint } from 'src/Hooks/useHint'
import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { usePaymentCompletedEvents } from 'src/Hooks/usePaymentCompletedEvents'

import { useSuccessPage } from './useSuccessPage'

import {
  ButtonWrapper,
  CustomModal,
  GreenText,
  SuccessBlock,
  SuccessBlockBody,
  SuccessButtonBlock,
  SuccessCircle,
  SuccessText,
  TitleWrapper,
} from './styles'

interface CampaignIsScheduledComponentProps {
  startDate?: string | Date
  platformName: CampaignPlatformTypeEnum
}

export const SuccessPage: React.FC<CampaignIsScheduledComponentProps> = ({
  startDate,
  platformName,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setIsOpen } = useHint()
  const initialized = useRef<boolean>(false)

  const {
    isLoading,
    isUpsellButtonVisible,
    successMessage,
    upsellButtonText,
    onUpsellButtonClick,
    isSuccessPageVisible,
    isGoToBalanceButtonVisible,
  } = useSuccessPage(startDate)

  const { handlePaymentCompletedEvents } = usePaymentCompletedEvents()
  const { isNextTimeBonusReceived } = useBonusTooltipTimer()

  useEffect(() => {
    if (isSuccessPageVisible && !initialized.current) {
      initialized.current = true
      handlePaymentCompletedEvents({ platformName })
    }
  }, [
    initialized,
    isSuccessPageVisible,
    handlePaymentCompletedEvents,
    platformName,
  ])

  if (!isSuccessPageVisible) {
    return null
  }

  return (
    <CustomModal>
      <SuccessBlock>
        <SuccessCircle>
          {isLoading ? (
            <IconProcessing width={40} height={40} />
          ) : (
            <IconSuccessfully width={40} height={40} />
          )}
        </SuccessCircle>
        <SuccessBlockBody>
          <TitleWrapper>
            {isLoading ? (
              <span> {t('scheduleCampaignPage.processing')}</span>
            ) : (
              <>
                <span>{t('scheduleCampaignPage.awesome')}</span>
                <span role='img' aria-label='party'>
                  &#127881;
                </span>
              </>
            )}
          </TitleWrapper>

          {isLoading ? (
            <SuccessText>
              {t('scheduleCampaignPage.paymentProcessing')}
            </SuccessText>
          ) : (
            <SuccessText>{successMessage}</SuccessText>
          )}

          {isNextTimeBonusReceived && (
            <>
              <SuccessText>&</SuccessText>
              <SuccessText>
                Bonus <GreenText>$20</GreenText> are successfully added to your
                balance
              </SuccessText>
            </>
          )}
        </SuccessBlockBody>

        <SuccessButtonBlock>
          {isGoToBalanceButtonVisible && (
            <ButtonWrapper>
              <Button
                type='whiteWithGreenBorder'
                onClick={() => {
                  navigate(`${balance}`)
                  setIsOpen()
                }}
              >
                {t('scheduleCampaignPage.goToBalanceButton')}
              </Button>
            </ButtonWrapper>
          )}

          <ButtonWrapper>
            <Button
              type={isUpsellButtonVisible ? 'white' : 'green'}
              onClick={() => {
                navigate(`${campaignsList}`)
                setIsOpen()
              }}
            >
              {t('scheduleCampaignPage.goToCampaigns')}
            </Button>
          </ButtonWrapper>

          {isUpsellButtonVisible && (
            <ButtonWrapper>
              <Button type='green' onClick={onUpsellButtonClick}>
                {upsellButtonText}
              </Button>
            </ButtonWrapper>
          )}
        </SuccessButtonBlock>
      </SuccessBlock>
    </CustomModal>
  )
}

export default SuccessPage
