import { rtkApi } from 'src/Services/rtkApi'
import { APP } from 'src/Configs/App'

const userEmailApi = rtkApi.injectEndpoints({
  endpoints(build) {
    return {
      sendEmailToConfirmAccount: build.query<unknown, void>({
        query: () => ({
          url: `${APP.SERVER}/user/send-email`,
          method: 'GET',
        }),
      }),
    }
  },
})

export const { useLazySendEmailToConfirmAccountQuery } = userEmailApi
