import React, { ReactNode } from 'react'

import { useSelector } from 'react-redux'
// import { useLocation } from 'react-router-dom'

import {
  getCollapse,
  getIsAlertMode,
} from 'src/Redux/sidebar-process/selectors'
// import { campaignsList, details } from 'src/Router/routes'
import { Mobile } from 'src/Components/Responsive'
import { TopBarAlert } from 'src/Containers/Layout/TopBarAlert'

import { AlertWrapper, StyledPageContainer, InnerWrapper } from './styles'

export interface PageContainerProps {
  children: ReactNode
}

// export const DETAILS_PATH_LENGTH = 4

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  // const location = useLocation()

  const isMenuClosed = useSelector(getCollapse)
  const isAlertMode = useSelector(getIsAlertMode)

  const showTestingModeMobile = false
  //   location.pathname.includes(`${campaignsList}${details}`) &&
  //   location.pathname.split('/').length === DETAILS_PATH_LENGTH

  return (
    <>
      <Mobile>
        <AlertWrapper>
          <TopBarAlert />
        </AlertWrapper>
      </Mobile>
      <StyledPageContainer
        isMenuOpened={!isMenuClosed}
        isTestingMode={showTestingModeMobile}
        isAlertMode={isAlertMode}
      >
        <InnerWrapper>{children}</InnerWrapper>
      </StyledPageContainer>
    </>
  )
}
export default PageContainer
