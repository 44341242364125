import { SyntheticEvent, useEffect, useRef, useState } from 'react'

import { useSelector } from 'react-redux'
import SpotifyIcon from 'mdi-react/SpotifyIcon'
import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import { useAppDispatch } from 'src/Hooks/redux'
import {
  getArtistSpotifyConnections,
  setSpotifyConnectionForArtist,
} from 'src/Redux/artists-process/api-actions'
import { getSfaEmail } from 'src/Redux/artists-process/selectors'

import {
  Block,
  CopyButton,
  LeftBlock,
  List,
  SuccessButtonContainer,
  Text,
  TextArea,
  TextCopied,
  Video,
  Wrapper,
  StyledModal,
  GoToSpotifyWrapper,
  SpotifyForArtistButtonWrapper,
} from './styles'

const spotifyLink = 'https://artists.spotify.com/'

const link =
  'https://content-soundcamps.s3.eu-central-1.amazonaws.com/public/SFA+video+-+April+2023.mp4'

const SpotifyForArtistModal: React.FunctionComponent<{
  toggle: () => void
  modal: boolean
  artistSpotifyId?: string
}> = ({ toggle, modal, artistSpotifyId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const sfaEmail = useSelector(getSfaEmail)

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const [isCopied, setCopied] = useState(false)

  useEffect(() => {
    void dispatch(getArtistSpotifyConnections())
  }, [dispatch])

  const goToSpotify = (): void => {
    window.open(spotifyLink, '_blank', 'noopener, noreferrer')
  }

  function copyToClipboard(e: SyntheticEvent<HTMLButtonElement>): void {
    if (textAreaRef.current !== null) {
      textAreaRef.current.select()
      navigator.clipboard.writeText(sfaEmail || '')
      e.currentTarget.focus()
      setCopied(true)
    }
  }

  const handleSubmit = (): void => {
    artistSpotifyId &&
      void dispatch(
        setSpotifyConnectionForArtist({ artist_spotify_id: artistSpotifyId }),
      )
    toggle()
  }

  return (
    <div>
      <StyledModal
        open={modal}
        footer={null}
        title={t('campaignResultsPage.connectForArtists')}
        onCancel={toggle}
      >
        <Wrapper>
          <Text>
            <span>{t('campaignResultsPage.toSetUpConnection')}</span>
            <span>{t('campaignResultsPage.ofTheArtistPromoted')}</span> &nbsp;
            <span>{t('campaignResultsPage.usingTheEmail')}</span>&nbsp;
            <span>
              <a href={`mailto: ${sfaEmail}`}>{sfaEmail}</a>.
            </span>
          </Text>
          <Block>
            <LeftBlock>
              <List>
                <li>{t('campaignResultsPage.goToSpotifyForArtists')}</li>
                <li>{t('campaignResultsPage.clickThe')}</li>
                <li>{t('campaignResultsPage.fillInTheFields')}</li>
                <li>{t('campaignResultsPage.selectViewer')}</li>
                <li>{t('campaignResultsPage.sendInvite')}</li>
              </List>
            </LeftBlock>
            <div>
              <TextArea ref={textAreaRef} value={sfaEmail || ''} readOnly>
                {sfaEmail}
              </TextArea>
              <CopyButton type='button' onClick={copyToClipboard}>
                {t('campaignResultsPage.copyEmail')}
              </CopyButton>
              {isCopied && (
                <TextCopied>Email is copied to clipboard!</TextCopied>
              )}
            </div>
          </Block>

          <Video>
            <video width='100%' controls>
              <source src={link} type='video/mp4' />
              <track
                // We can add captions here for the video
                // src="captions_en.vtt"
                kind='captions'
                srcLang='en'
                label='english_captions'
              />
            </video>
          </Video>
          <SpotifyForArtistButtonWrapper>
            <Button type='green' onClick={goToSpotify}>
              <GoToSpotifyWrapper>
                <SpotifyIcon />
                <span>{t('campaignResultsPage.goToSpotify')}</span>
              </GoToSpotifyWrapper>
            </Button>
          </SpotifyForArtistButtonWrapper>
          <br />
          <Text>{t('campaignResultsPage.pleaseLetUsKnow')}</Text>
          <SuccessButtonContainer>
            <SpotifyForArtistButtonWrapper>
              <Button type='whiteWithGreenBorder' onClick={handleSubmit}>
                {t('campaignResultsPage.done')}
              </Button>
            </SpotifyForArtistButtonWrapper>
          </SuccessButtonContainer>
        </Wrapper>
      </StyledModal>
    </div>
  )
}

export default SpotifyForArtistModal
