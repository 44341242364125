import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { CampaignVideo, InfluencerEventStatus } from 'src/Types'
import { PremoderationStatus, VideoStatus } from 'src/Constants/enums'

import { RootState } from '..'
import { SliceStatus } from '../types'

import { CampaignVideosState } from '.'

const monitoringVideoStatuses = [
  VideoStatus.APPROVED_BY_ARTIST,
  VideoStatus.APPROVED_AUTOMATICALLY,
  VideoStatus.APPROVED_BY_ADMIN,
  VideoStatus.AWAITING_MODERATOR_REVIEW,
]

const confirmedVideoStatuses = [
  VideoStatus.APPROVED_BY_ARTIST,
  VideoStatus.APPROVED_AUTOMATICALLY,
  VideoStatus.APPROVED_BY_ADMIN,
]

export interface CampaignPreModerationVideo extends CampaignVideo {
  preModerationStatus?: PremoderationStatus
}

export const mapVideoToPreModerationStatus = (
  video: CampaignVideo | null,
): CampaignPreModerationVideo | null => {
  if (!video) {
    return null
  }

  let preModerationStatus

  const isConfirmedConditions = video.isConfirmed
  const isMonitoringConditions = !video.isConfirmed && video.isAvailable

  if (video.status === VideoStatus.AWAITING_REVIEW) {
    preModerationStatus = PremoderationStatus.NEW_VIDEO
  }
  if (
    monitoringVideoStatuses.includes(video.status) &&
    isMonitoringConditions
  ) {
    preModerationStatus = PremoderationStatus.MONITORING
  }

  if (confirmedVideoStatuses.includes(video.status) && isConfirmedConditions) {
    preModerationStatus = PremoderationStatus.CONFIRMED
  }

  if (
    video.status === VideoStatus.DECLINED ||
    video.status === VideoStatus.DISMISSED
  ) {
    preModerationStatus = PremoderationStatus.REJECTED
  }

  if (preModerationStatus) {
    return { ...video, preModerationStatus }
  }

  return null
}

const getState = (state: RootState): CampaignVideosState => state.campaignVideos

export const getCampaignVideos = createDraftSafeSelector(
  getState,
  ({ allVideos }) => allVideos,
)

export const getVideosWithPremoderationStatuses = createDraftSafeSelector(
  getState,
  ({ allVideos }) => {
    const mappedVideos = allVideos.videos
      .map(mapVideoToPreModerationStatus)
      .filter(Boolean)

    return {
      videos: mappedVideos as CampaignPreModerationVideo[],
      meta: allVideos.meta,
    }
  },
)

const LoadingStatuses = [SliceStatus.Loading, SliceStatus.Idle]
export const getCampaignVideosStatusIsLoading = createDraftSafeSelector(
  getState,
  ({ status }) => LoadingStatuses.includes(status),
)

export const getAwaitingVideos = createDraftSafeSelector(
  getState,
  ({ awaitingReviewVideos }) => {
    const mappedVideos = awaitingReviewVideos.videos
      .map(mapVideoToPreModerationStatus)
      .filter(Boolean)

    return {
      videos: mappedVideos as CampaignPreModerationVideo[],
      meta: awaitingReviewVideos.meta,
    }
  },
)

export const getCampaignVideosCounters = createDraftSafeSelector(
  getState,
  ({ videosCount }) => videosCount,
)

export const getVideosCountByStatuses =
  (statuses: Array<InfluencerEventStatus>) =>
  (state: RootState): number =>
    getCampaignVideosCounters(state).reduce((acc, counterItem) => {
      if (statuses.includes(counterItem.status)) {
        return acc + counterItem.count
      }
      return acc
    }, 0)
