import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { variables } from 'src/Styled/variables'

export const AlertSubTitle = styled.div``
export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const StyledAlert = styled(Alert)`
  margin-bottom: 0 !important;
  width: 100%;
`

export const TaskCompletedAlert: FC = () => {
  const { t } = useTranslation()

  return (
    <StyledAlert color={'success'} icon unclosable={true}>
      <AlertTitle>{t('soundsPage.taskCompleted')}</AlertTitle>

      <AlertSubTitle>{t('soundsPage.taskCompletedDescription')}</AlertSubTitle>
    </StyledAlert>
  )
}
