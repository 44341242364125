import React, { FC, useEffect } from 'react'

import { Input } from 'antd'
import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'

import SearchIcon from 'src/Assets/Svg/search-icon.svg?react'
import { colors } from 'src/Styled/variables'

import { useSpotifyWidgetSearch } from './useSpotifyWidgetSearch'
import { SpotifyWidgetList } from './SpotifyWidgetList'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledInput = styled(Input)`
  border-radius: 80px;
  margin-bottom: 10px;
  padding: 16.5px 32px;
  border-color: ${colors.greyTextLight};
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  &.ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 16px;
  }
  &.ant-input-affix-wrapper-focused,
  &.ant-input-outlined:hover,
  &.ant-input-outlined:active,
  &.ant-input-outlined:focus {
    border-color: ${colors.greyTextLight};
    box-shadow: none;
  }
`

export const SpotifyWidgetSearch: FC = () => {
  const { hardShutdown } = useIntercom()
  const { onSearchTrack, tracks, loading, error } = useSpotifyWidgetSearch()

  useEffect(() => {
    hardShutdown()
  }, [hardShutdown])

  const handleSearchHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onSearchTrack(event.target.value)
  }
  return (
    <Wrapper>
      <StyledInput
        size='large'
        placeholder='Type your track name or URL from Spotify'
        prefix={<SearchIcon width={'16px'} height={'16px'} />}
        onChange={handleSearchHandler}
      />
      <SpotifyWidgetList tracks={tracks} loading={loading} error={error} />
    </Wrapper>
  )
}
