import { Middleware, PayloadAction } from '@reduxjs/toolkit'

import { enterTrackEvent } from 'src/Helpers/TagManager'

import rootReducer from '../root-reducer'
import { RootState } from '../index'

type Reducer = ReturnType<typeof rootReducer>

type MetaType = {
  requestStatus: 'pending' | 'fulfilled' | 'rejected'
}

type PayloadType = {
  code: number
  message: string
}

const tikTokSearchTrackActions = [
  'getTikTokTrack',
  'searchTikTokTrackByName',
  'getTikTokTrackWithoutAuth',
]

export const enterTrackMiddleware: Middleware<unknown, Reducer> =
  (store) =>
  (next) =>
  async (action: PayloadAction<PayloadType, string, MetaType>) => {
    const { authentication } = store.getState() as RootState
    const { user } = authentication.user

    const userId = user?.id ? user.id : user?.curatorId
    const campaignType = !userId ? 'first' : 'basic'

    const isTikTokSearchTrackAction = (actionType: string): boolean =>
      tikTokSearchTrackActions.some((searchTrackActions) =>
        actionType.includes(searchTrackActions),
      )

    if (
      action.meta?.requestStatus === 'fulfilled' &&
      isTikTokSearchTrackAction(action.type)
    ) {
      const enteredText =
        (action.meta?.arg as { searchText?: string })?.searchText ||
        (action.meta?.arg as { search?: string })?.search

      enterTrackEvent({ userId, campaignType, enteredText })
    }

    return next(action)
  }
