import { FC, useMemo } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { colors } from 'src/Styled/variables'
import CheckMark from 'src/Assets/Svg/checkmark.svg?react'
import Danger from 'src/Assets/Svg/Danger.svg?react'
import { CommonHint } from 'src/Components/CommonHint'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import dayjs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'

import { Chart } from '../Chart'

export const ViewsContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background-color: ${colors.greyBg};
  border-radius: 4px;
  position: relative;
  width: 100%;
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

export const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
`

interface ViewsProgressInfoProps {
  minViews: number
  currentViews: number
  milestoneDate: string
}

export const ViewsProgressInfo: FC<ViewsProgressInfoProps> = ({
  minViews,
  currentViews,
  milestoneDate,
}) => {
  const { t } = useTranslation()

  const isViewsMilestoneDayPassed = dayjs().isAfter(milestoneDate)

  const formattedMinViews = addSpaceToNumber(minViews, ',')
  const formattedMilestoneDay = dayjs(milestoneDate).format(
    formatDate.formatDate1,
  )
  const isViewsAchieved = currentViews > minViews

  const chartParams = useMemo(() => {
    if (isViewsMilestoneDayPassed && !isViewsAchieved) {
      return {
        icon: <Danger />,
        chartColor: 'red',
      }
    }

    if (isViewsAchieved) {
      return {
        icon: <CheckMark />,
        chartColor: 'green',
      }
    }

    return {
      icon: (
        <CommonHint
          text={t('campaignResultsPage.videoShouldAchieved', {
            formattedMinViews,
            formattedMilestoneDay,
          })}
        />
      ),
      chartColor: 'green',
    }
  }, [
    isViewsMilestoneDayPassed,
    isViewsAchieved,
    t,
    formattedMinViews,
    formattedMilestoneDay,
  ])

  return (
    <ViewsContainer>
      <div>{t('campaignResultsPage.views')}</div>

      <ChartWrapper>
        <Chart
          color={chartParams?.chartColor}
          description={t('campaignResultsPage.expectedViewsText')}
          maxValue={minViews}
          minValue={currentViews}
        />
        <IconWrapper>{chartParams?.icon}</IconWrapper>
      </ChartWrapper>

      <div>{t('campaignResultsPage.youWontCharge')} </div>
    </ViewsContainer>
  )
}
