import { FC, useEffect } from 'react'

import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { useAppSelector } from 'src/Hooks/redux'

import RateTable from './RateTable'

import { Container, RateContainer, RateTitle } from './styles'

const Rate: FC = () => {
  const { t } = useTranslation()

  const userID = useAppSelector(getUserId)

  useEffect(() => {
    userID && locationEvent()
    userID && pageViewEvent({ userID, currentPageInfo: pageInfo.rate })
  }, [userID])

  return (
    <RateContainer>
      <Container>
        <Col lg={12} md={12} xl={12}>
          <Row>
            <Col md={12}>
              <RateTitle>{t('rate.rate')}</RateTitle>
            </Col>
          </Row>
        </Col>
        <RateTable />
      </Container>
    </RateContainer>
  )
}

export default Rate
