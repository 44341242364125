import React, { FunctionComponent } from 'react'

import Danger from 'src/Assets/Svg/Danger.svg?react'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { SearchingTrackDropdownProps } from './SearchingTrackDropdownProps'

import { WrapperRelative, SearchingRelative } from './styles'

export const SearchingTrackDropdown: FunctionComponent<
  SearchingTrackDropdownProps
> = ({ text, error }) => (
  <WrapperRelative error={error}>
    {error && <Danger />}

    <SearchingRelative error={error}>{text}</SearchingRelative>
  </WrapperRelative>
)
