import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { addFeatureForUser } from 'src/Redux/A-BFeatures-process/api-actions'
import {
  getFeaturesIsSucceeded,
  getIsFeatureValue,
} from 'src/Redux/A-BFeatures-process/selectors'
import { getArtistBalanceDetails } from 'src/Redux/artistBalanceProcess/ArtistBalanceHistorySlice/api-actions'
import { getIsArtistNotFromInvitedLink } from 'src/Redux/artistBalanceProcess/ArtistBalanceHistorySlice/selectors'
import { memorizeFeature } from 'src/Redux/A-BFeatures-process'

import {
  getHasPaidCampaignMetadata,
  useMetadataQuery,
} from '../Redux/metadataApi'

import { useCustomSearchParams } from './useCustomSearchParams'

export const QUICK_CHECKOUT_FEATURE_NAME = 'quick_checkout'
export const EXPIRED_TIMER_FEATURE_VALUE = 4

export const useCheckoutTimerFeatureFlag = (): void => {
  const { data } = useMetadataQuery()

  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()

  const paymentStatus = search.get('paymentStatus')
  const isFirstPaid = getHasPaidCampaignMetadata(data)
  const featuresIsSucceeded = useAppSelector(getFeaturesIsSucceeded)
  const featureValue = useAppSelector(
    getIsFeatureValue(QUICK_CHECKOUT_FEATURE_NAME),
  )
  const isArtistNotFromInvitedLink = useAppSelector(
    getIsArtistNotFromInvitedLink,
  )

  const isNoFeature = featuresIsSucceeded && !featureValue && featureValue !== 0

  const isQuickCheckoutFeatureAvailable =
    (isNoFeature || featureValue === EXPIRED_TIMER_FEATURE_VALUE) &&
    isArtistNotFromInvitedLink &&
    !isFirstPaid

  const isSuccessPageVisible = paymentStatus === 'success'

  // In case payment is successful, we need to drop the feature for the user
  useEffect(() => {
    if (isSuccessPageVisible) {
      dispatch(
        memorizeFeature({
          name: QUICK_CHECKOUT_FEATURE_NAME,
          variation: 1,
        }),
      )

      void dispatch(
        addFeatureForUser({
          featureName: QUICK_CHECKOUT_FEATURE_NAME,
          variation: 0,
        }),
      )
    }
  }, [dispatch, isSuccessPageVisible])

  useEffect(() => {
    void dispatch(getArtistBalanceDetails())
  }, [dispatch])

  useEffect(() => {
    if (isQuickCheckoutFeatureAvailable) {
      void dispatch(
        addFeatureForUser({
          featureName: QUICK_CHECKOUT_FEATURE_NAME,
          variation: 1,
        }),
      )
    }
  }, [dispatch, isQuickCheckoutFeatureAvailable])
}
