import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { Meta } from 'src/Types'
import { UiSoundStatus } from 'src/Containers/SoundsPage/hooks/useSoundStatus'

import { Sound } from '../types'

export interface NewSound extends Sound {
  trackTiktokId: string
  status: UiSoundStatus.NEW
  id: number
  trackPlayUrl: string
  comment: string
}

interface NewSoundsBody {
  creatorId: number
  pageNumber?: number
}

interface NewSoundsResponse {
  data: NewSound[]
  meta: Meta
}

interface NewSoundByIdBody {
  creatorId: number
  soundId: number
}

interface AcceptSoundBody {
  soundId: number
  minViews: number
  maxViews: number
}

interface AcceptSoundResponse {
  eventId: number
  campaignId: number
  uploadVideoDeadlineDate: string
}

export type NewSoundByIdResponse = NewSound

const creatorNewSoundsApi = rtkApi.injectEndpoints({
  endpoints(build) {
    const pageSize = 10

    return {
      newSounds: build.query<NewSoundsResponse, NewSoundsBody>({
        query: ({ creatorId, pageNumber = 1 }) => ({
          url: `${APP.TIKTOK_SERVER}/creators/${creatorId}/sounds?pageSize=${pageSize}&pageNumber=${pageNumber}`,
        }),
        providesTags: ['newSound'],
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems) => ({
          data: [...currentCache.data, ...newItems.data],
          meta: newItems.meta,
        }),
        forceRefetch({ currentArg, previousArg }) {
          return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
        },
      }),

      newSoundById: build.query<NewSoundByIdResponse, NewSoundByIdBody>({
        query: ({ creatorId, soundId }) => ({
          url: `${APP.TIKTOK_SERVER}/creators/${creatorId}/sounds/${soundId}`,
        }),
      }),

      acceptSound: build.mutation<AcceptSoundResponse, AcceptSoundBody>({
        query: ({ soundId, minViews, maxViews }) => ({
          url: `${APP.TIKTOK_SERVER}/influencer/tiktok/v2/accept-new-track`,
          method: 'POST',
          body: {
            campaignId: soundId,
            minViews,
            maxViews,
          },
        }),
        invalidatesTags: ['newSound', 'inProgressSound'],
      }),
    }
  },
})

export const selectNewSoundsCount = ({
  data,
}: {
  data?: NewSoundsResponse
}): { newSoundsCount: number } => ({
  newSoundsCount: data?.meta.itemCount || 0,
})

export const {
  useNewSoundsQuery,
  useNewSoundByIdQuery,
  useAcceptSoundMutation,
} = creatorNewSoundsApi
