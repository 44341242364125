import styled from 'styled-components'

import { device } from 'src/Styled/variables'

export const AlertWrapper = styled.div`
  margin-bottom: -24px;

  @media ${device.mobileMax} {
    padding: 0 16px;
    margin-top: 24px;
  }
`
