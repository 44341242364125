import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  & > svg {
    margin-left: auto;
  }
`

export const UserPictureContainer = styled.div`
  img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
  }
`
export const CustomDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.white};
`
export const TopDetailsWrapper = styled.div`
  color: ${colors.mainText};
  display: grid;
  gap: 16px;
  background-color: ${colors.greyBg};
  padding: 16px;
  width: 100%;
`

export const SubmissionTitle = styled.p`
  font-size: 10px;
`
export const StyledFollowers = styled.div`
  font-size: 10px;
`
export const SubmissionDate = styled.p`
  margin: 0;
`
