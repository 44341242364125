import { useEffect } from 'react'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'

import { useAppSelector } from '../redux'

interface PageInfo {
  pageTitle: string
  pagePath: string
}

export const usePageViewEvent = (pageInfo: PageInfo): void => {
  const userID = useAppSelector(getUserId)

  useEffect(() => {
    pageViewEvent({ userID, currentPageInfo: pageInfo })
    locationEvent()
  }, [pageInfo, userID])
}
