import React, { FC } from 'react'

import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'
import dayJs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'

export const DateWrapper = styled.span`
  color: ${colors.green};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

interface DateInfoProps {
  date: string | null
}

export const DateInfo: FC<DateInfoProps> = ({ date }) => {
  const formattedDate = dayJs(date).format(formatDate.formatDateFirst)

  return <DateWrapper>{formattedDate}</DateWrapper>
}
