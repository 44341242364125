import { FC, useEffect, useState } from 'react'

import dayJs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import {
  DEBOUNCE_DELAY_1000,
  TRACKS_FEED_REVIEW_TIME_LEFT,
} from 'src/Constants/constants'
import { colors, device, variables } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'
import { DotedButton } from 'src/Components/Buttons/DotedButton'
import { Mobile } from 'src/Components/Responsive'
import { useReserveReviewMutation } from 'src/Redux/TrackFeedDetailsApi'
import { tracksFeed } from 'src/Router/routes'

const ReservedContainer = styled.div<{ isVisible: boolean }>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  background: ${colors.white};
  height: calc(100% - 60px);
  width: 100%;
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 60px;
  left: 0;

  @media ${device.mobileMax} {
    padding-left: 10px;
    padding-right: 10px;
  }
`
const ReservedText = styled.h2`
  display: block;
  margin-top: 0;
  margin-bottom: 40px;
  font-size: ${variables.fontSize18};
  color: ${colors.mainText};

  @media ${device.mobileMax} {
    text-align: center;
  }
`
const ButtonsContainer = styled.div`
  padding-bottom: 24px;
  padding-top: 64px;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    cursor: pointer;
  }

  @media ${device.mobileMax} {
    display: flex;
    flex-direction: column-reverse;
  }
`
const TimeLeft = styled.div`
  color: ${colors.messegesRed};
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize18};
  &::first-letter {
    color: inherit;
  }
`
const StyledButton = styled(Button)`
  width: 160px;
  button {
    padding: 0 40px;
  }
`
const TIMER = TRACKS_FEED_REVIEW_TIME_LEFT * 60 * 1000

interface TrackToReviewTimerProps {
  onStopTimer: () => void
  id?: string
}

export const TrackToReviewTimer: FC<TrackToReviewTimerProps> = ({
  onStopTimer,
  id,
}) => {
  const { t } = useTranslation()
  const [reserveReview, { isLoading: isLoadingReserve }] =
    useReserveReviewMutation()
  const navigate = useNavigate()
  const [time, setTime] = useState<string>()
  const [showWarning, setShowWarning] = useState<boolean>(false)
  const end = dayJs().add(TIMER, 'milliseconds')
  const [endTime, setEndTime] = useState<Dayjs>(end)

  useEffect(() => {
    const diffTime = endTime.unix() - dayJs().unix()

    let hasWarning = false
    let duration = dayJs.duration(diffTime * 1000, 'milliseconds')

    const timer = setInterval(() => {
      duration = dayJs.duration(
        duration.asMilliseconds() - DEBOUNCE_DELAY_1000,
        'milliseconds',
      )

      // If time left less than a minute - turn on warning
      if (duration.hours() <= 0 && duration.minutes() < 1 && !hasWarning) {
        hasWarning = true
        setShowWarning(true)
      }
      // If there is no time left - stop and hide timer
      if (
        duration.hours() <= 0 &&
        duration.minutes() <= 0 &&
        duration.seconds() <= 0
      ) {
        clearInterval(timer)
        setShowWarning(false)
        setTime('')
        onStopTimer()
        return
      }
      setTime(duration.format('mm:ss'))
    }, DEBOUNCE_DELAY_1000)

    return () => clearInterval(timer)
  }, [setShowWarning, onStopTimer, endTime])

  const handleUserIsHere = async (): Promise<void> => {
    await reserveReview(id || '')
      .unwrap()
      .then((result) => {
        setShowWarning(false)
        setEndTime(end)
        return result
      })
  }

  const debouncedSkipReview = (): void => {
    navigate(`${tracksFeed}?skip=${String(id)}`)
  }

  return (
    <>
      {showWarning && (
        <ReservedContainer isVisible={showWarning}>
          <ReservedText>{t('tracksFeedPage.areYouStillHere')}</ReservedText>
          <TimeLeft>{time}</TimeLeft>
          <ButtonsContainer>
            <DotedButton color={colors.mainText} onClick={debouncedSkipReview}>
              {t('tracksFeedPage.noWantToSkipReview')}
            </DotedButton>
            <StyledButton
              loading={isLoadingReserve}
              type='green'
              onClick={handleUserIsHere}
            >
              {t('tracksFeedPage.yesWantToFinishReview')}
            </StyledButton>
          </ButtonsContainer>
        </ReservedContainer>
      )}
      {time && (
        <>
          <Mobile>
            <span>-</span>
          </Mobile>
          <TimeLeft>{time}</TimeLeft>
        </>
      )}
    </>
  )
}
