import { FC } from 'react'

import styled from 'styled-components'

import { CustomScrollbarWrapper } from 'src/Components/CustomScrollbarWrapper'
import { SpotifyTrack } from 'src/Redux/spotifyTracksApi'
import { colors, device } from 'src/Styled/variables'

import { WidgetListLoading } from '../WidgetListLoading'
import { WidgetListError } from '../WidgetListError'

import { SpotifyWidgetListItem } from './SpotifyWidgetListItem'

interface SpotifyWidgetListProps {
  loading?: boolean
  error?: string
  tracks?: SpotifyTrack[]
}

const WrapperList = styled(CustomScrollbarWrapper)<{ fitContent: boolean }>`
  height: ${({ fitContent }) => (fitContent ? 'fit-content' : '400px')};
`
const Wrapper = styled.div`
  border-radius: 8px;
  background: ${colors.white};
  padding: 4px 4px 0 4px;
  @media ${device.mobileMax} {
    margin-bottom: 50px;
  }
`
const LoadingWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 400px;
  overflow: hidden;
`
const MIN_TRACKS_COUNT = 5

export const SpotifyWidgetList: FC<SpotifyWidgetListProps> = ({
  loading,
  error,
  tracks,
}) => {
  if (loading) {
    return (
      <LoadingWrapper>
        <WidgetListLoading />
      </LoadingWrapper>
    )
  }
  if (error) {
    return (
      <Wrapper>
        <WidgetListError error={error} />
      </Wrapper>
    )
  }

  if (!tracks?.length) {
    return null
  }

  return (
    <Wrapper>
      <WrapperList fitContent={tracks.length <= MIN_TRACKS_COUNT}>
        {tracks.map((track) => (
          <SpotifyWidgetListItem key={track.id} {...track} />
        ))}
      </WrapperList>
    </Wrapper>
  )
}
