import { FunctionComponent, ReactNode, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { getSpotifyToken } from 'src/Redux/spotifyToken-process/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { SpotifyCampaign, TiktokCampaign } from 'src/Types'
import { getCampaignsList } from 'src/Redux/campaign-process/mainOldProcessSlice/api-actions'
import {
  getCampaigns,
  getCampaignsLoader,
} from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'

import { CampaignsListTable } from './components/CampaignsListTable'
import { CampaignEmptyPage } from './components/CampaignEmptyPage/index'

import {
  CampaignsContainer,
  CampaignsContainerPageSubHeader,
  Card,
} from './styles'

const CampaignsList: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const userID = useAppSelector(getUserId)
  const campaigns = useSelector(getCampaigns)
  const spotifyAccessToken = useSelector(getSpotifyToken)
  const loading = useSelector(getCampaignsLoader)

  useEffect(() => {
    void dispatch(getCampaignsList())
  }, [dispatch])

  useEffect(() => {
    if (userID) {
      pageViewEvent({ userID, currentPageInfo: pageInfo.campaigns })
      locationEvent()
    }
  }, [userID])

  const renderCampaigns = (
    campaignList: Array<SpotifyCampaign | TiktokCampaign>,
  ): ReactNode => {
    if (loading) {
      return <Skeleton count={30} height={50} />
    }
    if (campaignList?.length !== 0) {
      return <CampaignsListTable />
    }
    if (spotifyAccessToken) {
      return <CampaignEmptyPage />
    }
    return null
  }

  return (
    <CampaignsContainer>
      <CampaignsContainerPageSubHeader>
        {t('campaignsPage.pageTitle')}
      </CampaignsContainerPageSubHeader>

      <Card>{renderCampaigns(campaigns)}</Card>
    </CampaignsContainer>
  )
}

export default CampaignsList
