import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { useAppSelector } from 'src/Hooks/redux'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'

import { PromocodePopup } from '../PromocodePopup'

import { CheckoutProps } from './props'
import { ButtonsBlock } from './components/ButtonsBlock'
import { ProtectionAlert } from './components/ProtectionAlert'
import { ValuesBlock } from './components/ValuesBlock'

import { RightSideContainer } from './styles'

export const ScheduleCampaignSummary: React.FC<CheckoutProps> = ({
  platformName,
}) => {
  const { t } = useTranslation()
  const [isPromoCodeModalOpen, setIsPromoCodeModalOpen] = useState(false)

  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)
  const scheduledTikTokCampaign = useAppSelector(getScheduledTikTokCampaign)
  const tikTokCampaignPrice = useAppSelector(getTiktokPrice)

  const activeCampaign =
    platformName === CampaignPlatformTypeEnum.SPOTIFY
      ? spotifyCampaignDetails
      : scheduledTikTokCampaign

  const currentPrice =
    platformName === CampaignPlatformTypeEnum.SPOTIFY
      ? spotifyCampaignDetails.price
      : tikTokCampaignPrice

  const onClickPromoCode = (): void => {
    setIsPromoCodeModalOpen(true)
  }

  return (
    <>
      <PromocodePopup
        campaignType={activeCampaign.type || CampaignTypeEnum.BASIC}
        price={currentPrice}
        content={t('scheduleCampaignPage.PromoCode')}
        visible={isPromoCodeModalOpen}
        width={332}
        setModalPromocode={setIsPromoCodeModalOpen}
        handleClose={() => setIsPromoCodeModalOpen(false)}
      />

      <RightSideContainer>
        <ValuesBlock platformName={platformName} />

        <ButtonsBlock
          onClickPromoCode={onClickPromoCode}
          platformType={platformName}
        />

        <ProtectionAlert platformType={platformName} />
      </RightSideContainer>
    </>
  )
}
