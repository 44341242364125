import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { api } from 'src/Services/api'
import { CampaignVideos, InfluencerEventStatus, Meta } from 'src/Types'

import { NameSpace, ErrorCode } from '../types'

interface GetCampaignTikTokVideosData {
  data: CampaignVideos
  meta: Meta
}

interface GetCampaignTikTokVideosRequest {
  campaignId: string
  pageNumber: number
  status: Array<InfluencerEventStatus>
}

export const getCampaignTikTokVideos = createAsyncThunk<
  GetCampaignTikTokVideosData,
  GetCampaignTikTokVideosRequest
>(
  `${NameSpace.CampaignVideos}/getCampaignTikTokVideos`,
  async ({ campaignId, status, pageNumber }, { rejectWithValue }) => {
    try {
      const statusesString = status
        .map((value) => `status=${encodeURIComponent(value)}`)
        .join('&')

      const { data } = await api.get<GetCampaignTikTokVideosData>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns/${campaignId}/events?pageSize=10&pageNumber=${pageNumber}&${statusesString}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const getAllCampaignTikTokVideos = createAsyncThunk<
  GetCampaignTikTokVideosData,
  { campaignId: string; pageNumber: number }
>(
  `${NameSpace.CampaignVideos}/getAllCampaignTikTokVideos`,
  async ({ campaignId, pageNumber }, { rejectWithValue, dispatch }) => {
    try {
      const allTabVideoStatuses = [
        InfluencerEventStatus.AWAITING_REVIEW,
        InfluencerEventStatus.APPROVED_AUTOMATICALLY,
        InfluencerEventStatus.APPROVED_BY_ARTIST,
        InfluencerEventStatus.APPROVED_BY_ADMIN,
        InfluencerEventStatus.AWAITING_MODERATOR_REVIEW,
        InfluencerEventStatus.DECLINED,
        InfluencerEventStatus.VIDEO_DISMISSED,
      ]

      const videosAction = await dispatch(
        getCampaignTikTokVideos({
          campaignId,
          pageNumber,
          status: allTabVideoStatuses,
        }),
      )

      return unwrapResult(videosAction)
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const getAwaitingReviewCampaignTikTokVideos = createAsyncThunk<
  GetCampaignTikTokVideosData,
  { campaignId: string; pageNumber: number }
>(
  `${NameSpace.CampaignVideos}/getAwaitingReviewCampaignTikTokVideos`,
  async ({ campaignId, pageNumber }, { rejectWithValue, dispatch }) => {
    try {
      const awaitingReviewStatuses = [InfluencerEventStatus.AWAITING_REVIEW]

      const videosAction = await dispatch(
        getCampaignTikTokVideos({
          campaignId,
          pageNumber,
          status: awaitingReviewStatuses,
        }),
      )

      return unwrapResult(videosAction)
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

interface GetCampaignTikTokVideosStatsRequest {
  campaignId: string
}

interface GetCampaignTikTokVideosStatsData {
  statuses: Array<{
    status: InfluencerEventStatus
    count: number
  }>
}

export const getCampaignTikTokVideosStats = createAsyncThunk<
  GetCampaignTikTokVideosStatsData,
  GetCampaignTikTokVideosStatsRequest
>(
  `${NameSpace.CampaignVideos}/getCampaignTikTokVideosStats`,
  async ({ campaignId }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetCampaignTikTokVideosStatsData>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns/${campaignId}/events/stats`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
