import { FC, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { formatDate } from 'src/Constants/formatDate'
import { calculateDaysLeft } from 'src/Constants/functions'
import dayjs from 'src/Helpers/dayjs'
import { colors } from 'src/Styled/variables'
import { getTiktokCampaignDetails } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { TikTokCampaignPricingModelEnum } from 'src/Types'

import { DetailsCampaignStatus } from '../DetailsCampaignStatus'

import {
  TikTokDetailsCampaignStatusWrapper,
  StyledProgress,
  DaysInfo,
  DateInfo,
  TikTokDetailsCampaignStatusContainer,
} from './styles'

const HUNDRED_PERCENT = 100
export const FOLLOWERS_BASED_TIKTOK_CAMPAIGN_PERIOD = 14
const VIEWS_BASED_TIKTOK_CAMPAIGN_PERIOD = 30

export const DetailsProgress: FC = () => {
  const { t } = useTranslation()

  const campaignDetails = useSelector(getTiktokCampaignDetails)

  const daysLeftMemo = useMemo(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const days = calculateDaysLeft(campaignDetails?.endDate || '')

    const campaignPeriod =
      campaignDetails?.pricingModelVersion ===
      TikTokCampaignPricingModelEnum.FollowersBased
        ? FOLLOWERS_BASED_TIKTOK_CAMPAIGN_PERIOD
        : VIEWS_BASED_TIKTOK_CAMPAIGN_PERIOD

    const daysLeftText =
      days === 1
        ? `${days} ${t('campaignsPage.dayLeft')}`
        : `${days} ${t('campaignsPage.daysLeft')}`

    const percentageGraph = (days * HUNDRED_PERCENT) / campaignPeriod
    const displayedDate = dayjs(campaignDetails?.startDate)
      .tz(timezone)
      .format(formatDate.formatDateMonthFull)

    return { daysLeftText, days, percentageGraph, displayedDate }
  }, [
    campaignDetails?.endDate,
    campaignDetails?.pricingModelVersion,
    campaignDetails?.startDate,
    t,
  ])

  return (
    <TikTokDetailsCampaignStatusWrapper>
      <TikTokDetailsCampaignStatusContainer>
        <DetailsCampaignStatus campaignStatus={campaignDetails?.status || ''} />
        <DaysInfo>{daysLeftMemo.daysLeftText}</DaysInfo>
      </TikTokDetailsCampaignStatusContainer>

      <StyledProgress
        percent={100 - daysLeftMemo.percentageGraph}
        strokeWidth={4}
        showInfo={false}
        strokeColor={colors.green}
        status='active'
      />
      <DateInfo>{`${t('campaignResultsPage.startedAt')} ${
        daysLeftMemo.displayedDate
      } `}</DateInfo>
    </TikTokDetailsCampaignStatusWrapper>
  )
}
