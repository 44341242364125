import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { AudienceTiktokState } from '.'

const getState = (state: RootState): AudienceTiktokState =>
  state.scheduling.audienceTiktok
export const getIsTiktokAudienceLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading || status === SliceStatus.Idle,
)

export const getIsReachSettingsError = createDraftSafeSelector(
  getState,
  ({ error }) => Boolean(error),
)

export const getEstimatedTiktokViews = createDraftSafeSelector(
  getState,
  ({ maxViews, minViews }) => ({ maxViews, minViews }),
)

export const getTiktokPrice = createDraftSafeSelector(
  getState,
  ({ price }) => price,
)

export const getIsEnoughBalanceForTiktok = createDraftSafeSelector(
  (state: RootState) => ({
    balance: Number(state?.authentication?.user?.user?.balance),
    price: getTiktokPrice(state),
  }),
  ({ price, balance }) => balance >= price && price > 0,
)
