import { FC, useMemo } from 'react'

import { colors } from 'src/Styled/variables'

import { ProgressTooltip } from './ProgressTooltip'

import { MainValue, Mark, MarkLabel, ProgressBar, SubValue } from './styles'

import { ViewsValue } from '.'

interface IProgress {
  min: number
  max: number
  value?: ViewsValue
  hasRightRadius?: boolean
  views?: string
  price?: string
  completed?: boolean
}

export const Progress: FC<IProgress> = ({
  min,
  max,
  value,
  hasRightRadius,
  views,
  price,
  completed,
}) => {
  const currentViews = value?.views || 0
  const totalRange = max - min
  const valueOffset = currentViews - min
  const position = Math.round((valueOffset / totalRange) * 100)
  const isMore = currentViews >= max
  const isLess = currentViews >= min

  const showTooltip = (hasRightRadius || !isMore) && isLess
  const hasLeftRadius = min === 0

  const markColor = useMemo(() => {
    if (completed) {
      return colors.white
    }
    if (isMore) {
      return colors.progressMain
    }
    return colors.progressActive
  }, [completed, isMore])

  return (
    <>
      <ProgressBar
        hasRightRadius={hasRightRadius}
        hasLeftRadius={hasLeftRadius}
        position={position}
        completed={completed}
      >
        {Boolean(value) && showTooltip && (
          <ProgressTooltip
            position={position}
            views={currentViews}
            price={value?.price}
            hasRightRadius={hasRightRadius}
            hasLeftRadius={hasLeftRadius}
            completed={completed}
          />
        )}
      </ProgressBar>
      {!hasRightRadius && (
        <Mark color={markColor}>
          <MarkLabel>
            <MainValue>{price}</MainValue>
            <SubValue>{views}</SubValue>
          </MarkLabel>
        </Mark>
      )}
    </>
  )
}
