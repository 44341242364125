import { FC } from 'react'

import { IFrameSkeleton } from 'src/Containers/TracksFeed/components/IframeSkeleton'

import { useSpotifyIFrame } from './useSpotifyIframe'

import { ErrorLink, ErrorSubText, ErrorText, ErrorWrapper } from './styles'

interface SpotifyIFrameProps {
  trackId: string
}

export const SpotifyIFrame: FC<SpotifyIFrameProps> = ({ trackId }) => {
  const { loading, error, iFrame } = useSpotifyIFrame(trackId)

  if (loading) {
    return <IFrameSkeleton />
  }
  if (error) {
    return (
      <ErrorWrapper>
        <ErrorText>Oops! Preview not available </ErrorText>
        <ErrorSubText>
          listen to the track on{' '}
          <ErrorLink
            href={`https://open.spotify.com/track/${trackId}`}
            target='_blank'
          >
            Spotify
          </ErrorLink>
        </ErrorSubText>
      </ErrorWrapper>
    )
  }

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: iFrame }} />
}
