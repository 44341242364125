import React, { useEffect, useMemo } from 'react'

import { Col, Row } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getCurrencies } from 'src/Redux/wise-process/api-actions'
import { setRequirementsToInitialState } from 'src/Redux/wise-process'
import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { Roles } from 'src/Constants/enums'
import { getTabs } from 'src/Containers/SettingsInfuencer/helpers'
import WisePayout from 'src/Components/WisePayout'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import {
  getIsArtist,
  getUserRole,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'

import { TabKeys } from './helpers'

import {
  SettingsContainer,
  Container,
  SettingsTitle,
  SettingsInnerContainer,
  TabName,
  StyledTabs,
} from './styles'

const Settings: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const userID = useAppSelector(getUserId)
  const userRole = useAppSelector(getUserRole)
  const isArtist = useSelector(getIsArtist)

  useEffect(() => {
    userID && pageViewEvent({ userID, currentPageInfo: pageInfo.settings })
    userID && locationEvent()

    if (userRole === Roles.curator || !isArtist) {
      void dispatch(getCurrencies())
    }

    return () => {
      dispatch(setRequirementsToInitialState())
    }
  }, [userID, userRole, dispatch, isArtist])

  const tabs = useMemo(() => getTabs(), [])

  const items = tabs.map(({ key, title }) => ({
    key,
    label: <TabName>{t(title)}</TabName>,
    children: <WisePayout />,
  }))

  return (
    <SettingsContainer>
      <Container>
        <Col lg={12} md={12} xl={12}>
          <Row>
            <Col md={12}>
              <SettingsTitle>{t('settingsPage.pageTitle')}</SettingsTitle>
            </Col>
          </Row>
        </Col>

        <SettingsInnerContainer>
          <StyledTabs items={items} defaultActiveKey={TabKeys.payout} />
        </SettingsInnerContainer>
      </Container>
    </SettingsContainer>
  )
}

export default Settings
