import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RootState } from 'src/Redux'

import { ga } from './api'

export const rtkApi = createApi({
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState, endpoint }) => {
      const isCookies = ga && !endpoint?.startsWith('https://api.spotify.com')
      const token = (getState() as RootState).authentication?.credentials
        ?.AccessToken
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (isCookies) {
        headers.set('Cookies', ga)
      }
      return headers
    },
  }),
  tagTypes: ['newSound', 'inProgressSound', 'submittedSound'],
  endpoints: () => ({}),
})
