import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

export const useWarningModal = (): {
  textContent: { title: string; description: string } | null
  isModalVisible: boolean
} => {
  const { t } = useTranslation()
  const { search } = useCustomSearchParams()

  const artistName = search.get('artistName') || ''
  const trackName = search.get('trackName') || ''
  const isYouAreLateConfirmModalVisible = Boolean(
    search.get('youAreLateConfirmModalVisible'),
  )
  const isYouAreLateUploadModalVisible = Boolean(
    search.get('youAreLateUploadModalVisible'),
  )

  const isCanceledCampaignModalVisible = Boolean(
    search.get('canceledCampaignModalVisible'),
  )

  const textContent = useMemo(() => {
    if (isYouAreLateUploadModalVisible) {
      return {
        title: t('soundsPage.youAreLateTitle'),
        description: t('soundsPage.lateUploadDescription'),
      }
    }

    if (isYouAreLateConfirmModalVisible) {
      return {
        title: t('soundsPage.youAreLateTitle'),
        description: t('soundsPage.soundUnavailable'),
      }
    }

    if (isCanceledCampaignModalVisible) {
      return {
        title: t('soundsPage.campaignCanceled', { trackName }),
        description: t('soundsPage.cancelAlertDescription', { artistName }),
      }
    }

    return null
  }, [
    artistName,
    isCanceledCampaignModalVisible,
    isYouAreLateConfirmModalVisible,
    isYouAreLateUploadModalVisible,
    t,
    trackName,
  ])

  const isModalVisible =
    (isYouAreLateConfirmModalVisible ||
      isCanceledCampaignModalVisible ||
      isYouAreLateUploadModalVisible) &&
    Boolean(textContent)

  return {
    textContent,
    isModalVisible,
  }
}
