import TagManager from 'react-gtm-module'

import { ID } from 'src/Types'

import { EventTypeEnum } from '../tagManagerEnums'

export const pageViewEvent = ({
  userID,
  currentPageInfo,
}: {
  userID?: ID | null
  currentPageInfo?: { pageTitle: string; pagePath: string }
}): void => {
  const tagManagerArgs = {
    dataLayer: {
      event: EventTypeEnum.PageView,
      pageTitle: currentPageInfo && currentPageInfo.pageTitle,
      pagePath: currentPageInfo && currentPageInfo.pagePath,
      ...(userID ? { userID } : {}),
    },
  }

  TagManager.dataLayer(tagManagerArgs)
}

export const locationEvent = (): void => {
  const tagManagerLocationArgs = {
    dataLayer: {
      originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
    },
  }
  TagManager.dataLayer(tagManagerLocationArgs)
}
