import { useState, useEffect, FunctionComponent } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { numberFormatSpaces } from 'src/Constants/functions'
import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import SurveyForEndedCampaign from 'src/Components/SideBarSurveys/SurveyForEndedCampaign'
import { getClosedTypeformIds } from 'src/Redux/typeform-process'
import {
  getValidNumber,
  getValueOrEmptyString,
  isEmpty,
} from 'src/Helpers/layoutHelpers'
import { CampaignStatusEnum, Review } from 'src/Types'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import {
  getUserEmail,
  getUserId,
} from 'src/Redux/auth-process/userSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'

import SpotifyForArtistModal from '../ui/SpotifyForArtistModal'

import CampaignRating from './components/CampaignRating'
import { CampaignHeader } from './components/CampaignHeader'
import CampaignResultsTitle from './components/CampaignResultsTitle'
import { CampaignResultsTabs } from './components/CampaignResultsTabs'
import { ListenersCard } from './components/ListenersCard/ListenersCard'

import {
  Card,
  CardAdditionalInfo,
  CardBody,
  CardMainValue,
  CardTitle,
  CardsContainer,
  CardsOverflow,
  Container,
  ReviewsContainer,
} from './styles'

const getPopularityDifference = (
  popularity?: number,
  popularityResult?: number,
): number => {
  const spotifyPopularity = popularity || 0
  const spotifyPopularityResult = popularityResult || 0

  if (spotifyPopularityResult > spotifyPopularity) {
    return spotifyPopularityResult - spotifyPopularity
  }
  return 0
}

const CampaignResults: FunctionComponent = () => {
  const [filteredReviews, setFilteredReviews] = useState<Review[]>([])
  const { campaignDetails } = useSpotifyCampaignDetails()

  const userID = useAppSelector(getUserId)
  const userEmail = useAppSelector(getUserEmail)
  const closedTypeformIds = useSelector(getClosedTypeformIds)
  const { t } = useTranslation()

  useEffect(() => {
    if (userID) {
      pageViewEvent({ userID, currentPageInfo: pageInfo.campaignResults })
      locationEvent()
    }
  }, [userID])

  const [modal, setModal] = useState<boolean>(false)

  const modalToggle = (): void => setModal(!modal)
  const openConnectSFAModal = (): void => setModal(true)

  const isAppropriateCampaignStatus =
    campaignDetails?.status &&
    campaignDetails?.status === CampaignStatusEnum.ENDED

  const isClosedCampaign = Boolean(
    closedTypeformIds.includes(String(campaignDetails?.id)),
  )

  const playlistsAdds = `+${getValidNumber(
    campaignDetails?.playlists_adds,
  )} ${t('campaignResultsPage.playlists')}`
  return (
    <Container>
      {isAppropriateCampaignStatus && !isClosedCampaign && (
        <SurveyForEndedCampaign
          userEmail={userEmail}
          campaignId={`${campaignDetails?.id ?? ''}`}
          campaignType={`${getValueOrEmptyString(campaignDetails?.type)}`}
          campaignName={`${getValueOrEmptyString(
            campaignDetails?.Track?.track_name,
          )}`}
          isFeed={campaignDetails?.is_feed}
          id='VP92h7z5'
          text={t('sidebar.shareFeedback')}
        />
      )}

      <SpotifyForArtistModal
        modal={modal}
        toggle={modalToggle}
        artistSpotifyId={campaignDetails?.Track?.artist_spotify_id}
      />

      {!isEmpty(campaignDetails) && (
        <>
          <CampaignResultsTitle />

          <CampaignHeader />
          <CardsOverflow>
            <CardsContainer>
              <ListenersCard
                title={t('campaignResultsPage.listeners')}
                count={campaignDetails?.listeners_count || 0}
                isSfaConnected={Boolean(campaignDetails?.is_sfa_connected)}
                openConnectSFAModal={openConnectSFAModal}
              />
              <ListenersCard
                title={t('campaignResultsPage.streams')}
                count={campaignDetails?.streams_count || 0}
                isSfaConnected={Boolean(campaignDetails?.is_sfa_connected)}
                openConnectSFAModal={openConnectSFAModal}
              />

              <Card>
                <CardBody>
                  <CardTitle>
                    {t('campaignResultsPage.playlistsAdds')}
                  </CardTitle>
                  <CardMainValue>
                    {numberFormatSpaces(
                      campaignDetails?.playlists_followers_result,
                    )}
                  </CardMainValue>
                  <CardAdditionalInfo>{playlistsAdds}</CardAdditionalInfo>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>
                    {t('campaignResultsPage.spotifyPopularity')}
                  </CardTitle>
                  <CardMainValue>
                    {campaignDetails?.Track.spotify_popularity_result
                      ? campaignDetails?.Track.spotify_popularity_result
                      : campaignDetails?.Track.spotify_popularity}
                  </CardMainValue>
                  <CardAdditionalInfo>
                    +
                    {getPopularityDifference(
                      campaignDetails?.Track?.spotify_popularity,
                      campaignDetails?.Track.spotify_popularity_result,
                    )}
                    &ensp;
                    {t('campaignResultsPage.newFollowers')}
                  </CardAdditionalInfo>
                </CardBody>
              </Card>
            </CardsContainer>
          </CardsOverflow>
          <ReviewsContainer>
            <CampaignResultsTabs setFilteredReviews={setFilteredReviews} />

            {filteredReviews.map((review, index) => (
              <CampaignRating
                review={review}
                key={`${index.toString()}=${review.review_refund}`}
                campaignID={Number(campaignDetails?.id)}
                isSFAConnected={campaignDetails?.is_sfa_connected || false}
              />
            ))}
          </ReviewsContainer>
        </>
      )}
    </Container>
  )
}
export default CampaignResults
