import styled from 'styled-components'

import { device } from 'src/Styled/variables'

export const CreateCampaignBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media screen and (min-width: 576px) {
    max-width: 1120px;
    margin: 0 auto;
  }
`

export const ButtonWrapper = styled.div`
  width: 214px;
  margin: 0 auto;
  margin-bottom: 40px;
  @media ${device.mobileMin} {
    margin-bottom: 160px;
    margin-top: 64px;
  }
`
