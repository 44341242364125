import { FC, ReactNode } from 'react'

import { Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { LoadingSVG } from 'src/Components/LoadingSVG'
import { CommonHint } from 'src/Components/CommonHint'
import { HUNDRED } from 'src/Constants/numeric'
import {
  getCampaignToSchedule,
  getIsUpdateCampaignLoading,
} from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { colors } from 'src/Styled/variables'
import { useAppSelector } from 'src/Hooks/redux'

import {
  SectionTitle,
  MatchingBlockCuratorsReach,
  MatchingBlockValue,
  MatchingBlockValueMain,
  MatchingBlockPotentialReach,
  MatchingBlockHeaderPotentialReach,
  CircularProgressBarWrapper,
  Blocks,
  StyledPercentage,
  LoadingSVGWrapper,
} from './styles'

const STROKE_WIDTH = 7

export const SpotifyEstimation: FC = () => {
  const { t } = useTranslation()

  const campaignDetails = useSelector(getCampaignToSchedule)
  const isUpdateCampaignLoading = useAppSelector(getIsUpdateCampaignLoading)

  const percentage =
    campaignDetails?.minCuratorsCount && campaignDetails?.maxCuratorsCount
      ? (campaignDetails.minCuratorsCount / campaignDetails.maxCuratorsCount) *
        HUNDRED
      : 0

  const customFormat = (): ReactNode => (
    <StyledPercentage>{Math.round(percentage)}%</StyledPercentage>
  )

  return (
    <Blocks>
      <SectionTitle>{t('scheduleCampaignPage.estimatedReach')}</SectionTitle>
      <MatchingBlockCuratorsReach>
        <MatchingBlockHeaderPotentialReach>
          <div>{t('scheduleCampaignPage.curatorsReach')}</div>
          <CommonHint text={t('scheduleCampaignPage.setReachHint')} />
        </MatchingBlockHeaderPotentialReach>
        <MatchingBlockValue>
          {isUpdateCampaignLoading ? (
            <LoadingSVG />
          ) : (
            <MatchingBlockValueMain>
              {`${t('scheduleCampaignPage.upTo')} ${
                campaignDetails?.maxCuratorsCount ?? 0
              }`}
            </MatchingBlockValueMain>
          )}
        </MatchingBlockValue>
      </MatchingBlockCuratorsReach>
      <MatchingBlockPotentialReach>
        <MatchingBlockHeaderPotentialReach>
          <div>{t('scheduleCampaignPage.campaignReach')}</div>
          <CommonHint
            text={t('scheduleCampaignPage.shareOfCuratorsMatchedWithYourTrack')}
          />
        </MatchingBlockHeaderPotentialReach>

        {isUpdateCampaignLoading ? (
          <LoadingSVGWrapper>
            <LoadingSVG />
          </LoadingSVGWrapper>
        ) : (
          <CircularProgressBarWrapper>
            <Progress
              type='circle'
              percent={percentage}
              strokeWidth={STROKE_WIDTH}
              trailColor={colors.green1Light}
              strokeColor={colors.green}
              format={customFormat}
            />
          </CircularProgressBarWrapper>
        )}
      </MatchingBlockPotentialReach>
    </Blocks>
  )
}
