import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { SmartImage } from '../SmartImage'
import { CustomScrollbarWrapper } from '../CustomScrollbarWrapper'

export const SearchResults = styled.div`
  margin: 0 auto;
  padding-left: 16px;
  background-color: ${colors.white};
  border-radius: 8px;
  z-index: 1;
  position: relative;
`

export const SearchResultsItem = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  z-index: 1000;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;
  border-bottom: 1px solid #f3f4f7;

  @media ${device.mobileMin} {
    border-bottom: 1px solid ${colors.greyBlue};
  }
`

export const SearchResultsImg = styled(SmartImage)`
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 4px;
`

export const SearchResultsText = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-between;
  height: 100%;
  text-align: left;
  line-height: 21px;
  font-size: ${variables.fontSize14};
`

export const SearchResultsTitle = styled.div`
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  margin-bottom: 4px;
  @media ${device.mobileMin} {
    margin-bottom: 4px;
  }
`

export const SearchResultsDescription = styled.div`
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${colors.mainText};
`

export const Duration = styled.div`
  margin: 0 16px 0 auto;
  color: ${colors.greyTextWhiteBg};
  font-size: ${variables.fontSize14};
  line-height: 150%;
`

interface Props {
  isSmallTracksContainer?: boolean
  platform: CampaignPlatformTypeEnum
}

export const TracksWrapper = styled(CustomScrollbarWrapper)<Props>`
  height: ${({ isSmallTracksContainer }) =>
    isSmallTracksContainer ? 'fit-content' : '280px'};
  @media ${device.mobileMax} {
    height: ${(props: Props) =>
      props.platform === CampaignPlatformTypeEnum.SPOTIFY
        ? 'calc(100vh - 70px)'
        : 'calc(100vh - 150px)'};
  }
`
