import React, { FC } from 'react'

import { Control, Controller } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'

import ArrowIcon from 'src/Assets/Svg/arrow.svg?react'
import SearchInput from 'src/Containers/ui/Input'
import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { useTrackSearchForm } from 'src/Containers/SearchTrackByPlatform/TrackSearchForm/useTrackSearchForm'

import { FormDataProps } from '../../TrackSearchProps'

import { BackButton, BtnWrapper, GlobalStyled, InputWrapper } from './styles'

interface SearchInputBlockProps {
  control: Control<FormDataProps>
  clearInput: () => void
  placeholderText?: string
  onClickBack?: () => void
  isFAQ?: boolean
  onFocus?: () => void
}

export const SearchInputBlock: FC<SearchInputBlockProps> = ({
  control,
  clearInput,
  placeholderText,
  onClickBack,
  isFAQ,
  onFocus,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 567px)' })

  const { debouncedFindTrack } = useTrackSearchForm()

  return (
    <InputWrapper isFAQ={isFAQ}>
      <BtnWrapper show={isMobile} isFAQ={isFAQ}>
        <BackButton onClick={onClickBack}>
          <ArrowIcon />
        </BackButton>
      </BtnWrapper>
      <GlobalStyled />
      <Controller
        name='searchInput'
        control={control}
        defaultValue=''
        render={({ field: { onChange, onBlur, value } }) => (
          <SearchInput
            autoFocus={!isMobile}
            isfaq={isFAQ?.toString()}
            withborder='withborder'
            value={value}
            name='searchInput'
            type='searchInput'
            autoComplete='off'
            required={false}
            id='searchInput'
            placeholder={isMobile ? placeholderText : ''}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              debouncedFindTrack(e.target.value)
            }}
          />
        )}
      />
      <BtnWrapper show={isMobile}>
        <BackButton onClick={clearInput}>
          <CloseCross />
        </BackButton>
      </BtnWrapper>
    </InputWrapper>
  )
}
