import { useCallback } from 'react'

import { HUNDRED_PERCENT, VAT_PERCENT, FIXED_BY } from 'src/Constants/constants'

import { Coupon } from '../Types'

interface CalculatePaymentParams {
  price?: number
  balance?: number
  coupon?: Coupon | null
  country?: string | null
}

interface CalculatePaymentResponse {
  couponDiscountAmount: number
  VATAmount: number
  total: number
  balanceUseAmount: number
}

interface Payment {
  calculatePaymentAmounts: (
    params: CalculatePaymentParams,
  ) => CalculatePaymentResponse | null
}

export const checkIsVATCountry = (country?: string | null): boolean =>
  country === 'IL' || country === 'ISR'

export const usePayment = (): Payment => {
  const calculatePaymentAmounts = useCallback(
    (params: CalculatePaymentParams): CalculatePaymentResponse | null => {
      const { price, balance, coupon, country } = params

      if (price && balance && price <= balance) {
        return {
          couponDiscountAmount: 0,
          VATAmount: 0,
          total: 0,
          balanceUseAmount: price,
        }
      }

      if (price && typeof balance === 'number') {
        const isVATCountry = checkIsVATCountry(country)
        const subtotalAmountWithBalance = price - balance
        const couponDiscountAmount = coupon
          ? subtotalAmountWithBalance * (coupon.discount / HUNDRED_PERCENT)
          : 0

        const subtotalValueWithBalanceAndCoupon =
          subtotalAmountWithBalance > 1
            ? subtotalAmountWithBalance - couponDiscountAmount
            : subtotalAmountWithBalance

        const VATAmount = isVATCountry
          ? subtotalValueWithBalanceAndCoupon * VAT_PERCENT
          : 0

        const subtotalValueWithBalanceAndCouponAndVAT =
          subtotalValueWithBalanceAndCoupon + VATAmount

        return {
          couponDiscountAmount,
          VATAmount,
          balanceUseAmount: balance,
          total: Number(
            subtotalValueWithBalanceAndCouponAndVAT.toFixed(FIXED_BY),
          ),
        }
      }

      return null
    },
    [],
  )

  return { calculatePaymentAmounts }
}
