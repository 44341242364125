import i18n from 'src/Localization/i18n'
import { BadgeType } from 'src/Components/Badge'

export enum UiSoundStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  MONITORING = 'MONITORING',
  NOT_COMPLETED = 'NOT_COMPLETED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
}

export enum SoundStatus {
  RUNNING = 'running',
  ACCEPTED = 'accepted',
  SUBMISSION_AWAITING = 'submission_awaiting',
  SUBMISSION_REJECTED = 'submission_rejected',
  AWAITING_REVIEW = 'awaiting_review',
  APPROVED_BY_ARTIST = 'approved_by_artist',
  APPROVED_AUTOMATICALLY = 'approved_automatically',
  AWAITING_MODERATOR_REVIEW = 'awaiting_moderator_review',
  APPROVED_BY_ADMIN = 'approved_by_admin',
  DECLINED = 'declined',
  VIDEO_DISMISSED = 'video_dismissed',
  CANCELED = 'canceled',
  STOPPED = 'campaign_stopped',
}

export const monitoringStatuses = [
  SoundStatus.AWAITING_REVIEW,
  SoundStatus.SUBMISSION_AWAITING,
  SoundStatus.APPROVED_BY_ARTIST,
  SoundStatus.APPROVED_AUTOMATICALLY,
  SoundStatus.AWAITING_MODERATOR_REVIEW,
  SoundStatus.APPROVED_BY_ADMIN,
]

const notCompletedStatuses = [
  SoundStatus.SUBMISSION_REJECTED,
  SoundStatus.DECLINED,
  SoundStatus.VIDEO_DISMISSED,
]

const completedOrConfirmStatuses = [
  SoundStatus.APPROVED_BY_ARTIST,
  SoundStatus.APPROVED_AUTOMATICALLY,
  SoundStatus.APPROVED_BY_ADMIN,
]

interface UseSoundStatusI {
  getStatusValues: (
    soundStatus: SoundStatus,
    presenceMilestoneDate?: boolean,
  ) => {
    title: string
    type: BadgeType
    uiStatus: UiSoundStatus
  }
}

export const useSoundStatus = (): UseSoundStatusI => {
  const getStatusValues = (
    soundStatus: SoundStatus,
    presenceMilestoneDay?: boolean,
  ): {
    title: string
    type: BadgeType
    uiStatus: UiSoundStatus
  } => {
    if (
      presenceMilestoneDay &&
      completedOrConfirmStatuses.includes(soundStatus)
    ) {
      return {
        title: i18n.t('soundsPage.completed'),
        type: 'regular',
        uiStatus: UiSoundStatus.COMPLETED,
      }
    }
    if (soundStatus === SoundStatus.RUNNING) {
      return {
        title: i18n.t('soundsPage.newSound'),
        type: 'regular',
        uiStatus: UiSoundStatus.NEW,
      }
    }

    if (soundStatus === SoundStatus.ACCEPTED) {
      return {
        title: i18n.t('soundsPage.inProgressStatus'),
        type: 'warning',
        uiStatus: UiSoundStatus.IN_PROGRESS,
      }
    }

    if (monitoringStatuses.includes(soundStatus)) {
      return {
        title: i18n.t('soundsPage.monitoring'),
        type: 'disabled',
        uiStatus: UiSoundStatus.MONITORING,
      }
    }

    if (notCompletedStatuses.includes(soundStatus)) {
      return {
        title: i18n.t('soundsPage.notCompleted'),
        type: 'danger',
        uiStatus: UiSoundStatus.NOT_COMPLETED,
      }
    }

    return {
      title: 'Unknown status',
      type: 'danger',
      uiStatus: UiSoundStatus.MONITORING,
    }
  }

  return {
    getStatusValues,
  }
}
