import { FC } from 'react'

import IconSpotify from 'src/Assets/Svg/spotify.svg?react'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { CampaignPlatformTypeEnum } from 'src/Types'

type IconComponentProps = React.SVGProps<SVGSVGElement> & {
  title?: string
  platform?: CampaignPlatformTypeEnum
}

const DEFAULT_SIZE = 16

export const IconComponent: FC<IconComponentProps> = ({
  platform,
  width = DEFAULT_SIZE,
  height = DEFAULT_SIZE,
}) => {
  if (platform === CampaignPlatformTypeEnum.SPOTIFY) {
    return <IconSpotify width={width} height={width} />
  }

  return <TikTokIcon width={height} height={height} />
}
