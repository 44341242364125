import { createSlice } from '@reduxjs/toolkit'

import { Artist, Genre, Language, Mood } from 'src/Types/index'
import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace, SliceStatus } from '../types'

import { getSpotifyTrack } from './api-actions'

export interface Dimensions {
  width: number
  height: number
}

export interface ImageType extends Dimensions {
  url: string
}

export interface Track {
  artist_spotify_id: string
  album: {
    album_type: string
    artists: Array<Artist>
    available_markets: Array<string>
    external_urls: { spotify: string }
    href: string
    id: string | number
    images: Array<ImageType>
    name: string
    release_date: string | Date
    release_date_precision: string
    total_tracks: number
    type: string
    uri: string
  }
  artists: Array<Artist>
  available_markets: Array<string>
  disc_number: number
  duration_ms: number
  explicit: boolean
  external_ids: { isrc: 'DECE72003523' }
  external_urls: { spotify: string }
  href: string
  id: string | number
  is_local: boolean
  name: string
  popularity: number
  preview_url: string
  track_number: number
  type: string
  uri: string
  track_name: string
  artist_name: string
  track_image: string
  track_url?: string
  track_id?: number
  TrackGenres: Array<Genre>
  TrackLanguages: Array<Language>
  TrackMoods: Array<Mood>
  spotify_id: string
  author?: string
  title?: string

  cover?: string
  spotify_popularity?: number
  spotify_popularity_result?: number
}

export interface TrackState {
  track: null | Track
  loading: SliceStatus
  error: ApiError | null
}

const initialState: TrackState = {
  track: null,
  loading: SliceStatus.Idle,
  error: null,
}

export const trackProcess = createSlice({
  initialState,
  name: NameSpace.Track,
  reducers: {
    setTrackToInitialState: (): TrackState => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    // getSpotifyTrack
    builder.addCase(getSpotifyTrack.pending, (state) => {
      state.loading = SliceStatus.Loading
    })
    builder.addCase(getSpotifyTrack.fulfilled, (state, action) => {
      state.track = action.payload
      state.error = null
      state.loading = SliceStatus.Succeeded
    })
    builder.addCase(getSpotifyTrack.rejected, (state, action) => {
      state.error = setApiError(action)
      state.track = null
      state.loading = SliceStatus.Failed
    })
  },
})

export const { setTrackToInitialState } = trackProcess.actions
