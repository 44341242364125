import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'src/Redux'
import { ApiError, AppDispatch } from 'src/Redux/types'

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

export type AppSelector<T> = (state: RootState) => T

export const setApiError = (
  action: PayloadAction<unknown, string, never, SerializedError>,
): ApiError => {
  if (action.payload) {
    return action.payload as ApiError
  }
  return {
    message: action?.error.message || '',
    code: Number(action?.error.code),
  }
}
