import React from 'react'

import { Button as AntButton } from 'antd'
import { useDebouncedCallback } from 'use-debounce'

import { DEBOUNCE_TIMER_300 } from 'src/Constants/constants'

import { DottedButtonProps } from './DottedButtonProps'

import { ButtonTitle, StyledButton } from './styles'

const DottedButton: React.FC<DottedButtonProps> = ({
  title,
  type,
  onClick,
  disabled,
}) => {
  const debouncedOnClick = useDebouncedCallback(() => {
    onClick && onClick()
  }, DEBOUNCE_TIMER_300)

  return (
    <StyledButton>
      <AntButton disabled={disabled || false} onClick={debouncedOnClick}>
        <ButtonTitle type={type}>{title}</ButtonTitle>
      </AntButton>
    </StyledButton>
  )
}

export default DottedButton
