import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Badge } from 'src/Components/Badge'

import { MobileBlockWrapper } from '../../styles'
import { SoundStatus, useSoundStatus } from '../../../../hooks/useSoundStatus'

type SoundsListItemProps = {
  status: SoundStatus
  presenceMilestoneDate?: boolean
}

export const StyledBadge = styled.div`
  display: flex;
  width: 93px;
  padding-right: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`

export const SoundListItemStatus: FunctionComponent<SoundsListItemProps> = ({
  status,
  presenceMilestoneDate,
}) => {
  const { getStatusValues } = useSoundStatus()
  const { title, type } = getStatusValues(status, presenceMilestoneDate)

  return (
    <MobileBlockWrapper>
      <StyledBadge>
        <Badge type={type} title={title} />
      </StyledBadge>
    </MobileBlockWrapper>
  )
}
