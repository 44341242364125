import React, { FC, useRef, useState } from 'react'

type AudioPlayerProps = React.DetailedHTMLProps<
  React.AudioHTMLAttributes<HTMLAudioElement>,
  HTMLAudioElement
> & {
  render: (props: {
    onClick: (e: React.MouseEvent<HTMLDivElement>, trackUrl?: string) => void
    loading: boolean
    currentTrackUrl?: string
    isPlaying: boolean
  }) => JSX.Element
}

export const TrackAudioPlayer: FC<AudioPlayerProps> = ({
  render,
  ...restProps
}) => {
  const audioPlayer = useRef<HTMLAudioElement>(null)
  const [currentTrackUrl, setCurrentTrackUrl] = useState('')
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const handleAudioEnd = (): void => {
    setIsPlaying(false)
  }

  const handleClickCover = (
    e: React.MouseEvent<HTMLDivElement>,
    trackUrl?: string,
  ): void => {
    e.stopPropagation()
    if (audioPlayer.current) {
      if (currentTrackUrl !== trackUrl || audioPlayer.current.paused) {
        setIsLoading(true)
        audioPlayer.current.src = trackUrl || ''
        audioPlayer.current.load()
        audioPlayer.current
          .play()
          .then((response) => {
            setIsLoading(false) // Stop loading once audio starts playing
            return response
          })
          .catch(() => {
            setIsLoading(false) // Stop loading on error
          })
        setCurrentTrackUrl(trackUrl || '')
        setIsPlaying(true)
      } else {
        audioPlayer.current.pause()
        setIsPlaying(false)
      }
    }
  }
  return (
    <>
      <audio {...restProps} ref={audioPlayer} onEnded={handleAudioEnd} />
      {render({
        currentTrackUrl,
        isPlaying,
        loading: isLoading,
        onClick: handleClickCover,
      })}
    </>
  )
}
