import React, { ReactElement } from 'react'

import { useParams } from 'react-router-dom'

import { useSubmittedSoundByIdQuery } from 'src/Redux/creatorSubmittedSoundsApi'
import dayjs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'

import { SomethingWentWrongAlert } from '../../../../../SomethingWentWrongAlert'
import { MonitoringFailedAlert } from '../../../../../MonitoringFailedAlert'
import { ViewsNotAchievedAlert } from '../../../../../ViewsNotAchivedAlert'
import { TaskNotCompletedAlert } from '../../../../../TaskNotCompletedAlert'
import { ViewsAchievedAlert } from '../../../../../ViewsAchievedAlert'
import { TaskCompletedAlert } from '../../../../../TaskCompletedAlert'

import { useAlerts } from './useAlerts'

export const Alerts = (): ReactElement | null => {
  const { soundId } = useParams<{ soundId: string }>()

  const { data: soundData, error: isDefaultErrorAlert } =
    useSubmittedSoundByIdQuery(Number(soundId))

  const {
    isMonitoringFailedAlert,
    isViewsNotAchievedAlert,
    isTaskNotCompletedAlert,
    isViewsAchievedAlert,
    isTaskCompletedAlert,
  } = useAlerts()

  if (!soundData) {
    return null
  }

  const rewardDate = dayjs(soundData.presenceMilestoneDate).format(
    formatDate.formatDateFirst,
  )

  return (
    <>
      {isDefaultErrorAlert && <SomethingWentWrongAlert />}
      {isMonitoringFailedAlert && <MonitoringFailedAlert />}
      {isViewsNotAchievedAlert && (
        <ViewsNotAchievedAlert
          minViews={soundData.minViews}
          currentViews={soundData.views}
        />
      )}
      {isTaskNotCompletedAlert && <TaskNotCompletedAlert />}
      {isViewsAchievedAlert && (
        <ViewsAchievedAlert
          minViews={soundData.minViews}
          currentViews={soundData.views}
          rewardDate={rewardDate}
        />
      )}
      {isTaskCompletedAlert && <TaskCompletedAlert />}
    </>
  )
}
