import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

const getBarColor = (color?: string): string => {
  switch (color) {
    case 'green':
      return colors.green
    case 'red':
      return colors.messegesRed
    default:
      return colors.green
  }
}

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  overflow: hidden;
  border-radius: 4px;
`

export const ChartValue = styled.div`
  color: ${colors.mainText};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
export const BarWrapper = styled.div`
  display: flex;
  height: 4px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: ${colors.greyStroke};
  border-radius: 4px;
  position: relative;
`
export const BarValue = styled.div<{
  percentageWidth?: number
  color?: string
}>`
  height: 4px;
  width: ${({ percentageWidth }) => `${percentageWidth || 0}%`};
  position: absolute;
  background-color: ${({ color }) => getBarColor(color)};
  border-radius: 4px;
`
export const Description = styled.div`
  color: ${colors.mainText};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
