import { FC, useEffect, useState } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import {
  Body,
  SearchWrapper,
  StyledTabs,
} from 'src/Containers/AdminPage/components/styles'
import {
  getArtistsCount,
  getArtistsSelector,
  getIsArtistListLoading,
} from 'src/Redux/adminPanel-process/artistsListSlice/selectors'
import { getArtists } from 'src/Redux/adminPanel-process/artistsListSlice/api-actions'
import {
  getFoundUsers,
  getIsSearchUsersLoading,
} from 'src/Redux/adminPanel-process/searchUsersSlice/selectors'
import { searchUsers } from 'src/Redux/adminPanel-process/searchUsersSlice/api-actions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import Search from 'src/Components/Search'
import {
  getCuratorsSelector,
  getIsCuratorListLoading,
  getTotalCurators,
} from 'src/Redux/adminPanel-process/curatorListSlice/selectors'
import { getCurators } from 'src/Redux/adminPanel-process/curatorListSlice/api-actions'
import { User } from 'src/Types/index'
import { TabName } from 'src/Containers/TikTokCampaignDetails/styles'

import { UserTable } from './UserTable'

const DELAY = 1000

const USER_COUNT = 50
const SEARCH_LENGTH = 3

export const UserManagementPanel: FC = () => {
  const dispatch = useAppDispatch()
  const artists = useAppSelector(getArtistsSelector)
  const curators = useAppSelector(getCuratorsSelector)
  const isArtistListLoading = useAppSelector(getIsArtistListLoading)
  const isCuratorListLoading = useAppSelector(getIsCuratorListLoading)
  const totalArtists = useAppSelector(getArtistsCount)
  const totalCurators = useAppSelector(getTotalCurators)
  const allFoundUsers = useAppSelector(getFoundUsers)
  const [isSearch, setIsSearch] = useState(false)
  const isSearchLoading = useAppSelector(getIsSearchUsersLoading)
  const [foundUsers, setFoundUsers] = useState<User[]>([])
  const [activeTab, setActiveTab] = useState('1')

  useEffect(() => {
    setFoundUsers(allFoundUsers.slice(0, USER_COUNT))
  }, [allFoundUsers])

  const handleFindUser = (str: string): void => {
    if (!str.length || str.length < SEARCH_LENGTH) {
      setIsSearch(false)
    } else {
      setIsSearch(true)
      void dispatch(searchUsers(str))
    }
  }

  const handleGetArtists = (page: number): void => {
    void dispatch(getArtists(page))
  }
  const handleGetCurators = (page: number): void => {
    void dispatch(getCurators({ page }))
  }

  const debouncedFindUser = useDebouncedCallback(handleFindUser, DELAY)

  const foundArtists = foundUsers.filter(({ isArtist }) => isArtist)
  const foundCurators = foundUsers.filter(({ isArtist }) => !isArtist)
  const items = [
    {
      label: <TabName>Artists &ensp;</TabName>,
      key: '1',
      children: (
        <UserTable
          loading={isArtistListLoading}
          getUsers={(page) => handleGetArtists(page)}
          isSearch={isSearch}
          setFoundUsers={setFoundUsers}
          totalUsers={isSearch ? foundArtists.length : totalArtists}
          users={isSearch ? foundArtists : artists}
        />
      ),
    },
    {
      label: <TabName> Curators &ensp;</TabName>,
      key: '2',
      children: (
        <UserTable
          loading={isCuratorListLoading}
          getUsers={handleGetCurators}
          isSearch={isSearch}
          setFoundUsers={setFoundUsers}
          totalUsers={isSearch ? foundCurators.length : totalCurators}
          users={isSearch ? foundCurators : curators}
        />
      ),
    },
  ]

  return (
    <Body>
      <SearchWrapper>
        <Search
          placeholder='Search'
          disabled={isSearchLoading}
          onChange={debouncedFindUser}
        />
      </SearchWrapper>
      <StyledTabs
        activeKey={activeTab}
        items={items}
        onChange={(activeKey: string) => setActiveTab(activeKey)}
      />
    </Body>
  )
}
