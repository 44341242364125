import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import ArrowIcon from 'src/Assets/Svg/arrow.svg?react'
import { colors } from 'src/Styled/variables'
import { SpotifyIcon, TikTokIcon } from 'src/Assets/Svg'
import { Track } from 'src/Redux/firstTrackApi'
import { CampaignPlatformTypeEnum } from 'src/Types'

import UserRegisterForm from '../UserRegisterForm'
import { FormDataProps } from '../UserRegisterForm/UserRegisterFormProps'

import { RegisterTitle } from './RegisterTitle'
import { TrackDetails } from './TrackDetails'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

const Header = styled.div`
  display: flex;
  height: 40px;
  max-width: 800px;
  width: 100%;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`
const ArrowBackButton = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  cursor: pointer;
`

export const TrackInfoAndFormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: unset;
  @media (min-width: 767px) {
    width: 50%;
  }
`

const FormContainer = styled.div`
  display: flex;
  max-width: 800px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  background: ${colors.white};
  @media (min-width: 767px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }
`

interface UserRegisterModalWithExperimentProps {
  onBackClick: () => void
  track?: Track
  platform?: CampaignPlatformTypeEnum
  emailExist: boolean
  onResetEmailExist: () => void
  onSubmit: (data: FormDataProps, token: string) => void
  setGTMEventWhenEnterPhoneNumber: () => void
}

export const UserRegisterModalWithExperiment: FC<
  UserRegisterModalWithExperimentProps
> = ({
  onBackClick,
  emailExist,
  onResetEmailExist,
  onSubmit,
  setGTMEventWhenEnterPhoneNumber,
  track,
  platform,
}) => {
  const { t } = useTranslation()
  const icon =
    platform === CampaignPlatformTypeEnum.SPOTIFY ? SpotifyIcon : TikTokIcon
  const title =
    platform === CampaignPlatformTypeEnum.SPOTIFY
      ? t('createCampaignPage.spotifyCampaign')
      : t('createCampaignPage.tikTokCampaign')
  return (
    <Container>
      <Header>
        <ArrowBackButton onClick={onBackClick}>
          <ArrowIcon />
        </ArrowBackButton>
        <RegisterTitle />
      </Header>
      <FormContainer>
        <TrackInfoAndFormWrapper>
          <UserRegisterForm
            emailExist={emailExist}
            setGTMEvent={setGTMEventWhenEnterPhoneNumber}
            handleResetEmailExist={onResetEmailExist}
            onSubmit={onSubmit}
          />
        </TrackInfoAndFormWrapper>
        <TrackDetails
          Icon={icon}
          title={title}
          coverUrl={track?.trackCoverUrl}
          trackName={track?.trackName}
          trackAuthorName={track?.artistName}
          duration={track?.trackDuration}
        />
      </FormContainer>
    </Container>
  )
}
