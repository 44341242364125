import { FC, PropsWithChildren } from 'react'

import { useMediaQuery } from 'react-responsive'

export const Mobile: FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 576 })
  if (!isMobile) {
    return null
  }
  return children
}
