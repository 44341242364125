import React, { ReactElement, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'
import { Rule } from 'src/Components/Rule'
import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import { ProblemsWithTikTokErrorAlert } from 'src/Components/ProblemsWithTikTokErrorAlert'
import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useAcceptSoundMutation } from 'src/Redux/creatorNewSoundsApi'
import { sounds } from 'src/Router/routes'

import { SomethingWentWrongAlert } from '../../../SomethingWentWrongAlert'
import { SoundStatus } from '../../../../hooks/useSoundStatus'
import { TabKeysEnum } from '../../../../types'

import { greenRules, redRules } from './helper'

import {
  ActionsWrapper,
  ButtonsWrapper,
  Container,
  ContentWrapper,
  Link,
  MainTitle,
  RulesList,
  StyledButton,
  StyledCheckbox,
  Label,
  CheckboxWrapper,
} from './styles'

const POLICY_LINK =
  'https://help.soundcamps.com/en/articles/9757453-video-requirements'

const YOU_ARE_LATE_URL = `${sounds}/${TabKeysEnum.NEW}?youAreLateConfirmModalVisible=true`

const NOT_ENOUGH_CURRENT_AMOUNT = 'not-enough-current-amount'

export const ConfirmRules = (): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { soundId } = useParams<{ soundId?: string }>()
  const { setSearch } = useCustomSearchParams()
  const [isRulesRead, setIsRulesRead] = useState(false)

  const { data: monetizationData, isError: isProblemsWithTikTokAlertVisible } =
    useGetMonetizationDataQuery()

  const [acceptSound, { isLoading, isError: isDefaultErrorVisible }] =
    useAcceptSoundMutation()

  const onCheckboxChange = (): void => {
    setIsRulesRead(!isRulesRead)
  }

  const onGoBackClick = (): void => {
    navigate(NAVIGATE_BACK_INDEX)
  }

  const onConfirmClick = (): void => {
    acceptSound({
      soundId: Number(soundId),
      minViews: monetizationData?.minViews || 0,
      maxViews: monetizationData?.maxViews || 0,
    })
      .then((response) => {
        if ('data' in response) {
          setSearch({
            soundStatus: SoundStatus.ACCEPTED,
            eventId: String(response.data?.eventId),
            deadline: response.data?.uploadVideoDeadlineDate || '',
            confirmRules: null,
          })
        }

        if ('error' in response) {
          const errorData = response.error as { data: { message: string } }

          errorData.data.message === NOT_ENOUGH_CURRENT_AMOUNT &&
            navigate(YOU_ARE_LATE_URL)
        }
        return true
      })
      .catch(() => false)
  }

  return (
    <Container>
      <MainTitle>{t('soundsPage.rulesTitle')}</MainTitle>
      {isDefaultErrorVisible && <SomethingWentWrongAlert />}
      {isProblemsWithTikTokAlertVisible && <ProblemsWithTikTokErrorAlert />}

      <ContentWrapper>
        <RulesList>
          {greenRules.map((rule) => (
            <Rule
              key={rule.title}
              title={rule.title}
              description={rule.description}
              type='green'
            />
          ))}
        </RulesList>

        <RulesList>
          {redRules.map((rule) => (
            <Rule
              key={rule.title}
              title={rule.title}
              description={rule.description}
              type='red'
            />
          ))}
        </RulesList>

        <ActionsWrapper>
          <CheckboxWrapper>
            <StyledCheckbox
              onChange={onCheckboxChange}
              checked={isRulesRead}
              title={''}
            />

            <Trans
              t={t}
              i18nKey='soundsPage.confirmText'
              components={{
                Label: <Label />,
                span: (
                  <Link
                    href={POLICY_LINK}
                    target='_blank'
                    rel='noopener noreferrer'
                  />
                ),
              }}
              defaults='<Label>Video contains the wrong track. Please use the track from the current campaign. To do this, open the track on TikTok and press the <span>Use this sound</span> button.</Label>'
            />
          </CheckboxWrapper>

          <ButtonsWrapper>
            <DottedBtn
              content={t('soundsPage.goBack')}
              onClick={onGoBackClick}
            />
            <StyledButton
              type={'green'}
              disabled={!isRulesRead}
              onClick={onConfirmClick}
              loading={isLoading}
            >
              {t('soundsPage.confirm')}
            </StyledButton>
          </ButtonsWrapper>
        </ActionsWrapper>
      </ContentWrapper>
    </Container>
  )
}
