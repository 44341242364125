import { FC, PropsWithChildren } from 'react'

import { useMediaQuery } from 'react-responsive'

const apiConfig = import.meta.env as Record<string, string>
export const Desktop: FC<PropsWithChildren> = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 577 })

  if (!isNotMobile) {
    return null
  }

  if (apiConfig.NODE_ENV === 'test') {
    return children
  }

  return children
}
