import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { device } from 'src/Styled/variables'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { tiktokAddTrackHintEvent } from 'src/Helpers/TagManager/HintsEvents'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import {
  getUserCampaignTypeSelector,
  getUserSelector,
} from 'src/Redux/auth-process/userSlice/selectors'

import { DottedBtn } from '../DottedBtn/DottedBtn'

export const HowToGetYourTrackURL = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  line-height: 150%;
  padding: 9.5px 0;
  margin-top: 8px;
  @media ${device.mobileMin} {
    margin-top: 12px;
  }
`

interface HowToGetYourTrackURLButtonProps {
  platform?: CampaignPlatformTypeEnum
}

export const HowToGetYourTrackURLButton: FC<
  HowToGetYourTrackURLButtonProps
> = ({ platform }) => {
  const { t } = useTranslation()
  const campaignType = useSelector(getUserCampaignTypeSelector)
  const user = useSelector(getUserSelector)
  const { setSearch, search } = useCustomSearchParams()
  const isHowGetTrackModalVisible =
    search.get('isHowGetTrackModalVisible') === 'true'

  const toggleModalVisibility = (): void => {
    setSearch({
      isHowGetTrackModalVisible: isHowGetTrackModalVisible ? null : 'true',
    })
    if (!isHowGetTrackModalVisible) {
      tiktokAddTrackHintEvent({
        campaignType,
        userId: user?.id,
      })
    }
  }

  if (platform !== CampaignPlatformTypeEnum.TIKTOK) {
    return null
  }

  return (
    <HowToGetYourTrackURL>
      <DottedBtn
        content={t('howToGetYourTrackURL.howToGetYourTrackURL')}
        onClick={() => toggleModalVisibility()}
      />
    </HowToGetYourTrackURL>
  )
}
