import { FunctionComponent, useState } from 'react'

import { useInProgressSoundsQuery } from 'src/Redux/creatorInProgressSoundsApi'

import { SoundsList } from '../index'
import { InProgressSoundsListItem } from '../../SoundsListItem/components/InProgressSoundsListItem'

export const InProgressSoundsList: FunctionComponent = () => {
  const [pageNumber, setPageNumber] = useState(1)

  const {
    data: inProgressSoundsData,
    isLoading,
    isFetching,
  } = useInProgressSoundsQuery(pageNumber)

  const inProgressSounds = inProgressSoundsData?.data || []
  const hasNextPage = inProgressSoundsData?.meta.hasNextPage || false

  const loadMore = (): void => {
    setPageNumber(pageNumber + 1)
  }

  return (
    <SoundsList
      hasNextPage={hasNextPage}
      isLoading={isLoading || isFetching}
      onLoadMore={loadMore}
      soundsAmount={inProgressSounds.length}
    >
      {inProgressSounds.map((item) => (
        <InProgressSoundsListItem
          key={item.id}
          id={item.id}
          trackAuthorName={item.trackAuthorName}
          trackCoverUrl={item.trackCoverUrl}
          trackTitle={item.trackTitle}
          deadline={item.uploadVideoDeadlineDate}
          maxCreatorReward={item.maxCreatorReward}
        />
      ))}
    </SoundsList>
  )
}
