import React, { ReactElement, useEffect, useMemo } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useSubmitVideoMutation } from 'src/Redux/creatorSubmittedSoundsApi'
import { sounds } from 'src/Router/routes'
import Button from 'src/Components/Buttons/Button'
import { SmartInput } from 'src/Components/SmartInput'
import { colors, variables } from 'src/Styled/variables'
import dayJs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useInProgressSoundByIdQuery } from 'src/Redux/creatorInProgressSoundsApi'
import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import i18n from 'src/Localization/i18n'
import { getValueOrZero } from 'src/Constants/functions'

import { DetailsTitle } from '../DetailsTitle'
import { FollowInfo } from '../FollowInfo'
import { Range } from '../Range'
import { TrackInfo } from '../TrackInfo'
import { Container, Content, Title } from '../../styles'
import { TabKeysEnum } from '../../../../types'

export const UrlInput = styled(SmartInput)<{ isError?: boolean }>`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  margin: auto;

  input {
    height: 43px;
    background-color: ${colors.white};
    border: ${({ isError }) =>
      isError
        ? `1px solid ${colors.messegesRed}`
        : `1px solid ${colors.greyStroke}`};
    border-radius: 8px;
    width: 100%;
    padding: 13px 16px;
    font-size: ${variables.fontSize14};
    color: ${colors.greyTextWhiteBg};

    &::-webkit-input-placeholder {
      color: ${colors.greyTextWhiteBg};
    }
    &::-moz-placeholder {
      color: ${colors.greyTextWhiteBg};
    }
    &:-moz-placeholder {
      color: ${colors.greyTextWhiteBg};
    }
    &:-ms-input-placeholder {
      color: ${colors.greyTextWhiteBg};
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
`

export const UrlBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

export const InputError = styled.span`
  color: ${colors.messegesRed};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`
export const InputWrapper = styled.div`
  width: 100%;
`
interface IFormInput {
  videoTiktokUrl: string
}

export const StyledButton = styled(Button)`
  width: 162px;
`

export type VideoValidationError =
  | 'incorrect-track-in-video'
  | 'small-video-duration'
  | 'not-tiktok-link'
  | 'not-owner-tiktok-link'
  | 'not-tiktok-video-link'
  | 'video-not-available'

const errorModalLink = `${sounds}/${TabKeysEnum.IN_PROGRESS}?errorModalVisible=true`
const inputPlaceholder = i18n.t('soundsPage.tikTokUrl')
const defaultError = i18n.t('soundsPage.errorLink')

const errorsList: { [key in VideoValidationError]: string } = {
  'not-tiktok-video-link': defaultError,
  'small-video-duration': i18n.t('soundsPage.errorTooShort'),
  'not-tiktok-link': defaultError,
  'incorrect-track-in-video': i18n.t('soundsPage.errorWrongSound'),
  'not-owner-tiktok-link': defaultError,
  'video-not-available': defaultError,
}

const EVENT_NOT_EXISTS = 'event-for-this-influencer-does-not-exists'
const YOU_ARE_LATE_URL = `${sounds}/${TabKeysEnum.IN_PROGRESS}?youAreLateUploadModalVisible=true`

export const InProgressSoundDetails = (): ReactElement => {
  const { t } = useTranslation()
  const { setSearch } = useCustomSearchParams()
  const navigate = useNavigate()

  const { register, handleSubmit } = useForm<IFormInput>()

  const { soundId } = useParams<{ soundId: string }>()

  const [submitVideo, { error: urlValidationError }] = useSubmitVideoMutation()

  const { isError: isRangeOutdated } = useGetMonetizationDataQuery()

  const { data: soundData, error: isDefaultErrorAlert } =
    useInProgressSoundByIdQuery(Number(soundId))

  const formattedDeadlineDate = dayJs(
    soundData?.uploadVideoDeadlineDate,
  ).format(formatDate.formatDateFirst2)

  useEffect(() => {
    if (isDefaultErrorAlert) {
      navigate(errorModalLink)
    }
  }, [isDefaultErrorAlert, navigate])

  const onSubmit: SubmitHandler<IFormInput> = ({ videoTiktokUrl }) => {
    void submitVideo({
      videoTiktokUrl,
      eventId: Number(soundId),
    }).then((response) => {
      const isError = 'error' in response

      if (isError) {
        const errorData = response.error as { data: { message: string } }

        errorData.data.message === EVENT_NOT_EXISTS &&
          navigate(YOU_ARE_LATE_URL)
      }

      if (!isError) {
        setSearch({
          soundStatus: 'submitted',
          creditedDate: dayJs(
            response.data.presenceMilestoneDate,
          ).toISOString(),
        })
      }

      return true
    })
  }

  const linkValidationError = useMemo(() => {
    if (urlValidationError && 'data' in urlValidationError) {
      const errorData = urlValidationError.data as {
        message: VideoValidationError
      }
      if (errorData.message) {
        return errorsList[errorData.message]
      }
      return defaultError
    }

    return null
  }, [urlValidationError])

  return (
    <Container>
      <DetailsTitle
        badgeTitle={t('soundsPage.inProgress')}
        badgeType={'warning'}
        title={t('soundsPage.uploadVideoTitle')}
      />

      <Content>
        {soundData && (
          <TrackInfo
            trackCoverUrl={soundData.trackCoverUrl}
            trackAuthorName={soundData.trackAuthorName}
            trackName={soundData.trackTitle}
            trackTiktokUrl={soundData.trackTiktokUrl}
          />
        )}

        <FollowInfo comment={soundData?.comment} />
        <Range
          isRangeOutdated={isRangeOutdated}
          minCreatorReward={getValueOrZero(soundData?.minCreatorReward)}
          maxCreatorReward={getValueOrZero(soundData?.maxCreatorReward)}
          maxViews={getValueOrZero(soundData?.maxViews)}
          minViews={getValueOrZero(soundData?.minViews)}
        />
        <UrlBlock>
          <Title>
            {t('soundsPage.submitUpTo')} {formattedDeadlineDate}
          </Title>
          <InputWrapper>
            <UrlInput
              {...register('videoTiktokUrl')}
              placeholder={inputPlaceholder}
              isError={Boolean(linkValidationError)}
            />
            {linkValidationError && (
              <InputError>{linkValidationError}</InputError>
            )}
          </InputWrapper>
        </UrlBlock>
        <StyledButton type={'green'} onClick={handleSubmit(onSubmit)}>
          {t('soundsPage.submitVideo')}
        </StyledButton>
      </Content>
    </Container>
  )
}
