import { useSelector } from 'react-redux'

import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import {
  campaignDetailsEvent,
  createCampaignEvent,
  createdCampaignEvent,
} from 'src/Helpers/TagManager'
import { getAmountOfCampaigns } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { createTiktokCampaignEvent } from 'src/Redux/googleAnalytics-process/api-actions'
import {
  firstCampaignCreationStartMpEvent,
  newCampaignCreationStartMpEvent,
} from 'src/Redux/ga4-process/api-actions'
import { getIsAuth } from 'src/Redux/auth-process/credentialsSlice/selectors'

import { useAppDispatch } from '../redux'

export const useCreateCampaignEvents = (): {
  createCampaignEventHandler: ({
    campaignId,
  }: {
    campaignId: string | number
  }) => void

  createdTikTokCampaignEventHandler: ({
    campaignId,
    trackTiktokId,
  }: {
    campaignId: string | number
    trackTiktokId?: string | number
  }) => void

  createTiktokCampaignEventHandler: ({
    campaignId,
  }: {
    campaignId: string | number
  }) => void

  campaignDetailsEventHandler: ({
    campaignType,
    platformType,
  }: {
    campaignType: CampaignTypeEnum
    platformType: CampaignPlatformTypeEnum
  }) => void
  createdSpotifyCampaignEventHandler: ({
    campaignId,
    spotifyTrackId,
    isFeedCampaign,
  }: {
    campaignId: string | number
    spotifyTrackId: string | number
    isFeedCampaign: boolean
  }) => void

  campaignCreationStartMpEventHandler: ({
    platformType,
  }: {
    platformType: CampaignPlatformTypeEnum
  }) => void
} => {
  const dispatch = useAppDispatch()

  const amountOfCampaigns = useSelector(getAmountOfCampaigns)
  const userID = useSelector(getUserId)
  const isAuth = useSelector(getIsAuth)

  const referrerUrl = document.referrer
  const urlParams = new URLSearchParams(window.location.search)
  const utmCampaign = urlParams?.get('utm_campaign')
  const utmContent = urlParams?.get('utm_content')
  const utmMedium = urlParams?.get('utm_medium')
  const utmSource = urlParams?.get('utm_source')
  const utmTerm = urlParams?.get('utm_term')

  const campaignNumber = amountOfCampaigns + 1

  const createCampaignEventHandler = ({
    campaignId,
  }: {
    campaignId: string | number
  }): void => {
    // CreatedCampaign GTM event
    createCampaignEvent(String(userID), String(campaignId))
  }

  const createdTikTokCampaignEventHandler = ({
    campaignId,
    trackTiktokId,
  }: {
    campaignId: string | number
    trackTiktokId?: string | number
  }): void => {
    // created_campaign GTM event
    campaignNumber &&
      createdCampaignEvent({
        campaignNumber,
        campaignId,
        isFirstCampaign: false,
        platformName: CampaignPlatformTypeEnum.TIKTOK,
        trackId: trackTiktokId || campaignId,
        userId: String(userID),
        is_feed: false,
      })
  }

  const createTiktokCampaignEventHandler = ({
    campaignId,
  }: {
    campaignId: string | number
  }): void => {
    // campaign/create-tiktok-campaign-event/:id
    void dispatch(
      createTiktokCampaignEvent({
        campaignID: Number(campaignId),
        url: window.location.href,
      }),
    )
  }

  const campaignDetailsEventHandler = ({
    campaignType,
    platformType,
  }: {
    campaignType: CampaignTypeEnum
    platformType: CampaignPlatformTypeEnum
  }): void => {
    // campaign.details GTM event
    campaignDetailsEvent(platformType, campaignNumber, campaignType)
  }

  const createdSpotifyCampaignEventHandler = ({
    campaignId,
    spotifyTrackId,
    isFeedCampaign,
  }: {
    campaignId: string | number
    spotifyTrackId: string | number
    isFeedCampaign: boolean
  }): void => {
    campaignId &&
      campaignNumber &&
      createdCampaignEvent({
        campaignNumber,
        campaignId,
        isFirstCampaign: false,
        platformName: CampaignPlatformTypeEnum.SPOTIFY,
        trackId: spotifyTrackId,
        userId: String(userID),
        danceRequired: false,
        is_feed: isFeedCampaign,
      })
  }

  const campaignCreationStartMpEventHandler = ({
    platformType,
  }: {
    platformType: CampaignPlatformTypeEnum
  }): void => {
    !isAuth
      ? void dispatch(
          firstCampaignCreationStartMpEvent({
            campaignPlatform: platformType,
            referrerUrl,
            utmCampaign,
            utmContent,
            utmMedium,
            utmSource,
            utmTerm,
          }),
        )
      : void dispatch(
          newCampaignCreationStartMpEvent({
            campaignPlatform: platformType,
            campaignType: CampaignTypeEnum.BASIC,
          }),
        )
  }
  return {
    createCampaignEventHandler,
    createdTikTokCampaignEventHandler,
    createTiktokCampaignEventHandler,
    campaignDetailsEventHandler,
    createdSpotifyCampaignEventHandler,
    campaignCreationStartMpEventHandler,
  }
}
