import { FC } from 'react'

import styled from 'styled-components'
import { Radio, RadioChangeEvent } from 'antd'
import { useTranslation } from 'react-i18next'

import { colors, variables } from 'src/Styled/variables'
import { PaymentMethodEnum } from 'src/Constants/enums'
import PayPalLogoSmall from 'src/Assets/Svg/paypal_logo_small.svg?react'
import Button from 'src/Components/Buttons/Button'
import LockIcon from 'src/Assets/Svg/lock.svg?react'
import { useAppSelector } from 'src/Hooks/redux'
import { isCreateBillingAgreementLoadingSelector } from 'src/Redux/billingAgreement-process/create-billingAgreement'
import { isSubscriptionLoadingSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { subscriptionConfirmedPaymentEvent } from 'src/Helpers/TagManager'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getBillingAgreementEmailSelector } from 'src/Redux/billingAgreement-process/get-billingAgrreement'
import { obfuscateEmail } from 'src/Helpers/layoutHelpers'
import { StripeCardInfo } from 'src/Components/StripeCardInfo'
import { CustomRadio } from 'src/Containers/ui/CustomRadio'

import { usePayPaypalSubscription } from './usePayPaypalSubscription'
import { PaymentError } from './PaymentError'
import { usePayStripeSubscription } from './usePayStripeSubscription'
import { usePayMethod } from './usePayMethod'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

const Title = styled.p`
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  width: 100%;
`
const Email = styled.p`
  color: ${colors.mainText};
  margin: 0;
  padding: 0;
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  button {
    width: fit-content !important;
    padding: 0 40px;
  }
`

const SecureContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
const SecureLabel = styled.div`
  color: ${colors.greyTextWhiteBg};
  font-size: ${variables.fontSize10};
  line-height: 150%;
`

const StyledButton = styled(Button)`
  .ant-btn.ant-btn-default {
    width: 202px !important;
  }
`

export const Payment: FC = () => {
  const { t } = useTranslation()
  const { onPayPaypal, hasBillingAgreement } = usePayPaypalSubscription()
  const { onPayStripe, hasIntent, cardInfo } = usePayStripeSubscription()
  const userId = useAppSelector(getUserId)
  const { search } = useCustomSearchParams()
  const plan = search.get('plan') || ''
  const email = useAppSelector(getBillingAgreementEmailSelector)
  const isCreateBillingAgreementLoading = useAppSelector(
    isCreateBillingAgreementLoadingSelector,
  )
  const isSubscriptionLoading = useAppSelector(isSubscriptionLoadingSelector)
  const [paymentMethod, setPaymentMethod] = usePayMethod(hasIntent)

  const handleChange = (e: RadioChangeEvent): void => {
    setPaymentMethod(e.target.value as PaymentMethodEnum)
  }
  const handleClick = async (): Promise<void> => {
    if (paymentMethod === PaymentMethodEnum.PAYPAL) {
      const paypalMethod = hasBillingAgreement ? 'PayPal_saved' : 'PayPal'
      subscriptionConfirmedPaymentEvent({
        userId,
        planName: plan,
        paymentMethod: paypalMethod,
      })
      onPayPaypal()
    } else {
      const paypalMethod = hasIntent ? 'Stripe_saved' : 'Stripe'
      subscriptionConfirmedPaymentEvent({
        userId,
        planName: plan,
        paymentMethod: paypalMethod,
      })
      await onPayStripe()
    }
  }
  return (
    <Wrapper>
      <Container>
        <Title>{t('getPremiumPage.selectPaymentMethod')}</Title>
        <StyledRadioGroup onChange={handleChange} value={paymentMethod}>
          <CustomRadio value={PaymentMethodEnum.PAYPAL}>
            <PayPalLogoSmall />
            {email && <Email>{obfuscateEmail(email)}</Email>}
          </CustomRadio>
          <CustomRadio value={PaymentMethodEnum.STRIPE}>
            <StripeCardInfo cardInfo={cardInfo} />
          </CustomRadio>
        </StyledRadioGroup>
      </Container>
      <PaymentError />
      <ButtonContainer>
        <StyledButton
          loading={isCreateBillingAgreementLoading || isSubscriptionLoading}
          onClick={handleClick}
          type={paymentMethod === PaymentMethodEnum.PAYPAL ? 'paypal' : 'green'}
        >
          {t('scheduleCampaignPage.confirm&Pay')}
        </StyledButton>
        <SecureContainer>
          <LockIcon width={'16px'} height={'16px'} />
          <SecureLabel>{t('scheduleCampaignPage.ssl')}</SecureLabel>
        </SecureContainer>
      </ButtonContainer>
    </Wrapper>
  )
}
