import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import checkedCircle from 'src/Assets/Png/checked-circle.png'
import { variables, colors } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'

const MessageContainer = styled.div`
  position: fixed;
  z-index: 1002;
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
`

const SuccessIcon = styled.img`
  margin: 0 auto;
  width: 48px;
  height: 48px;
`

const SuccessText = styled.div`
  padding-top: 40px;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize20};
  color: ${colors.mainText};
  line-height: 150%;
`

const ButtonsContainer = styled.div`
  margin-top: 40px;
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  width: 114px;
`

interface SuccessSendReviewProps {
  isSuccess: boolean
  onClose: () => void
}

export const SuccessSendReview: FC<SuccessSendReviewProps> = ({
  isSuccess,
  onClose,
}) => {
  const { t } = useTranslation()
  if (!isSuccess) {
    return null
  }
  return (
    <MessageContainer>
      <SuccessIcon width={48} height={48} src={checkedCircle} />
      <SuccessText>{t('reviewsPage.sendSuccessReview')}</SuccessText>
      <ButtonsContainer>
        <Button type='white' onClick={onClose}>
          {t('reviewsPage.close')}
        </Button>
      </ButtonsContainer>
    </MessageContainer>
  )
}
