import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'

import { CampaignVideo } from '../../../Types'

import { getVideoByEvent } from './api-actions'

export interface IVideoProcess {
  error: ApiError | null
  status: SliceStatus
  video: CampaignVideo | null
}

export const initialState: IVideoProcess = {
  error: null,
  video: null,
  status: SliceStatus.Idle,
}

export const videoProcess = createSlice({
  initialState,
  name: NameSpace.Video,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVideoByEvent.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getVideoByEvent.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.video = action.payload
      state.error = null
    })
    builder.addCase(getVideoByEvent.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
