import { FC } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { string as yupString, object, number as yupNumber } from 'yup'

import { artistReviewSentEvent } from 'src/Helpers/TagManager'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { respondToReview } from 'src/Redux/reviews-process/api-actions'
import { CampaignTypeEnum, ID } from 'src/Types'
import { getAmountOfCampaigns } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import Button from 'src/Components/Buttons/Button'
import { DEBOUNCE_DELAY } from 'src/Constants/numeric'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { useLazyGetCampaignDetailsQuery } from 'src/Redux/campaignDetailsApi'

import SelectResponseRating from '../../SelectResponseRating'
import SelectResponseText from '../../SelectResponseText'
import { RateReviewValues } from '../props'
import { ResponseForm } from '../styles'

interface ReviewFeedBackFormProps {
  reviewId: ID
  campaignID: number
}

export const ReviewFeedBackForm: FC<ReviewFeedBackFormProps> = ({
  reviewId,
  campaignID,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [trigger, { data }] = useLazyGetCampaignDetailsQuery()
  const user = useAppSelector(getUserSelector)
  const amountOfCampaigns = useAppSelector(getAmountOfCampaigns)

  const {
    control,
    getValues,
    formState: { isValid },
  } = useForm<RateReviewValues>({
    resolver: yupResolver(
      object({
        responseReviewRatingSelect: yupNumber().moreThan(0).required(),
        responseReviewSelect: object({
          value: yupString().required(),
        }),
      }),
    ),
    defaultValues: {
      responseReviewRatingSelect: 0,
      responseReviewSelect: {
        value: '',
      },
    },
  })

  const onSubmit = (values: RateReviewValues): void => {
    void dispatch(
      respondToReview({
        review_id: reviewId,
        rating: values.responseReviewRatingSelect,
        respond: values.responseReviewSelect.value,
      }),
    )
      .unwrap()
      .then(async (response) => {
        await trigger(campaignID)
        if (data) {
          artistReviewSentEvent(
            data.type,
            data.type === CampaignTypeEnum.FIRST ? 1 : amountOfCampaigns,
            data.id,
            user.id,

            values.responseReviewRatingSelect,
            values.responseReviewSelect.value,
            reviewId,
          )
        }
        return response
      })
  }

  const debouncedCallback = useDebouncedCallback(() => {
    onSubmit(getValues())
  }, DEBOUNCE_DELAY)

  return (
    <ResponseForm>
      <Controller
        control={control}
        name='responseReviewRatingSelect'
        render={({ field: { onChange, value, ref } }) => (
          <SelectResponseRating
            text={t('campaignResultsPage.rateThisReview')}
            ref={ref}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name='responseReviewSelect'
        render={({ field: { onChange, value, ref } }) => (
          <SelectResponseText
            ref={ref}
            value={value.value}
            onChange={({ value: newValue }) =>
              onChange({ value: newValue.toString() })
            }
          />
        )}
      />

      <Button type='white' onClick={debouncedCallback} disabled={!isValid}>
        {t('campaignResultsPage.send')}
      </Button>
    </ResponseForm>
  )
}
