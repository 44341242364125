import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { offensiveValue, poorValue, thankValue } from 'src/Constants/constants'
import { ArtistFeedback } from 'src/Types'
import AngryFaceGreyIcon from 'src/Assets/Svg/angry-face-grey.svg?react'
import DislikeGreyIcon from 'src/Assets/Svg/dislike-grey.svg?react'
import HeartGreyIcon from 'src/Assets/Svg/heart-grey.svg?react'

interface ArtistFeedbackComponentProps {
  feedback: ArtistFeedback
}

const RespondedIcon = styled.div`
  display: flex;
  margin-right: 8px;
  width: 16px;
  height: 16px;
`

export const ArtistFeedbackComponent: FC<ArtistFeedbackComponentProps> = ({
  feedback,
}) => {
  const { t } = useTranslation()
  switch (feedback) {
    case offensiveValue:
      return (
        <>
          <RespondedIcon>
            <AngryFaceGreyIcon width={16} height={16} />
          </RespondedIcon>
          <span>{t('campaignResultsPage.soundsOffensive')}</span>
        </>
      )
    case poorValue:
      return (
        <>
          <RespondedIcon>
            <DislikeGreyIcon width={16} height={16} />
          </RespondedIcon>
          <span>{t('campaignResultsPage.notUsefulFeedback')}</span>
        </>
      )
    case thankValue:
    default:
      return (
        <>
          <RespondedIcon>
            <HeartGreyIcon width={16} height={16} />
          </RespondedIcon>
          <span>{t('campaignResultsPage.thankYou')}</span>
        </>
      )
  }
}
