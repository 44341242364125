import { PaymentMethodEnum } from 'src/Constants/enums'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
type SetCurrentType = (type: PaymentMethodEnum) => void
const getSavedMethod = (hasIntent: boolean): PaymentMethodEnum =>
  hasIntent ? PaymentMethodEnum.STRIPE : PaymentMethodEnum.PAYPAL
type PaymentMethod = PaymentMethodEnum | null
export const usePayMethod = (
  hasIntent: boolean,
): [PaymentMethodEnum, SetCurrentType] => {
  const { search, setSearch } = useCustomSearchParams()

  const paymentType = search.get('paymentType') as PaymentMethod
  const currentType = paymentType ? paymentType : getSavedMethod(hasIntent)

  const setCurrentType = (type: PaymentMethodEnum): void => {
    setSearch({ paymentType: type, error: null })
  }
  return [currentType, setCurrentType]
}
