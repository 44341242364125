import { FC, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { useAppDispatch } from 'src/Hooks/redux'
import { Desktop, Mobile } from 'src/Components/Responsive'
import { campaignsList, details } from 'src/Router/routes'
import { getVideoByEvent } from 'src/Redux/campaignVideo-process/video-process/api-actions'

import { TiktokReviewDesktop } from './components/TiktokReviewDesktop'
import { TiktokReviewMobile } from './components/TiktokReviewMobile'

export interface TiktokReviewParams {
  campaignID?: string
  videoID?: string
}

export const TiktokReview: FC = () => {
  const { campaignID, videoID } = useParams<keyof TiktokReviewParams>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    if (campaignID) {
      void dispatch(
        getVideoByEvent({
          campaignId: Number(campaignID),
          eventId: Number(videoID),
        }),
      )
        .unwrap()
        .catch(() => {
          navigate(`${campaignsList}${details}/${Number(campaignID)}`)
        })
    }
  }, [campaignID, dispatch, navigate, videoID])

  // TODO need to merge desktop and mobile components

  return (
    <>
      <Desktop>
        <TiktokReviewDesktop />
      </Desktop>
      <Mobile>
        <TiktokReviewMobile />
      </Mobile>
    </>
  )
}
