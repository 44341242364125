import { FC, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import logo from 'src/Assets/Png/logo.png'
import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'

import { ForgotPasswordForm } from './components/ForgotPasswordForm'

import { Card, Container, Header, Logo, Text, Wrapper } from './styles'

const ForgotPassword: FC = () => {
  const { t } = useTranslation()

  useEffect(() => {
    pageViewEvent({ currentPageInfo: pageInfo.forgotPassword })
    locationEvent()
  }, [])

  return (
    <Wrapper>
      <Container>
        <Logo>
          <img alt='logo' src={logo} />
        </Logo>
        <Card>
          <Header>
            <h3>{t('forgotPasswordPage.forgotPassword')}</h3>
          </Header>
          <ForgotPasswordForm />
          <Text>
            <Link to='/'>{t('forgotPasswordPage.backToLogin')}</Link>
          </Text>
        </Card>
      </Container>
    </Wrapper>
  )
}

export default ForgotPassword
