import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const DescriptionWrapper = styled.div`
  text-align: center;
  padding: 8px 8px 64px 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${colors.mainText};

  @media ${device.mobileMax} {
    padding: 8px 8px 24px 8px;
  }
`
export const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ErrorTitle = styled.h3`
  color: ${colors.mainText};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
`

export const PlayerError = styled.div`
  border-radius: 4px;
  background-color: #f9f9f9;
  display: flex;
  width: 300px;
  height: 565px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PlayerWrapper = styled.div`
  & blockquote {
    min-height: auto !important;
    min-width: auto !important;
    max-width: auto !important;
    width: auto;
    height: 565px;
    overflow: hidden;
    border: none;
    border-radius: 4px;
    padding: 0;
    margin: 0 !important;
  }
  @media ${device.mobileMax} {
    align-items: center;
    justify-content: center;
    /* margin-top: 22px; */
  }
`

export const LinkTiktok = styled.a`
  color: ${colors.red};
  border-bottom: 1px dashed ${colors.red};
  &:hover {
    color: ${colors.red};
  }
`
