import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { getValueOrZero } from 'src/Constants/functions'
import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import { colors, variables } from 'src/Styled/variables'

import { MobileBlockWrapper, SmallText } from '../../styles'

type ViewsInfoProps = {
  currentViews: number
}

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 96px;
`
export const NormalText = styled.span`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${colors.mainText};
  white-space: nowrap;
`

export const ViewsInfo: FunctionComponent<ViewsInfoProps> = ({
  currentViews,
}) => {
  const { t } = useTranslation()

  const { data: monetizationData } = useGetMonetizationDataQuery()

  const formattedMinViews = addSpaceToNumber(
    getValueOrZero(monetizationData?.minViews),
    ',',
  )

  const formattedCurrentViews = addSpaceToNumber(
    getValueOrZero(currentViews),
    ',',
  )

  const formattedViews = `${formattedCurrentViews} / ${formattedMinViews}`

  return (
    <MobileBlockWrapper>
      <ValuesContainer>
        <SmallText>{t('soundsPage.views')}</SmallText>
        <NormalText>{formattedViews}</NormalText>
      </ValuesContainer>
    </MobileBlockWrapper>
  )
}
