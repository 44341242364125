import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

export interface MonetizationData {
  minViews: number
  maxViews: number
  minCreatorReward: number
  maxCreatorReward: number
}

const monetizationApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getMonetizationData: builder.query<MonetizationData, void>({
      query: () => `${APP.TIKTOK_SERVER}/influencer/tiktok/v2/monetization`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetMonetizationDataQuery, useLazyGetMonetizationDataQuery } =
  monetizationApi
