import { FC } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import ReactLoading from 'react-loading'
import styled from 'styled-components'

import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { campaignsList, details } from 'src/Router/routes'
import { useAppSelector } from 'src/Hooks/redux'
import { colors, variables } from 'src/Styled/variables'
import {
  getIsVideoDeclined,
  getIsVideoDeletedFromTikTok,
  getIsVideoLoading,
  getPricingModelVersion,
  getVideo,
} from 'src/Redux/campaignVideo-process/video-process/selectors'
import { TikTokCampaignPricingModelEnum } from 'src/Types'
import { getValueOrZero } from 'src/Constants/functions'

import { RateContainer } from '../RateConteiner'
import { TiktokReviewHeader } from '../TiktokReviewHeader'
import { TikTokPlayer } from '../TiktokPlayer'
import { TiktokReviewParams } from '../..'
import { CreatorInfo } from '../CreatorInfo'
import { TiktokRateError } from '../TiktokRateError'
import { ViewsProgressInfo } from '../ViewsProgressInfo'
import { WontChargedAlerts } from '../WontChargedAlerts'

import {
  CloseButton,
  TiktokDetailsContainer,
  TiktokReviewDetailsContainer,
  LoaderWrapper,
  TiktokReviewDesktopWrapper,
} from './styles'

export const InfoContainer = styled.div`
  display: flex;
  min-width: 288px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`

export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
`

export const TiktokReviewDesktop: FC = () => {
  const navigate = useNavigate()
  const { campaignID } = useParams<keyof TiktokReviewParams>()
  const isLoading = useAppSelector(getIsVideoLoading)
  const video = useAppSelector(getVideo)
  const isVideoDeclinedOrRejected = useAppSelector(getIsVideoDeclined)
  const isVideoDeleted = useAppSelector(getIsVideoDeletedFromTikTok)

  const pricingModel = useAppSelector(getPricingModelVersion)

  const handleClose = (): void => {
    if (campaignID) {
      navigate(`${campaignsList}${details}/${campaignID}`)
    }
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <ReactLoading
          color={colors.badgeGreen}
          width={'50px'}
          height={'50px'}
          type='spin'
        />
      </LoaderWrapper>
    )
  }

  const isViewsHidden =
    pricingModel === TikTokCampaignPricingModelEnum.FollowersBased ||
    isVideoDeclinedOrRejected ||
    isVideoDeleted

  return (
    <TiktokReviewDesktopWrapper>
      <TiktokDetailsContainer>
        <TiktokReviewHeader />
        <WontChargedAlerts />
        <TiktokRateError />
        <RateContainer />
        <TiktokReviewDetailsContainer>
          <TikTokPlayer url={video?.videoLink || ''} />
          <InfoContainer>
            <CreatorInfo />
            {!isViewsHidden && (
              <ViewsProgressInfo
                currentViews={getValueOrZero(video?.viewsCount)}
                minViews={getValueOrZero(video?.minViews)}
                milestoneDate={video?.minViewsDeadline || ''}
              />
            )}
          </InfoContainer>
        </TiktokReviewDetailsContainer>
      </TiktokDetailsContainer>
      <CloseButton onClick={handleClose}>
        <CloseCross width='16px' height='16px' />
      </CloseButton>
    </TiktokReviewDesktopWrapper>
  )
}
