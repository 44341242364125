import React, { FC, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { getCoupon } from 'src/Redux/schedule-process/couponSlice/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { usePaymentAmountsWithCurrency } from 'src/Hooks/usePaymentAnountsWithCurrency'
import { useAppSelector } from 'src/Hooks/redux'
import { getIsCreditsMethod } from 'src/Redux/payment-process/selectors'
import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'

import { TrackInfo } from '../TrackInfo'

import {
  CampaignsTracksPromocode,
  CampaignsTracksPromocodeDiscount,
  CampaignsTracksPromocodeExist,
  Divider,
  DividerWithBottomMargin,
  TopRow,
  Row,
  BottomRow,
  RowText,
  CampaignSummaryTitle,
} from './styles'

export const ValuesBlock: FC<{ platformName: CampaignPlatformTypeEnum }> = ({
  platformName,
}) => {
  const { t } = useTranslation()
  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)
  const tikTokCampaignDetails = useAppSelector(getScheduledTikTokCampaign)
  const isCreditsMethod = useAppSelector(getIsCreditsMethod)
  const coupon = useAppSelector(getCoupon)

  const { startNextTimeBonusCountdown } = useBonusTooltipTimer()

  const {
    currencyDiscount,
    campaignCurrencyBudget,
    currencyVat,
    currencyBalance,
    currencyTotal,
    isVATCountry,
  } = usePaymentAmountsWithCurrency(platformName)

  useEffect(() => {
    startNextTimeBonusCountdown()
  }, [startNextTimeBonusCountdown])

  const isSpotify = platformName === CampaignPlatformTypeEnum.SPOTIFY

  const trackAuthor = isSpotify
    ? spotifyCampaignDetails?.track?.author
    : tikTokCampaignDetails?.track?.authorName ||
      tikTokCampaignDetails.trackAuthorName

  const trackTitle = isSpotify
    ? spotifyCampaignDetails?.track?.title
    : tikTokCampaignDetails?.track?.title || tikTokCampaignDetails.trackTitle

  return (
    <>
      <CampaignSummaryTitle>
        {t('scheduleCampaignPage.campaignSummary')}
      </CampaignSummaryTitle>
      <div>
        <TopRow>
          <TrackInfo
            platformType={platformName}
            trackAuthor={trackAuthor}
            trackTitle={trackTitle}
          />
          <RowText>{campaignCurrencyBudget}</RowText>
        </TopRow>
        <Divider />
        <Row>
          <RowText>{t('scheduleCampaignPage.subtotal')}</RowText>
          <RowText>{campaignCurrencyBudget}</RowText>
        </Row>
        <Row>
          <RowText>{t('scheduleCampaignPage.balanceCheckout')}</RowText>
          <RowText>{currencyBalance}</RowText>
        </Row>

        {!isCreditsMethod && isVATCountry && (
          <Row>
            <RowText>{t('scheduleCampaignPage.vat')}</RowText>
            <RowText>{currencyVat}</RowText>
          </Row>
        )}
        {!isCreditsMethod && coupon && (
          <CampaignsTracksPromocode>
            <CampaignsTracksPromocodeDiscount>
              {coupon.discount}% {t('scheduleCampaignPage.discount')}
            </CampaignsTracksPromocodeDiscount>
            <CampaignsTracksPromocodeExist>
              {currencyDiscount}
            </CampaignsTracksPromocodeExist>
          </CampaignsTracksPromocode>
        )}
        <DividerWithBottomMargin />
        <BottomRow>
          <RowText bold>{t('scheduleCampaignPage.total')}</RowText>
          <RowText bold>{currencyTotal}</RowText>
        </BottomRow>
      </div>
    </>
  )
}
