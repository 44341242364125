import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { BadgeType, Badge } from 'src/Components/Badge'
import { PremoderationStatus } from 'src/Constants/enums'

interface StatusBadgeProps {
  preModerationStatus?: PremoderationStatus
}

export const StatusBadge: FC<StatusBadgeProps> = ({ preModerationStatus }) => {
  const { t } = useTranslation()

  const getVideoStatusBadge = (
    status: PremoderationStatus,
  ): { type: BadgeType; title: string } => {
    const statusDictionary: Record<string, { type: BadgeType; title: string }> =
      {
        newVideo: { type: 'regular', title: t('campaignResultsPage.newVideo') },
        monitoring: {
          type: 'disabled',
          title: t('campaignResultsPage.monitoring'),
        },
        confirmed: {
          type: 'regular',
          title: t('campaignResultsPage.confirmed'),
        },
        rejected: { type: 'danger', title: t('campaignResultsPage.rejected') },
      }

    return (
      statusDictionary[status] || { type: 'danger', title: 'Unknown status' }
    )
  }

  if (!preModerationStatus) {
    return null
  }

  const { type, title } = getVideoStatusBadge(preModerationStatus)

  return <Badge type={type} title={title} />
}
