import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { colors, device, variables } from 'src/Styled/variables'

import { StripeImages } from '../PaymentMethods/StripeImages'

interface StripeCardInfoProps {
  cardInfo?: string
}

const CardInfo = styled.div`
  color: ${colors.mainText};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 150%;
`
const StyledSpan = styled.span`
  font-size: ${variables.fontSize14};
  display: flex;
  align-items: center;
  color: ${colors.mainText};

  font-weight: ${variables.fontWeight400};
  white-space: break-spaces;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media ${device.mobileMin} {
    flex-direction: row;
  }
`

export const StripeCardInfo: FC<StripeCardInfoProps> = ({ cardInfo }) => {
  const { t } = useTranslation()
  if (cardInfo) {
    return <CardInfo>{cardInfo}</CardInfo>
  }
  return (
    <Container>
      <StyledSpan>{t('scheduleCampaignPage.creditAndDebitCards')}</StyledSpan>
      <StripeImages />
    </Container>
  )
}
