import { FC } from 'react'

import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { colors, variables } from 'src/Styled/variables'
import ArrowIcon from 'src/Assets/Svg/arrow.svg?react'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { SearchTrackByPlatformParams } from '../../Containers/SearchTrackByPlatform/index'

interface SubHeaderProps {
  onBackClick: () => void
}

export const ArrowBackButton = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const SubHeadForFirstCampaignSearch = styled.div`
  color: ${colors.mainText};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize20};
  display: flex;
  height: 40px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 16px;
`

export const FirstTrackSubHeader: FC<SubHeaderProps> = ({ onBackClick }) => {
  const { platform } = useParams<SearchTrackByPlatformParams>()
  const { t } = useTranslation()
  const title =
    platform === CampaignPlatformTypeEnum.SPOTIFY
      ? t('createCampaignPage.newSpotifyCampaign')
      : t('createCampaignPage.addingTikTokCampaign')
  return (
    <SubHeadForFirstCampaignSearch>
      <ArrowBackButton onClick={onBackClick}>
        <ArrowIcon />
      </ArrowBackButton>
      <div>{title}</div>
    </SubHeadForFirstCampaignSearch>
  )
}
