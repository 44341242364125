import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
export enum PaymentType {
  setupIntent = 'stripe_setup_intent',
}

interface SavedCard {
  id: number
  displayInfo: string
  type: PaymentType
}

const savedCardApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getSavedCards: builder.query<SavedCard[], void>({
      query: () => `${APP.TIKTOK_SERVER}/user-saved-cards`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetSavedCardsQuery } = savedCardApi

export const getStripeSetupIntent = (
  data?: SavedCard[],
): SavedCard | undefined =>
  data?.find((card) => card.type === PaymentType.setupIntent)

interface CardInfo {
  brand?: string
  content?: string
}

export const getCardInfo = (data?: SavedCard[]): string | undefined => {
  const card = getStripeSetupIntent(data)
  const cardInfo = JSON.parse(card?.displayInfo || '{}') as CardInfo
  if (!cardInfo.brand || !cardInfo.content) {
    return ''
  }
  return `${cardInfo.brand} **** **** **** ${cardInfo.content}`
}
