import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import dayjs from 'src/Helpers/dayjs'
import Button from 'src/Components/Buttons/Button'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { formatDate } from 'src/Constants/formatDate'
import { campaignsList, details } from 'src/Router/routes'
import { VideoStatus } from 'src/Constants/enums'
import avatar from 'src/Assets/Png/ava.png'
import { SmartImage } from 'src/Components/SmartImage'
import { CampaignPreModerationVideo } from 'src/Redux/campaignVideos-process/selectors'

import { VideoStatusBlock } from '../VideoStatusBlock'

import {
  VideoItemCardContainer,
  UserInfoWrapper,
  UserParamWrapper,
  ViewsParamWrapper,
  UserPicContainer,
  BigText,
  CreatorInfoWrapper,
  SmallText,
  StatusWrapper,
  ButtonWrapper,
} from './styles'

interface VideoItemProps {
  video: CampaignPreModerationVideo
}

const notShowedViewsStatuses = [
  VideoStatus.DECLINED,
  VideoStatus.REJECTED,
  VideoStatus.DISMISSED,
]

export const VideoItem: FC<VideoItemProps> = ({ video }) => {
  const navigate = useNavigate()
  const { campaignID } = useParams<keyof { campaignID: string }>()

  const { t } = useTranslation()

  const formattedFollowers = addSpaceToNumber(video.followerCount, ',')

  const formattedViews = notShowedViewsStatuses.includes(video.status)
    ? '-'
    : addSpaceToNumber(video.viewsCount || 0, ',')

  const getSubmissionsDate = (): string => {
    const dateToUse =
      video.preModerationDate || dayjs(video.videoUploadDate).add(24, 'hour')
    return dayjs(dateToUse).format(formatDate.formatDateMonthFull)
  }

  const isRateButtonVisible = video.status === VideoStatus.AWAITING_REVIEW
  const isDetailsButtonVisible = [
    VideoStatus.APPROVED_AUTOMATICALLY,
    VideoStatus.DECLINED,
    VideoStatus.DISMISSED,
    VideoStatus.APPROVED_BY_ARTIST,
    VideoStatus.APPROVED_BY_ADMIN,
    VideoStatus.AWAITING_MODERATOR_REVIEW,
  ].includes(video.status)

  const onDetailsOrRateClick = (): void => {
    if (campaignID && video.id) {
      navigate(`${campaignsList}${details}/${campaignID}/${video.id}`)
    }
  }

  return (
    <VideoItemCardContainer>
      <CreatorInfoWrapper>
        <UserPicContainer>
          <SmartImage
            alt='userpic'
            src={video.userPhotoUrl}
            fallbackSrc={avatar}
          />
        </UserPicContainer>

        <UserInfoWrapper>
          <BigText>{video.nickname}</BigText>
          <SmallText>
            {formattedFollowers} {t('campaignResultsPage.followers')}
          </SmallText>
        </UserInfoWrapper>
      </CreatorInfoWrapper>

      <UserParamWrapper>
        <SmallText>{t('campaignResultsPage.submissionDate')}</SmallText>

        <BigText>{getSubmissionsDate()}</BigText>
      </UserParamWrapper>

      <ViewsParamWrapper>
        <SmallText>{t('campaignResultsPage.views')}</SmallText>
        <BigText>{formattedViews}</BigText>
      </ViewsParamWrapper>

      <StatusWrapper>
        <VideoStatusBlock video={video} />
      </StatusWrapper>

      <ButtonWrapper>
        {isRateButtonVisible && (
          <Button type={'green'} onClick={onDetailsOrRateClick}>
            {t('campaignResultsPage.rate')}
          </Button>
        )}
        {isDetailsButtonVisible && (
          <Button type={'whiteWithGreenBorder'} onClick={onDetailsOrRateClick}>
            {t('campaignResultsPage.details')}
          </Button>
        )}
      </ButtonWrapper>
    </VideoItemCardContainer>
  )
}
