import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getVideoWithPremoderationStatus } from 'src/Redux/campaignVideo-process/video-process/selectors'

import { VideoStatusBlock } from '../../../TikTokCampaignDetails/components/VideoStatusBlock'

import { TiktokDetailsHeader, TiktokDetailsTitle } from './styles'

export const TiktokReviewHeader: FC = () => {
  const { t } = useTranslation()

  const video = useSelector(getVideoWithPremoderationStatus)

  return (
    <TiktokDetailsHeader>
      <TiktokDetailsTitle>
        {t('campaignResultsPage.details')}
      </TiktokDetailsTitle>

      <VideoStatusBlock video={video} />
    </TiktokDetailsHeader>
  )
}
