import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

import { SmartImage } from '../SmartImage'

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`

export const ScheduleCampaignContainer = styled.div`
  line-height: 150%;
  display: grid;
  padding: 16px 24px;
  gap: 24px;
  align-self: stretch;
  background-color: ${colors.white};
  border-radius: 4px;
  @media ${device.mobileMin} {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
`
export const ScheduleCampaignTrackContainer = styled.div`
  display: grid;
  grid-template-columns: 24px 40px 1fr;
  gap: 16px;
  align-items: center;
`
export const DateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 9.5px;
  align-items: center;
  @media ${device.mobileMin} {
    justify-self: end;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 9.5px;
  }
`
export const Author = styled.div`
  font-size: ${variables.fontSize10};
  line-height: 15px;
  color: ${colors.mainText};
`
export const Title = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.mainText};
`
export const TextInfo = styled.div`
  width: 100%;
  display: grid;
  gap: 2px;
`
export const StyledDate = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 21px;
`
export const ChangeButton = styled.button`
  display: grid;
  justify-self: end;
  width: fit-content;
  color: ${colors.mainText};
  border-bottom: 1px dashed ${colors.mainText};
  text-underline-offset: 6px;
  font-size: ${variables.fontSize14};
  background-color: ${colors.white};
  cursor: pointer;
  padding: 2px 0px 2px 0px;

  @media ${device.mobileMin} {
    padding: 3px 0px 0px 0px;
  }
`
export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`

export const Avatar = styled(SmartImage)`
  width: 40px;
  height: 40px;
  border-radius: 4px;
`
export const ScheduleDatePickerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 16px 0 24px 0;
  div:last-child {
    width: fit-content;
    .ant-btn.ant-btn-default {
      padding: 0 40px;
    }
  }
`
