import { FunctionComponent } from 'react'

import { CommonHint } from 'src/Components/CommonHint'

import { BlockProps } from './BlockProps'

import { Card, Title, Value, SubTitle, HintContainer } from './styles'

const Block: FunctionComponent<BlockProps> = ({
  title,
  value,
  subtitle,
  hintText,
}) => {
  const numberValue = Number(value)

  return (
    <Card>
      {/* TODO 7223 hint layout */}
      <HintContainer>
        {hintText && <CommonHint text={hintText} />}
      </HintContainer>

      <Title>{title}</Title>
      <Value>{numberValue <= 0 ? '-' : value}</Value>
      <SubTitle>{subtitle}</SubTitle>
    </Card>
  )
}

export default Block
