import i18n from 'src/Localization/i18n'

const VIDEO_POLICIES_URL =
  'https://help.soundcamps.com/en/articles/9757453-video-requirements'
const PROFILE_POLICIES_URL =
  'https://help.soundcamps.com/en/articles/9757440-profile-requirements'

export const infoBlocks = [
  {
    title: i18n.t('monetization.whatAreTheRequirements'),
    listItems: [
      i18n.t('monetization.follow'),
      i18n.t('monetization.ensureYourVideo'),
      i18n.t('monetization.attainAMinimum'),
      i18n.t('monetization.keepThePost'),
    ],
    link: {
      title: i18n.t('monetization.videoPolicies'),
      url: VIDEO_POLICIES_URL,
    },
  },
  {
    title: i18n.t('monetization.whatAreTheRequirementsForProfile'),
    listItems: [
      i18n.t('monetization.ensureYourTikTok'),
      i18n.t('monetization.allowComments'),
      i18n.t('monetization.stayActive'),
    ],
    link: {
      title: i18n.t('monetization.profilePolicies'),
      url: PROFILE_POLICIES_URL,
    },
  },
]
