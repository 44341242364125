import { useEffect, useMemo, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { sounds } from 'src/Router/routes'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useAppSelector } from 'src/Hooks/redux'
import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'
import { NewSound, useNewSoundByIdQuery } from 'src/Redux/creatorNewSoundsApi'
import {
  useGetMonetizationDataQuery,
  useLazyGetMonetizationDataQuery,
} from 'src/Redux/monetizationApi'

import { TabKeysEnum } from '../../../../types'

const ELIGIBLE_BY_MEDIAN_ERROR = 'not-eligible-by-median-views'
const errorModalLink = `${sounds}/${TabKeysEnum.NEW}?errorModalVisible=true`

interface UseNewSoundDetailsI {
  onClickAcceptSound: () => void
  monetizationErrors: { eligibleMedian: boolean }
  isDataOutdated: boolean
  newSoundData?: NewSound
}

export const useNewSoundDetails = (): UseNewSoundDetailsI => {
  const navigate = useNavigate()
  const { setSearch } = useCustomSearchParams()
  const { soundId } = useParams<{ soundId?: string }>()

  const [isDataOutdated, setIsDataOutdated] = useState(false)

  const creatorId = useAppSelector(getInfluencerId)

  const { data: newSoundData, error: getSoundError } = useNewSoundByIdQuery({
    creatorId,
    soundId: Number(soundId),
  })

  useEffect(() => {
    if (getSoundError) {
      navigate(errorModalLink)
    }
  }, [getSoundError, navigate])

  const { data: monetizationData } = useGetMonetizationDataQuery()

  const [getMonetizationData, { error: monetizationError }] =
    useLazyGetMonetizationDataQuery()

  const onClickAcceptSound = (): void => {
    void getMonetizationData().then(({ error, data }) => {
      const isDiffData =
        data?.maxViews !== monetizationData?.maxViews ||
        data?.minViews !== monetizationData?.minViews

      setIsDataOutdated(isDiffData)

      if (!error && !isDiffData) {
        setSearch({
          confirmRules: 'true',
        })
      }

      return true
    })
  }

  const monetizationErrors = useMemo(() => {
    if (monetizationError && 'data' in monetizationError) {
      const errorData = monetizationError.data as { message: string }
      if (errorData.message === ELIGIBLE_BY_MEDIAN_ERROR) {
        return {
          eligibleMedian: true,
        }
      }

      if (errorData.message === ELIGIBLE_BY_MEDIAN_ERROR) {
        return {
          eligibleMedian: true,
        }
      }
    }
    return {
      eligibleMedian: false,
    }
  }, [monetizationError])

  return {
    onClickAcceptSound,
    monetizationErrors,
    isDataOutdated,
    newSoundData,
  }
}
