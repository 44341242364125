import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'src/Hooks/redux'
import { getIsBlockedCreatorSelector } from 'src/Redux/auth-process/userSlice/selectors'

import { TrackCover } from '../TrackCover'
import { ViewsInfo } from '../ViewsInfo'
import { SoundListItemStatus } from '../SoundsListItemStatus'
import {
  MobileBlockWrapper,
  SoundsListItemContainer,
  StyledButton,
} from '../../styles'
import { RewardInfo } from '../RewardInfo'
import { TabKeysEnum } from '../../../../types'
import {
  monitoringStatuses,
  SoundStatus,
} from '../../../../hooks/useSoundStatus'

interface SubmittedSoundsListItemProps {
  id: number
  trackCoverUrl: string
  trackTitle: string
  trackAuthorName: string
  status: SoundStatus
  trackTiktokId: string
  currentViews: number
  reward: number
  isPaymentReceived: boolean
}

export const SubmittedSoundsListItem: FunctionComponent<
  SubmittedSoundsListItemProps
> = ({
  id,
  trackAuthorName,
  trackCoverUrl,
  trackTitle,
  currentViews,
  status,
  reward,
  isPaymentReceived,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isCreatorBlocked = useAppSelector(getIsBlockedCreatorSelector)

  const onDetailsClick = (): void => {
    navigate(`${TabKeysEnum.SUBMITTED}/${id}`)
  }

  const formattedReward =
    monitoringStatuses.includes(status) && !isPaymentReceived ? null : reward

  return (
    <SoundsListItemContainer>
      <TrackCover
        trackAuthorName={trackAuthorName}
        trackCoverUrl={trackCoverUrl}
        trackTitle={trackTitle}
      />

      <ViewsInfo currentViews={currentViews} />

      <RewardInfo reward={formattedReward} />

      <SoundListItemStatus
        status={status}
        presenceMilestoneDate={isPaymentReceived}
      />

      <MobileBlockWrapper>
        <StyledButton
          type={'whiteWithGreenBorder'}
          onClick={onDetailsClick}
          disabled={isCreatorBlocked}
        >
          {t('soundsPage.details')}
        </StyledButton>
      </MobileBlockWrapper>
    </SoundsListItemContainer>
  )
}
