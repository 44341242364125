import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'
import { VideoStatus } from 'src/Constants/enums'

import { mapVideoToPreModerationStatus } from '../../campaignVideos-process/selectors'
import { EventDismissReasonsEnum } from '../../creatorSubmittedSoundsApi'

import { IVideoProcess } from '.'

const getState = (state: RootState): IVideoProcess => state.campaignVideo.video

export const getIsVideoLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)

export const getVideo = createDraftSafeSelector(getState, ({ video }) => video)

export const getVideoWithPremoderationStatus = createDraftSafeSelector(
  getState,
  ({ video }) => mapVideoToPreModerationStatus(video),
)

export const getIsVideoDeclined = createDraftSafeSelector(
  getState,
  ({ video }) =>
    video?.status === VideoStatus.REJECTED ||
    video?.status === VideoStatus.DECLINED,
)

export const getPricingModelVersion = createDraftSafeSelector(
  getState,
  ({ video }) => video?.pricingModelVersion,
)
export const getIsVideoDeletedFromTikTok = createDraftSafeSelector(
  getState,
  ({ video }) =>
    video?.status === VideoStatus.DISMISSED &&
    video?.dismissReason === EventDismissReasonsEnum.VIDEO_DELETED,
)
