import { FunctionComponent, useState } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import { calcTimeLeft } from 'src/Containers/Tracks/helpers'
import { creatorAcceptedTrack } from 'src/Helpers/TagManager'
import {
  acceptTrack,
  getEventsStatistics,
  getNewTikTokInfluencerEvents,
} from 'src/Redux/influencer-process/api-actions'
import { setID, setOfferedCampaigns } from 'src/Redux/influencer-process'
import { getOpenedCampaign } from 'src/Redux/influencer-process/selectors'
import { useAppDispatch } from 'src/Hooks/redux'
import { ApiError } from 'src/Redux/types'
import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'

import {
  ButtonsGroups,
  ButtonWrapper,
  CancelButtonWrapper,
  Container,
} from '../styles'
import AcceptPolicy from '../AcceptPolicy'

import { AcceptTrackError } from './AcceptTrackError'
import { AcceptTrackProps } from './types'

import { TimeLeftInfo } from './styles'

export const AcceptTrack: FunctionComponent<AcceptTrackProps> = ({
  onClose,
  onAcceptTrack,
  isRulesPage,
  isSuccessAcceptedPage,
  setIsSuccessAcceptedPage,
}) => {
  const { t } = useTranslation()
  const [policyAccepted, setPolicyAccepted] = useState(false)
  const campaign = useSelector(getOpenedCampaign)
  const userId = useSelector(getInfluencerId)
  const dispatch = useAppDispatch()

  const toggleAcceptPolicy = (): void => setPolicyAccepted(!policyAccepted)

  const timeLeft =
    campaign?.deadlineToAccept && calcTimeLeft(campaign?.deadlineToAccept)

  const handleClickAccept = (): void => {
    if (isRulesPage && campaign?.id) {
      void dispatch(acceptTrack(campaign.id))
        .unwrap()
        .then((res) => {
          campaign &&
            creatorAcceptedTrack({
              userID: userId,
              reviewId: res.eventId,
              trackId: campaign.trackTiktokId,
              campaignId: campaign.id,
              amountPaid: res.amountPaid,
            })

          dispatch(setID(res.eventId))
          void dispatch(getNewTikTokInfluencerEvents({ pageNumber: 1 }))
          void dispatch(getEventsStatistics())
          setIsSuccessAcceptedPage(true)
          return res
        })
        .catch((error) => {
          setIsSuccessAcceptedPage(false)
          const { message } = error as ApiError
          campaign?.id &&
            dispatch(
              setOfferedCampaigns({
                id: campaign.id,
                message: message || '',
              }),
            )
        })
    }
    onAcceptTrack()
  }

  const handleClose = (): void => {
    if (!isSuccessAcceptedPage && !isRulesPage) {
      void dispatch(getNewTikTokInfluencerEvents({ pageNumber: 1 }))
      void dispatch(getEventsStatistics())
    }
    onClose()
  }

  const isAcceptButtonDisabled = !policyAccepted && isRulesPage

  return (
    <Container>
      <AcceptPolicy
        isRulesPage={isRulesPage}
        isSuccessAcceptedPage={isSuccessAcceptedPage}
        policyAccepted={policyAccepted}
        toggleAcceptPolicy={toggleAcceptPolicy}
      />

      {/* eslint-disable-next-line no-undefined */}
      {campaign === undefined ||
        (campaign?.isNoLongerAvailableTrack && (
          <AcceptTrackError message={campaign?.isNoLongerAvailableTrack} />
        ))}

      <ButtonsGroups isRulesPage={isRulesPage}>
        <ButtonWrapper>
          {!isRulesPage && (
            <Button type='white' onClick={handleClose}>
              {t('influencer.closeBtn')}
            </Button>
          )}
        </ButtonWrapper>
        {/* eslint-disable-next-line no-undefined */}
        {campaign !== undefined &&
        !campaign.isNoLongerAvailableTrack &&
        !isSuccessAcceptedPage ? (
          <ButtonWrapper>
            <Button
              type='green'
              disabled={isAcceptButtonDisabled}
              onClick={handleClickAccept}
            >
              {t('influencer.acceptTrack')}
            </Button>
            <TimeLeftInfo>{timeLeft}</TimeLeftInfo>
          </ButtonWrapper>
        ) : null}
        <CancelButtonWrapper>
          {isRulesPage ? (
            <Button type='white' onClick={handleClose}>
              {t('influencer.backBtn')}
            </Button>
          ) : null}
        </CancelButtonWrapper>
      </ButtonsGroups>
    </Container>
  )
}
