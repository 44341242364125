import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { getReferralCode } from 'src/Redux/referral-process/referralCode'
import {
  getHasPaidCampaignMetadata,
  selectPaidCampaignsAmount,
  useMetadataQuery,
} from 'src/Redux/metadataApi'
import {
  getCheckoutTimer,
  startCountdown,
  updateCountdown,
} from 'src/Redux/checkoutCountdown-process'
import { addFeatureForUser } from 'src/Redux/A-BFeatures-process/api-actions'
import { getIsMemoFeatureValue } from 'src/Redux/A-BFeatures-process/selectors'

import { useAppDispatch, useAppSelector } from './redux'
import {
  EXPIRED_TIMER_FEATURE_VALUE,
  QUICK_CHECKOUT_FEATURE_NAME,
} from './useCheckoutTimerFeratureFlag'

const TIMEOUT = 1000
const COUNTDOWN_VALUE = 420

interface UseBonusTooltipTimerInterface {
  isNextTimeBonus: boolean
  isNextTimeBonusReceived: boolean
  startNextTimeBonusCountdown: () => void
}

export const useBonusTooltipTimer = (): UseBonusTooltipTimerInterface => {
  const dispatch = useAppDispatch()

  // eslint-disable-next-line no-undefined
  const { data } = useMetadataQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const isFirstPaid = getHasPaidCampaignMetadata(data)
  const paidCampaignsAmount = useSelector(selectPaidCampaignsAmount)

  const referralCode = useSelector(getReferralCode)
  const countdown = useSelector(getCheckoutTimer)
  const memoFeatureValue = useAppSelector(
    getIsMemoFeatureValue(QUICK_CHECKOUT_FEATURE_NAME),
  )

  const isNextTimeBonus = !referralCode && !isFirstPaid
  const isNextTimeBonusReceived =
    memoFeatureValue === 1 && paidCampaignsAmount === 1

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown === 0) {
        void dispatch(
          addFeatureForUser({
            featureName: QUICK_CHECKOUT_FEATURE_NAME,
            variation: EXPIRED_TIMER_FEATURE_VALUE,
          }),
        )
        clearInterval(timer)
        dispatch(updateCountdown(null))
      } else {
        countdown && dispatch(updateCountdown(countdown - 1))
      }
    }, TIMEOUT)

    return () => clearInterval(timer)
  }, [countdown, dispatch])

  const startNextTimeBonusCountdown = (): void => {
    isNextTimeBonus && dispatch(startCountdown(COUNTDOWN_VALUE))
  }

  return {
    isNextTimeBonus,
    isNextTimeBonusReceived,
    startNextTimeBonusCountdown,
  }
}
