import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const ProgressContainer = styled.div`
  width: 192px;
  @media (max-width: 576px) {
    margin-bottom: 16px;
  }
`

export const ProgressInfo = styled.div`
  margin-bottom: 8px;
  width: 176px;
`

export const ProgressInfoItem = styled.span`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
`

export const ProgressBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 125px;
`

const BORDER_WIDTH = 2

export const ProgressSection = styled.div<{
  first?: boolean
  last?: boolean
  passed?: boolean
  yellow?: boolean
}>`
  width: 8px;
  height: 4px;
  border-top-left-radius: ${({ first }) => (first ? BORDER_WIDTH : 0)}px;
  border-bottom-left-radius: ${({ first }) => (first ? BORDER_WIDTH : 0)}px;
  border-top-right-radius: ${({ last }) => (last ? BORDER_WIDTH : 0)}px;
  border-bottom-right-radius: ${({ last }) => (last ? BORDER_WIDTH : 0)}px;
  background-color: ${({ passed, yellow }) => {
    if (passed) {
      return colors.greyStroke
    }
    if (yellow) {
      return colors.messegesYellow1
    }
    return colors.green
  }};
`

export const EndedStatusLabel = styled.span`
  display: block;
  color: ${colors.messegesRed};
  text-transform: capitalize;
`

export const EndedStatusReviews = styled.span`
  color: ${colors.greyTextWhiteBg};
`

export const EndedStatusReviewsDiff = styled.span<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'inline-block' : 'none')};
  color: ${colors.messegesRed};
`
