import { FunctionComponent, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Alert from 'src/Components/Alert'
import { referralLinkNotExist } from 'src/Router/routes'
import { useCheckReferralCodeQuery } from 'src/Redux/signupReferalCodeApi'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import {
  AlertText,
  AlertAmount,
  AlertTitle,
  WelcomeAlertWrapper,
} from './styles'

export const WelcomeAlert: FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { search } = useCustomSearchParams()

  const referralCode = search?.get('referralCode')
  const { data, isError } = useCheckReferralCodeQuery(
    { referralCode },
    { skip: !referralCode },
  )

  useEffect(() => {
    if (referralCode && isError) {
      navigate(`${referralLinkNotExist}`)
    }
  }, [isError, navigate, referralCode])

  if (!referralCode && !data?.firstName) {
    return null
  }

  return (
    <WelcomeAlertWrapper>
      <Alert color='success' icon unclosable noGap>
        <AlertText>
          <AlertTitle>{t('referAndEarn.welcomeToSoundCampaign')}</AlertTitle>
          <div>
            {data?.firstName} &nbsp;
            {t('referAndEarn.hasInvitedYouToCreateAFirstCampaignForYourSong')}
            &nbsp;<AlertAmount>$30</AlertAmount>&nbsp;
            {t('referAndEarn.hasAlreadyBeenCreditedToYourBalance')}
          </div>
        </AlertText>
      </Alert>
    </WelcomeAlertWrapper>
  )
}
