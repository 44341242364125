import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'

import { DetailsButtonProps } from './DetailsButtonProps'

export const DetailsButton: FC<DetailsButtonProps> = ({
  disabled,
  onViewResults,
}) => {
  const { t } = useTranslation()

  return (
    <Button
      disabled={disabled}
      type='whiteWithGreenBorder'
      onClick={onViewResults}
    >
      {t('campaignsPage.details')}
    </Button>
  )
}
