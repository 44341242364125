export enum CampaignStatus {
  PENDING_APPROVAL = 'pending_approval',
  AWAITING_REVIEW = 'awaiting_review',

  APPROVED = 'approved',
  PENDING = 'pending',

  SCHEDULED = 'scheduled',

  RUNNING = 'running',
  INPROCESS = 'in_process',

  PAYMENT_PENDING = 'payment_pending',

  ENDED = 'ended',
  FINISHED = 'finished',

  CANCELED = 'canceled',
  STOPPED = 'stopped',
}

export enum Roles {
  artist = 'artist',
  curator = 'curator',
  influencer = 'influencer',
  admin = 'admin',
  agent = 'agent',
  label = 'label',
  manager = 'manager',
  publisher = 'publisher',
}

export enum ArtistRolesEnum {
  ARTIST = 'artist',
  AGENT = 'agent',
  LABEL = 'label',
  MANAGER = 'manager',
  PUBLISHER = 'publisher',
  ADMIN = 'admin',
}

export enum VideoStatus {
  ACCEPTED = 'accepted',
  CANCELED_BY_ARTIST = 'canceled_by_artist',
  APPROVED_BY_ARTIST = 'approved_by_artist',
  APPROVED_AUTOMATICALLY = 'approved_automatically',
  APPROVED_BY_ADMIN = 'approved_by_admin',
  CANCELED = 'canceled',
  AWAITING_REVIEW = 'awaiting_review',
  DECLINED = 'declined',
  AWAITING_MODERATOR_REVIEW = 'awaiting_moderator_review',
  REJECTED = 'video_rejected',
  DISMISSED = 'video_dismissed',
}

export enum PremoderationStatus {
  NEW_VIDEO = 'newVideo',
  MONITORING = 'monitoring',
  CONFIRMED = 'confirmed',
  REJECTED = 'rejected',
}

export enum PaymentMethodEnum {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  Credits = 'credits',
  PAYPAL_BILLING_AGREEMENT = 'paypalBillingAgreement',
}

export enum PaymentStatusEnum {
  PAYED = 'payed',
  COMPLETED = 'completed',
  CHARGED_BACK = 'charged-back',
}

export enum TagManagerEnum {
  WIDER_LANGUAGES_MATCHING = 'Match selected and other languages (wider selection)',
  SELECTED_LANGUAGES_ONLY = 'Match selected language only',
}
