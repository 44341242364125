import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const VideoItemCardContainer = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: ${colors.greyBg};

  @media ${device.smallScreenMax} {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
`

export const CreatorInfoWrapper = styled.div`
  display: flex;
  max-width: 240px;
  padding-right: 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`

export const UserPicContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  overflow: hidden;
  flex-shrink: 0;
  img {
    width: 40px;
    height: 40px;
  }
`

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`
export const BigText = styled.div`
  line-height: 150%;
  white-space: nowrap;
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
`

export const SmallText = styled.div`
  line-height: 150%;
  white-space: nowrap;
  color: ${colors.mainText};
  font-size: ${variables.fontSize10};
`

export const UserParamWrapper = styled.div`
  display: flex;
  width: 120px;
  padding-right: 16px;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.smallScreenMax} {
    padding-left: 48px;
  }
`

export const ViewsParamWrapper = styled.div`
  display: flex;
  width: 120px;
  padding-right: 16px;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.smallScreenMax} {
    padding-left: 48px;
  }
`

export const ButtonWrapper = styled.div`
  width: 108px;

  @media ${device.smallScreenMax} {
    padding-left: 48px;
    width: 240px;
  }
`

export const StatusWrapper = styled.div`
  @media ${device.smallScreenMax} {
    padding-left: 48px;
  }
`
