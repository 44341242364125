import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CommonHint } from 'src/Components/CommonHint'
import { useAppSelector } from 'src/Hooks/redux'
import { getEstimatedTiktokViews } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { colors } from 'src/Styled/variables'

import { PotentialViews } from '../../../../ui/PotentialViews'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`
const Title = styled.div`
  color: ${colors.mainText};
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
`

const TiktokEstimationContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
`
const SubTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`
const Block = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${colors.greyStroke};
  background: ${colors.white};
`

export const TiktokEstimation: FC = () => {
  const { t } = useTranslation()
  const { maxViews, minViews } = useAppSelector(getEstimatedTiktokViews)

  return (
    <Wrapper>
      <Title>{t('scheduleCampaignPage.estimatedReach')}</Title>
      <TiktokEstimationContainer>
        <Block>
          <SubTitle>
            <div>{t('scheduleCampaignPage.numberOfPotentialViews')}</div>
            <CommonHint
              text={t('scheduleCampaignPage.tiktokScheduleRangeHint')}
            />
          </SubTitle>

          <PotentialViews minViews={minViews} maxViews={maxViews} />
        </Block>
      </TiktokEstimationContainer>
    </Wrapper>
  )
}
