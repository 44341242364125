import styled, { createGlobalStyle } from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

import Button from '../Buttons/Button'

interface Props {
  couponErrorMessage?: boolean
}

export const PromocodeForm = styled.form`
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
`

export const PromocodeInput = styled.input<Props>`
  height: 47px !important;
  font-size: ${variables.fontSize14};
  border: ${(props: Props) =>
    props.couponErrorMessage
      ? `1px solid ${colors.messegesRed}`
      : `1px solid ${colors.greyStroke}`};
  border-radius: 8px;
  outline: none;
  padding: 16px 16px 16px 14px;
  width: 100%;
  margin-bottom: ${(props: Props) =>
    props.couponErrorMessage ? '0px' : '24px'};
  background-color: ${colors.white};
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};

  &:focus {
    background-color: ${colors.white};
    outline: none;
    color: ${colors.mainText};
  }
  &:active {
    background-color: ${colors.white};
    outline: none;
    color: ${colors.mainText};
  }
  &::placeholder {
    color: ${colors.greyTextWhiteBg} !important;
  }
`
export const PromocodeButtonsBlock = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
`

export const PromocodeErrorText = styled.div`
  text-align: left;
  color: ${colors.messegesRed};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  margin-top: 0px;
  margin-bottom: 24px;
`

export const GlobalStyled = createGlobalStyle`
.ant-modal{
  transform-origin: unset;
}
 .ant-popover-arrow{
  display: none !important;
 }
 .ant-popover-inner-content{
    padding: 24px;
  }
  .ant-popover-inner {
    width: 240px
  }
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.5) ;
  }
  .ant-modal {
    top: 25%;
  }
  
`

export const StyledGreenButton = styled(Button)`
  width: 116px;
`
