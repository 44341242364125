import { useEffect } from 'react'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'

export const useSoundsPageAnalytics = (): void => {
  const userID = useAppSelector(getUserId)

  useEffect(() => {
    if (userID) {
      locationEvent()
      pageViewEvent({ userID, currentPageInfo: pageInfo.creator })
    }
  }, [userID])
}
