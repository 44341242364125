import { FC, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'

import { Roles } from 'src/Constants/enums'
import Button from 'src/Components/Buttons/Button'
import { FullWideNotification } from 'src/Components/FullWideNotification'
import { getCookieByName, getUtmParameters } from 'src/Constants/functions'
import {
  curatorRegistrationCompleteEvent,
  locationEvent,
  pageViewEvent,
} from 'src/Helpers/TagManager'
import { setRoleToUser } from 'src/Redux/auth-process/credentialsSlice/api-actions'
import { ApiError } from 'src/Redux/types'
import { useAppDispatch } from 'src/Hooks/redux'
import { colors } from 'src/Styled/variables'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { tracksFeed } from 'src/Router/routes'

import { StyledRoles, StyledModal, Title } from './styles'

export const blacklistMessages = [
  'Curator in blacklist',
  'Country in blacklist',
]

const SLICE_BY_SIX = 6
export const ChooseRoleModal: FC = () => {
  const { search } = useCustomSearchParams()
  const userData = search.get('user')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const { t } = useTranslation()

  const [modal, setModal] = useState(false)

  useEffect(() => {
    locationEvent()
    pageViewEvent({ currentPageInfo: pageInfo.Login })
    if (userData && userData.includes('access_token')) {
      setModal(true)
    }
  }, [dispatch, userData])

  const redirectCreateCampaign = (): void => {
    navigate('/create')
  }

  const handleSetRoleToUser = (): void => {
    const fullGcid = getCookieByName('_ga')
    const gcid = fullGcid ? fullGcid.slice(SLICE_BY_SIX) : ''
    const utm = getCookieByName('soundCampaignUtm')
    const utmObj = getUtmParameters(utm)
    const user = JSON.parse(userData || '') as Record<string, string>
    user &&
      void dispatch(
        setRoleToUser({
          gcid,
          utmObj,
          spotify_id: user?.spotify_id || '',
          userType: Roles.curator,
          access_token: user.access_token,
          email: user.email,
          is_email_confirmed: Boolean(user.is_email_confirmed),
          refresh_token: user.refresh_token,
          user_photo_url: user.user_photo_url,
          first_name: user.first_name,
          last_name: user.last_name,
        }),
      )
        .unwrap()
        .then((res) => {
          curatorRegistrationCompleteEvent({ userId: Number(res.user.id) })
          navigate(tracksFeed)
          return res
        })
        .catch((err: ApiError) => {
          const { message: errorMessage } = err
          if (blacklistMessages.includes(errorMessage)) {
            setModal(false)
            navigate('/')
            void messageApi.open({
              content: (
                <FullWideNotification
                  message={t('login.authErrorBlacklistCountry')}
                />
              ),
              duration: 3,
              style: { backgroundColor: colors.badgeRed },
            })
          }
        })
  }

  return (
    <>
      {contextHolder}
      <StyledModal footer={null} open={modal}>
        <Title>{t('login.roleSelection')}</Title>
        <StyledRoles>
          <Button
            type='green'
            htmlType='button'
            onClick={redirectCreateCampaign}
          >
            {t('login.iAmArtist')}
          </Button>
          <Button type='green' htmlType='button' onClick={handleSetRoleToUser}>
            {t('login.iAmCurator')}
          </Button>
        </StyledRoles>
      </StyledModal>
    </>
  )
}
