import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { Mobile } from '../Responsive'
import { ArtistProtection } from '../ArtistProtection'
import { BonusTooltip } from '../BonusTooltip'

import { ProtectionAlertMobileProps } from './ProtectionAlertMobileProps'

export const ProtectionAlertMobile: FunctionComponent<
  ProtectionAlertMobileProps
> = ({ platform }) => {
  const { t } = useTranslation()

  const { isNextTimeBonus } = useBonusTooltipTimer()

  const protectionText =
    platform === CampaignPlatformTypeEnum.SPOTIFY
      ? t('scheduleCampaignPage.artistProtectionSpotify')
      : t('scheduleCampaignPage.artistProtectionTikTok')

  return (
    <Mobile>
      <ArtistProtection>{protectionText}</ArtistProtection>

      {isNextTimeBonus && (
        <BonusTooltip
          description={t('scheduleCampaignPage.offerDescriptionBonus20Next')}
          position={'bottom-center'}
        />
      )}
    </Mobile>
  )
}
