import { FC, SVGProps, useEffect, useState } from 'react'

import { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ScheduleDatePicker } from 'src/Containers/ui/ScheduleDatePicker'
import dayJs from 'src/Helpers/dayjs'
import track from 'src/Assets/Png/track.png'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getHasActiveOrCanceledSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { mapScheduledSpotifyCampaignForUpdate } from 'src/Containers/ScheduleTiktokWrapper/helpers/mapScheduledSpotifyCampaignForUpdate'
import { updateTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/api-actions'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { setStartDate } from 'src/Redux/campaign-process/mainOldProcessSlice'

import Button from '../Buttons/Button'

import {
  Author,
  Avatar,
  ChangeButton,
  ScheduleCampaignContainer,
  DateContainer,
  IconWrapper,
  ScheduleDatePickerButtons,
  StyledDate,
  TextInfo,
  Title,
  ScheduleCampaignTrackContainer,
  Backdrop,
} from './style'
import { checkDisabledDate, getDefaultStartDate, getStartDate } from './helpers'

interface ScheduleCampaignHeaderProps {
  Icon: FC<SVGProps<SVGSVGElement>>
  avatar: string
  title: string
  author: string
}

export const ScheduleCampaignHeader: FC<ScheduleCampaignHeaderProps> = (
  props,
) => {
  const { pathname } = useLocation()

  const isTikTokPlatform = pathname.split('/').includes('scheduleTikTok')

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const hasActiveOrCanceledSubscription = useAppSelector(
    getHasActiveOrCanceledSubscriptionSelector,
  )
  const spotifyCampaign = useAppSelector(getCampaignToSchedule)
  const tikTokCampaign = useAppSelector(getScheduledTikTokCampaign)

  const { Icon, avatar, title, author } = props

  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<Dayjs>(
    isTikTokPlatform
      ? dayJs(tikTokCampaign.startDate)
      : dayJs(spotifyCampaign.start_date),
  )

  const onSetStartDate = (newDate: string): void => {
    if (isTikTokPlatform) {
      const mappedCampaign =
        mapScheduledSpotifyCampaignForUpdate(tikTokCampaign)

      void dispatch(
        updateTikTokCampaign({
          ...mappedCampaign,
          startDate: newDate,
        }),
      )
    } else {
      dispatch(setStartDate(newDate))
    }
  }

  useEffect(() => {
    const startDate = isTikTokPlatform
      ? tikTokCampaign.startDate
      : spotifyCampaign.start_date
    setValue(
      dayJs(startDate || getDefaultStartDate(hasActiveOrCanceledSubscription)),
    )
  }, [
    hasActiveOrCanceledSubscription,
    isTikTokPlatform,
    spotifyCampaign.start_date,
    tikTokCampaign.startDate,
  ])

  const handleClick = (): void => {
    setOpen(false)
    const now = dayJs()

    const utcTime = now
      .set('date', value.date())
      .set('month', value.month())
      .utc()
      .toISOString()
    onSetStartDate(utcTime)
  }

  const displayedStartDate = isTikTokPlatform
    ? getStartDate(tikTokCampaign.startDate, hasActiveOrCanceledSubscription)
    : getStartDate(spotifyCampaign.start_date, hasActiveOrCanceledSubscription)

  return (
    <ScheduleCampaignContainer>
      {open && <Backdrop />}
      <ScheduleCampaignTrackContainer>
        <IconWrapper>
          <Icon data-testid='platform icon' />
        </IconWrapper>
        <Avatar src={avatar} fallbackSrc={track} alt='track picture' />
        <TextInfo>
          <Title>{title}</Title>
          <Author>{author}</Author>
        </TextInfo>
      </ScheduleCampaignTrackContainer>
      <DateContainer>
        <StyledDate>
          {`${t('scheduleCampaignPage.startDate')} ${displayedStartDate}`}
          <ScheduleDatePicker
            popupClassName='popupClassName'
            onChange={(currentDate) => setValue(dayJs(currentDate))}
            open={open}
            style={{ visibility: 'hidden', width: 0, height: 0, padding: 0 }}
            disabledDate={(current) =>
              checkDisabledDate(current, hasActiveOrCanceledSubscription)
            }
            showNow={false}
            hideDisabledOptions
            value={value}
            renderExtraFooter={() => (
              <ScheduleDatePickerButtons>
                <ChangeButton onClick={() => setOpen(false)}>
                  {t('scheduleCampaignPage.cancel')}
                </ChangeButton>
                <Button onClick={handleClick} type='green'>
                  {t('targetGenres.save')}
                </Button>
              </ScheduleDatePickerButtons>
            )}
          />
        </StyledDate>
        <ChangeButton onClick={() => setOpen(true)}>
          {t('scheduleCampaignPage.change')}
        </ChangeButton>
      </DateContainer>
    </ScheduleCampaignContainer>
  )
}
