import React, { FC } from 'react'

import { useFeatureValue } from '@growthbook/growthbook-react'
import styled from 'styled-components'

interface TrackDetailsExperimentProps {
  children: [React.ReactNode, React.ReactNode]
}
const Container = styled.div`
  width: 100%;
  @media (min-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const TrackDetailsExperiment: FC<TrackDetailsExperimentProps> = ({
  children,
}) => {
  const isVariantB = useFeatureValue('track_details_v2', false)

  if (isVariantB) {
    return <Container>{children[1]}</Container>
  }
  return <Container>{children[0]}</Container>
}
