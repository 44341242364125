import React, { ReactElement } from 'react'

import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'

import {
  BarValue,
  BarWrapper,
  ChartContainer,
  ChartValue,
  Description,
  IconWrapper,
} from './styles'

interface ChartProps {
  minValue: number
  maxValue: number
  description: string
  color: string
  icon?: ReactElement | null
}

export const Chart: React.FC<ChartProps> = ({
  minValue,
  maxValue,
  description,
  color,
  icon,
}) => {
  const chartValue = `${addSpaceToNumber(minValue, ',')} / ${addSpaceToNumber(
    maxValue,
    ',',
  )}`
  const percentageWidth = (minValue / maxValue) * 100

  return (
    <ChartContainer>
      <ChartValue>{chartValue}</ChartValue>
      <BarWrapper>
        <BarValue percentageWidth={percentageWidth} color={color} />
      </BarWrapper>
      <Description>{description}</Description>

      <IconWrapper>{icon}</IconWrapper>
    </ChartContainer>
  )
}
