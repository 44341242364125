import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import {
  getCardInfo,
  getStripeSetupIntent,
  useGetSavedCardsQuery,
} from 'src/Redux/savedCardsApi'
import { useCreateSetupIntentMutation } from 'src/Redux/setupIntentApi'
import { subscription } from 'src/Router/routes'
import { getHasActiveSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { PaymentMethodEnum } from 'src/Constants/enums'

import { useCreateSubscription } from './useCreateSubscription'
import { useChangeSubscription } from './useChangeSubscription'

interface UseStripeIntent {
  onPayStripe: () => Promise<void>
  hasIntent: boolean
  cardInfo?: string
}

export const usePayStripeSubscription = (): UseStripeIntent => {
  const { data } = useGetSavedCardsQuery()
  const navigate = useNavigate()
  const setupIntent = getStripeSetupIntent(data)
  const cardInfo = getCardInfo(data)
  const hasActiveSubscriptionSelector = useAppSelector(
    getHasActiveSubscriptionSelector,
  )
  const [create] = useCreateSubscription()
  const [change] = useChangeSubscription()
  const [createSetupIntent] = useCreateSetupIntentMutation()
  const { search } = useCustomSearchParams()
  const packageId = Number(search.get('packageId'))
  const price = Number(search.get('price'))
  const cashback = Number(search.get('cashback'))
  const plan = String(search.get('plan'))
  const autopay = search.get('autopay')

  const onPayStripe = async (): Promise<void> => {
    if (!setupIntent) {
      await createSetupIntent({
        successUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&plan=${plan}&cashback=${cashback}&paymentType=${PaymentMethodEnum.STRIPE}&autopay=true`,
        cancelUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&plan=${plan}&cashback=${cashback}&paymentType=${PaymentMethodEnum.STRIPE}&error=save_token_error`,
      })
        .unwrap()
        .then((res) => {
          window.location.href = res.link
          return res
        })
        .catch(() => {
          navigate(
            `${subscription}/pay?packageId=${packageId}&plan=${plan}&price=${price}&paymentType=${PaymentMethodEnum.STRIPE}&error=subscription_error`,
          )
        })
      return
    }
    if (hasActiveSubscriptionSelector) {
      change(PaymentMethodEnum.STRIPE, setupIntent?.id)
    } else {
      create(PaymentMethodEnum.STRIPE, setupIntent?.id)
    }
  }

  useEffect(() => {
    if (autopay) {
      create(PaymentMethodEnum.STRIPE, setupIntent?.id)
    }
  }, [autopay, create, setupIntent?.id])

  return { cardInfo, onPayStripe, hasIntent: Boolean(setupIntent) }
}
