import { useState, FunctionComponent, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import html2canvas from 'html2canvas'

import { Mobile, Desktop } from 'src/Components/Responsive'
import IconArrow from 'src/Assets/Svg/arrow.svg?react'
import { RadioAntd } from 'src/Containers/ui'
import { SHARING_IMAGE_LENGTH_LIMIT } from 'src/Constants/numeric'
import {
  artistViewSharePopupEvent,
  artistClickDownloadShareImageEvent,
} from 'src/Helpers/TagManager'
import Button from 'src/Components/Buttons/Button'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'

import { SharingImageDisplayModeEnum } from '../../Types'

import { ImageToShare } from './components/imageToShare'
import { ModalProps } from './props'
import { downloadReview } from './helpers'

import {
  ModalContainer,
  BackButton,
  ModalContent,
  Text,
  Title,
  Hashtag,
  ButtonContainer,
  HiddenImageContainer,
  BackIcon,
  BackLabel,
  RadioContainer,
} from './styles'

const imageForDownload = 1080
const imageWidthDesktop = 400
const imageWidthMobile = 240

const radioOptions = [
  {
    label: 'Shortened version of review',
    value: SharingImageDisplayModeEnum.square,
  },
  {
    label: 'Full version of review',
    value: SharingImageDisplayModeEnum.full,
  },
]

export const ShareModal: FunctionComponent<ModalProps> = ({
  isModalVisible,
  onClose,
  review,
}) => {
  const { t } = useTranslation()
  const user = useSelector(getUserSelector)
  const { campaignDetails } = useSpotifyCampaignDetails()

  const [displayMode, setDisplayMode] = useState(
    SharingImageDisplayModeEnum.square,
  )

  useEffect(() => {
    campaignDetails &&
      artistViewSharePopupEvent({
        userId: user.id,
        campaignId: campaignDetails.id,
      })
  }, [campaignDetails, user.id])

  const getImageFromLayout = async (): Promise<void> => {
    const element = document.querySelector<HTMLElement>('#downloadReviewImage')
    if (element) {
      await html2canvas(element, {
        allowTaint: true,
        useCORS: true,
        scale: 1,
      }).then((canvas: HTMLCanvasElement) => {
        downloadReview(
          canvas,
          displayMode !== SharingImageDisplayModeEnum.square,
        )
        campaignDetails &&
          artistClickDownloadShareImageEvent({
            userId: user.id,
            campaignId: campaignDetails.id,
          })
        return canvas
      })
    }
  }

  const changeDisplayMode = (value: SharingImageDisplayModeEnum): void => {
    setDisplayMode(value)
  }

  const shouldRadioAppear = review.comment?.length > SHARING_IMAGE_LENGTH_LIMIT

  return (
    <>
      <Desktop>
        <ModalContainer open={isModalVisible} onCancel={onClose}>
          <ModalContent>
            <Title>{t('shareImage.preview')}</Title>
            <ImageToShare
              imageWidth={imageWidthDesktop}
              review={review}
              displayMode={displayMode}
            />
            <HiddenImageContainer>
              <ImageToShare
                imageWidth={imageForDownload}
                review={review}
                displayMode={displayMode}
                isForDownload
              />
            </HiddenImageContainer>
            {shouldRadioAppear && (
              <RadioContainer>
                <RadioAntd
                  options={radioOptions}
                  direction='horizontal'
                  defaultValue={SharingImageDisplayModeEnum.square}
                  gapSize='sm'
                  onChange={(e) =>
                    changeDisplayMode(
                      e.target.value as SharingImageDisplayModeEnum,
                    )
                  }
                />
              </RadioContainer>
            )}
            <Text>
              {t('shareImage.hashtagsLabel')}
              <Hashtag>{t('shareImage.soundcampHashtag')}</Hashtag>
              <Hashtag>{t('shareImage.promotionHashtag')}</Hashtag>
              <Hashtag>{t('shareImage.spotifyHashtag')}</Hashtag>
            </Text>
            <ButtonContainer>
              <Button type='green' onClick={getImageFromLayout}>
                Download image
              </Button>
            </ButtonContainer>
          </ModalContent>
        </ModalContainer>
      </Desktop>
      <Mobile>
        <ModalContainer open={isModalVisible} onCancel={onClose}>
          <BackButton onClick={onClose}>
            <BackIcon>
              <IconArrow />
            </BackIcon>
            <BackLabel>{t('shareImage.backLabel')}</BackLabel>
          </BackButton>
          <ModalContent>
            <Title>{t('shareImage.preview')}</Title>
            <ImageToShare
              imageWidth={imageWidthMobile}
              review={review}
              displayMode={displayMode}
            />
            <HiddenImageContainer>
              <ImageToShare
                imageWidth={imageForDownload}
                review={review}
                displayMode={displayMode}
                isForDownload
              />
            </HiddenImageContainer>
            {shouldRadioAppear && (
              <RadioContainer>
                <RadioAntd
                  options={radioOptions}
                  direction='vertical'
                  defaultValue={SharingImageDisplayModeEnum.square}
                  gapSize='sm'
                  onChange={(e) =>
                    changeDisplayMode(
                      e.target.value as SharingImageDisplayModeEnum,
                    )
                  }
                />
              </RadioContainer>
            )}
            <Text>
              {t('shareImage.hashtagsLabel')}
              <Hashtag>{t('shareImage.soundcampHashtag')}</Hashtag>
              <Hashtag>{t('shareImage.promotionHashtag')}</Hashtag>
              <Hashtag>{t('shareImage.spotifyHashtag')}</Hashtag>
            </Text>
            <ButtonContainer>
              <Button type='green' onClick={getImageFromLayout}>
                Download image
              </Button>
            </ButtonContainer>
          </ModalContent>
        </ModalContainer>
      </Mobile>
    </>
  )
}
