import { useBackFromPaymentPageSpotify } from 'src/Containers/ScheduleSpotifyWrapper/helpers/useBackFromPaymentPageSpotify'
import { useCheckSpotifyCampaignStatus } from 'src/Containers/ScheduleSpotifyWrapper/helpers/useCheckSpotifyCampaignStatus'
import { useSidebarVisibility } from 'src/Hooks/useSidebarVisibility'
import { useScheduleInitApiCalls } from 'src/Containers/ScheduleSpotifyWrapper/helpers/useSpotifyScheduleInitApiCalls'
import { useDefaultSpotifyCampaignStartDate } from 'src/Containers/ScheduleSpotifyWrapper/helpers/useDefaultSpotifyCampaignStartDate'
import { useCheckoutTimerFeatureFlag } from 'src/Hooks/useCheckoutTimerFeratureFlag'
import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'

import { useScheduleStartEvent } from './helpers/useScheduleStartEvent'
import { useIsCurrentSpotifyCampaign } from './helpers/useIsCurrentCampaign'

export const useScheduleSpotifyCampaign = (): void => {
  useIsCurrentSpotifyCampaign()
  useBackFromPaymentPageSpotify()
  useSidebarVisibility()
  useCheckSpotifyCampaignStatus()
  useScheduleInitApiCalls()
  useDefaultSpotifyCampaignStartDate()
  useScheduleStartEvent()

  useBonusTooltipTimer()
  useCheckoutTimerFeatureFlag()
}
