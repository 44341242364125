import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { MobileBlockWrapper, NormalText, SmallText } from '../../styles'

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  padding-right: 10px;
`

export const RewardInfo: FunctionComponent<{ reward: number | null }> = ({
  reward,
}) => {
  const { t } = useTranslation()

  const formattedRewardAmount = reward ? `$${reward.toFixed(2)}` : '-'

  return (
    <MobileBlockWrapper>
      <ValuesContainer>
        <SmallText>{t('soundsPage.reward')}</SmallText>
        <NormalText>{formattedRewardAmount}</NormalText>
      </ValuesContainer>
    </MobileBlockWrapper>
  )
}
