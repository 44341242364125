import { ANY_LANGUAGE_ID } from 'src/Constants/constants'
import {
  SpotifyTrackResponse,
  TiktokTrackResponse,
  Track,
} from 'src/Redux/firstTrackApi'

type TiktokCampaign = TiktokTrackResponse & {
  languages: number[]
  genres: number[]
}

type SpotifyCampaign = {
  url: string
  spotify_track: SpotifyTrackResponse
  language_ids: number[]
  genre_ids: number[]
}
interface TrackData {
  spotify_campaign: SpotifyCampaign | null
  tiktok_campaign: TiktokCampaign | null
}
const genreIds: number[] = []
const languageIds = [ANY_LANGUAGE_ID]
export const getTracksData = (track?: Track): TrackData => {
  const url = window.location.href
  const origin = track?.originalSound || {}
  let tikToKCampaign = null

  if ('isOriginalSound' in origin) {
    tikToKCampaign = {
      ...(origin as TiktokTrackResponse),
      languages: languageIds,
      genres: genreIds,
    }
  }

  let spotifyCampaign = null

  if ('album' in origin) {
    spotifyCampaign = {
      url,
      spotify_track: origin as SpotifyTrackResponse,
      language_ids: languageIds,
      genre_ids: genreIds,
    }
  }

  return {
    spotify_campaign: spotifyCampaign,
    tiktok_campaign: tikToKCampaign,
  }
}
