import { FC, PropsWithChildren } from 'react'

import styled from 'styled-components'

import { device } from 'src/Styled/variables'

import { CreateNewCampaignHeader } from '../CreateNewCampaignHeader'

const CreateCampaignContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f4f7;
  height: 100vh;
`

const CreateNewCampaignBlock = styled.div`
  background-color: #f3f4f7;
  padding: 0px 16px;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 301;
  overflow-y: auto;

  @media ${device.mobileMin} {
    margin: 0;
    padding: 0px 40px;
  }
`
export const CreateCampaignBlockContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  @media ${device.mobileMin} {
    max-width: 800px;
  }
`

type CreateNewCampaignContainerProps = PropsWithChildren & {
  onClick?: () => void
}

export const CreateNewCampaignContainer: FC<
  CreateNewCampaignContainerProps
> = ({ children, onClick }) => (
  <CreateCampaignContainer>
    <CreateNewCampaignBlock>
      <CreateNewCampaignHeader onClick={onClick} />
      <CreateCampaignBlockContainer>{children}</CreateCampaignBlockContainer>
    </CreateNewCampaignBlock>
  </CreateCampaignContainer>
)
