import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { colors, variables } from 'src/Styled/variables'
import { ProblemsWithTikTokErrorAlert } from 'src/Components/ProblemsWithTikTokErrorAlert'
import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import {
  selectStoppedSoundsCount,
  useStoppedSoundsQuery,
} from 'src/Redux/creatorStoppedSoundsApi'

import { TracksTable } from './components/TracksTable'
import { useSoundsPageAnalytics } from './useSoundsPageAnalytics'
import { WarningModal } from './components/WarningModal'
import { CampaignCanceledAlert } from './components/CampaignCanceledAlert'
import { ErrorModal } from './components/ErrorModal'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const TitleWrapper = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SoundsPage: FC = () => {
  const { t } = useTranslation()
  const { search } = useCustomSearchParams()

  const isErrorModalVisible = search.get('errorModalVisible') === 'true'

  // eslint-disable-next-line no-undefined
  const { stoppedSoundsSoundsCount } = useStoppedSoundsQuery(undefined, {
    selectFromResult: selectStoppedSoundsCount,
  })

  const { isError: isMonetizationError } = useGetMonetizationDataQuery()

  const isProblemsWithTikTokAlertVisible = isMonetizationError
  const isCampaignCanceledAlertVisible = stoppedSoundsSoundsCount > 0

  useSoundsPageAnalytics()

  return (
    <PageContainer>
      <TitleWrapper>
        <Title>{t('soundsPage.pageTitle')}</Title>
      </TitleWrapper>

      {isProblemsWithTikTokAlertVisible && <ProblemsWithTikTokErrorAlert />}
      {isCampaignCanceledAlertVisible && <CampaignCanceledAlert />}
      {isErrorModalVisible && <ErrorModal />}

      <TracksTable />

      <WarningModal />
    </PageContainer>
  )
}
