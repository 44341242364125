import { FC, useMemo } from 'react'

import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { influencerBoard, root } from 'src/Router/routes'
import { getIsCreator } from 'src/Redux/auth-process/credentialsSlice/selectors'

import { Link, Wrapper } from './styles'

const HELP_CENTER_LINK =
  'https://help.soundcamps.com/en/articles/9799252-views-based-reward-model'

export const TracksAlert: FC = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const isCreator = useSelector(getIsCreator)

  const showLevelsNotification =
    [influencerBoard].includes(pathname) || pathname === root

  const alertContent = useMemo(() => {
    const helpCenterLink = (
      <Link href={HELP_CENTER_LINK} target='_blank'>
        {t('levelsPage.helpCenter')}
      </Link>
    )

    if (showLevelsNotification) {
      return {
        text: (
          <span>
            {t('influencer.tracksAlert')} {helpCenterLink}
          </span>
        ),
        type: 'orange',
      }
    }

    return null
  }, [t, showLevelsNotification])

  if (!isCreator) {
    return null
  }

  if (alertContent) {
    return (
      <Wrapper alertType={alertContent.type}>
        <span>{alertContent.text} </span>
      </Wrapper>
    )
  }
  return null
}
