import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  input {
    width: 100%;
    color: ${colors.mainText};

    &:focus {
      outline: none;
    }
  }
`

export const StyledError = styled.span`
  position: absolute;
  right: 0;
  padding: 5px 10px;
  background: ${colors.pink};
  border-radius: 3px;
  font-size: 10px;
  line-height: 13px;
  color: ${colors.errorRed};
  margin: 0 0 -5px;
  display: block;
  top: -20px !important;

  &:after {
    content: '';
    position: absolute;
    right: 10px;
    bottom: -8px;
    border: 4px solid transparent;
    border-top-color: ${colors.pink};
  }
`
