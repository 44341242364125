import { FC } from 'react'

import {
  TiktokCampaignStatus,
  CampaignStatusEnum,
  CampaignPlatformTypeEnum,
} from 'src/Types/index'

import { CampaignReviewsProps } from './CampaignReviewsProps'
import { TiktokReviews } from './TiktokReviews'
import { SpotifyReviews } from './SpotifyReviews'

const HIDE_STATUSES = [
  CampaignStatusEnum.PENDING_APPROVAL,
  TiktokCampaignStatus.AWAITING_REVIEW,
  CampaignStatusEnum.APPROVED,
  TiktokCampaignStatus.PENDING,
  CampaignStatusEnum.SCHEDULED,
  CampaignStatusEnum.PAYMENT_PENDING,
]

const STOPPED_STATUSES = [
  TiktokCampaignStatus.STOPPED,
  CampaignStatusEnum.STOPPED,
  TiktokCampaignStatus.STOPPED_BY_ADMIN,
  TiktokCampaignStatus.CANCELED_BY_ADMIN,
  TiktokCampaignStatus.CANCELED,
]

const RUNNING_STATUSES = [
  CampaignStatusEnum.RUNNING,
  TiktokCampaignStatus.IN_PROCESS,
]

const ENDED_STATUSES = [CampaignStatusEnum.ENDED, TiktokCampaignStatus.FINISHED]

export const CampaignReviews: FC<CampaignReviewsProps> = ({ campaign }) => {
  const { status } = campaign

  if (HIDE_STATUSES.includes(status)) {
    return null
  }
  if (STOPPED_STATUSES.includes(status)) {
    return (
      <div>
        {campaign.platformType === CampaignPlatformTypeEnum.TIKTOK && (
          <TiktokReviews videosAmount={campaign.submittedVideosAmount} />
        )}
      </div>
    )
  }

  if (RUNNING_STATUSES.includes(status)) {
    return (
      <div>
        {campaign.platformType === CampaignPlatformTypeEnum.SPOTIFY ? (
          <div>
            <SpotifyReviews
              is_feed={campaign.is_feed}
              reviewedReviews={campaign.reviewedReviews}
              allReview={campaign.allReview}
            />
          </div>
        ) : (
          <div>
            <TiktokReviews videosAmount={campaign.submittedVideosAmount} />
          </div>
        )}
      </div>
    )
  }
  if (ENDED_STATUSES.includes(status)) {
    return (
      <div>
        {campaign.platformType === CampaignPlatformTypeEnum.SPOTIFY ? (
          <SpotifyReviews
            is_feed={campaign.is_feed}
            reviewedReviews={campaign.reviewedReviews}
            allReview={campaign.allReview}
          />
        ) : (
          <TiktokReviews videosAmount={campaign.submittedVideosAmount} />
        )}
      </div>
    )
  }
  return null
}
