import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { colors, device, variables } from 'src/Styled/variables'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { useCreateCampaignEvents } from 'src/Hooks/Analytics/useCreateCampaignEvents'
import { create } from 'src/Router/routes'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { toggleActivatedEvent } from 'src/Helpers/TagManager'

import { CreateCampaignHeader } from '../../Components/CreateCampaignLayout/components/CreateCampaignHeader'
import { HelmetTitle } from '../../Components/CreateCampaignLayout/components/HelmetTitle'
import { WelcomeAlert } from '../../Components/WelcomeAlert'
import { StartButtons } from '../../Components/CreateCampaignLayout/StartButtons'
import { InfoForNotAuthUser } from '../../Components/CreateCampaignLayout/InfoForNotAuthUser'

const CreateCampaignContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f4f7;
  height: 100vh;
`

const CreateFirstCampaignBlock = styled.div`
  background-color: #f3f4f7;
  padding: 0px 16px;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 301;
  overflow-y: auto;

  @media ${device.mobileMin} {
    margin: 0;
    padding: 0px 40px;
    overflow-y: auto;
  }
`

const CreateCampaignBlockContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  @media ${device.mobileMin} {
    max-width: 800px;
  }
`

const CreateCampaignHead = styled.div`
  padding-left: 0;
  padding-top: 0px;
  margin-bottom: 21px;
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${colors.mainText};
  margin-top: 21px;

  @media ${device.mobileMin} {
    margin-top: 24px;
    font-size: ${variables.fontSize24};
    margin-bottom: 18px;
  }
`

const CreateCampaignSubHead = styled.div`
  padding-left: 0;
  padding-top: 0px;
  margin-bottom: 24px;
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight400};
  line-height: 27px;
  color: ${colors.mainText};
  cursor: pointer;
`

export const FirstCreateCampaign: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { search } = useCustomSearchParams()
  const { campaignCreationStartMpEventHandler } = useCreateCampaignEvents()

  const handleToggle = (platformType: CampaignPlatformTypeEnum): void => {
    navigate(`${create}/${platformType}?${search.toString()}`)
    toggleActivatedEvent({
      platformName: platformType,
      campaignType: CampaignTypeEnum.FIRST,
      campaignNumber: 1,
    })
    campaignCreationStartMpEventHandler({
      platformType,
    })
  }
  return (
    <CreateCampaignContainer>
      <CreateFirstCampaignBlock>
        <CreateCampaignHeader />
        <CreateCampaignBlockContainer id='scroll'>
          <HelmetTitle />
          <WelcomeAlert />
          <CreateCampaignHead>
            {t('campaignsPage.createNewCampaign')}
          </CreateCampaignHead>
          <CreateCampaignSubHead>
            {t('campaignsPage.chooseTypeOfCampaignToStart')}
          </CreateCampaignSubHead>
          <StartButtons onClick={handleToggle} />
          <InfoForNotAuthUser />
        </CreateCampaignBlockContainer>
      </CreateFirstCampaignBlock>
    </CreateCampaignContainer>
  )
}
