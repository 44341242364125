import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { usePageViewEvent } from 'src/Hooks/Analytics/usePageViewEvent'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { useCreateCampaignStartEvent } from 'src/Hooks/Analytics/useCreateCampaignStartEvent'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { StartButtons } from 'src/Components/CreateCampaignLayout/StartButtons'
import { useCreateCampaignEvents } from 'src/Hooks/Analytics/useCreateCampaignEvents'
import { campaignsList, createCampaign } from 'src/Router/routes'
import { CreateNewCampaignContainer } from 'src/Components/CreateNewCampaignContainer'

import { CreateNewCampaignHelmet } from './components/CreateNewCampaignHelmet'

import { CreateCampaignHead, CreateCampaignSubHead } from './styles'

const CreateCampaignPage: FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { campaignCreationStartMpEventHandler } = useCreateCampaignEvents()
  usePageViewEvent(pageInfo.createCampaign)
  useCreateCampaignStartEvent()

  const handleSpotifyToggle = (
    platformType: CampaignPlatformTypeEnum,
  ): void => {
    navigate(`${campaignsList}${createCampaign}/${platformType}`)

    campaignCreationStartMpEventHandler({
      platformType,
    })
  }

  return (
    <CreateNewCampaignContainer>
      <CreateNewCampaignHelmet />

      <CreateCampaignHead>
        {t('campaignsPage.createNewCampaign')}
      </CreateCampaignHead>
      <CreateCampaignSubHead>
        {t('campaignsPage.chooseTypeOfCampaignToStart')}
      </CreateCampaignSubHead>
      <StartButtons onClick={handleSpotifyToggle} />
    </CreateNewCampaignContainer>
  )
}

export default CreateCampaignPage
