import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { usePageViewEvent } from 'src/Hooks/Analytics/useTrackEvents'
import {
  Track,
  useGetFirstSpotifyTrackQuery,
  useGetFirstTikTokTrackQuery,
} from 'src/Redux/firstTrackApi'
import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'
import { getValueOrZero } from 'src/Constants/functions'
import { useGetFreeSpotifyTokenQuery } from 'src/Redux/freeSpotifyTokenApi'
import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'

export interface FirstChosenTrackParams {
  [key: string]: string | undefined
  platform?: CampaignPlatformTypeEnum
  trackId?: string
}

type UseFirstChosenTrack = {
  track: Track | undefined
  loading?: boolean
  platform: CampaignPlatformTypeEnum | undefined
}

export const useFirstChosenTrack = (): UseFirstChosenTrack => {
  const { platform, trackId } = useParams<FirstChosenTrackParams>()

  const { data: tokenData, isLoading: isTokenLoading } =
    useGetFreeSpotifyTokenQuery()
  const navigate = useNavigate()
  const {
    data: spotifyTrack,
    isLoading: isSpotifyLoading,
    isError: isSpotifyError,
  } = useGetFirstSpotifyTrackQuery(
    {
      trackId,
      token: tokenData?.accessToken,
    },
    {
      skip:
        !trackId ||
        !tokenData?.accessToken ||
        !platform ||
        platform !== CampaignPlatformTypeEnum.SPOTIFY,
    },
  )
  const {
    data: tiktokTrack,
    isLoading: isTiktokLoading,
    isError: isTiktokError,
  } = useGetFirstTikTokTrackQuery(trackId || '', {
    skip: !trackId || !platform || platform !== CampaignPlatformTypeEnum.TIKTOK,
  })
  const { trackAddedEventHandler } = usePageViewEvent()

  const track =
    platform === CampaignPlatformTypeEnum.SPOTIFY ? spotifyTrack : tiktokTrack
  useEffect(() => {
    if (track) {
      trackAddedEventHandler({
        artistName: getValueOrEmptyString(track.artistName),
        platformType: CampaignPlatformTypeEnum.SPOTIFY,
        trackDuration: getValueOrZero(track.trackDurationInMs),
        trackId: getValueOrEmptyString(track.trackId),
        trackName: getValueOrEmptyString(track.trackName),
      })
    }
  }, [track, trackAddedEventHandler])

  useEffect(() => {
    if (isSpotifyError || isTiktokError) {
      navigate(NAVIGATE_BACK_INDEX)
    }
  }, [isSpotifyError, isTiktokError, navigate])

  return {
    track,
    platform,
    loading: isSpotifyLoading || isTiktokLoading || isTokenLoading,
  }
}
