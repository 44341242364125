import React, { MouseEvent, ReactElement, useMemo } from 'react'

import { Button as AntButton } from 'antd'
import { useDebouncedCallback } from 'use-debounce'

import PayPalIcon from 'src/Assets/Svg/paypal_2.svg?react'
import { LoadingSpinner } from 'src/Containers/ui'

import { CustomButtonProps } from './ButtonProps'

import { IconWithContentWrapper, StyledButton } from './styles'

const Button: React.FC<CustomButtonProps> = ({
  children,
  type,
  loading,
  onClick,
  disabled,
  htmlType,
  className,
}): ReactElement => {
  const debouncedOnClick = useDebouncedCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (typeof onClick === 'function') {
        onClick(event)
      }
    },
    0,
    { trailing: true },
  )

  const content = useMemo(() => {
    if (loading) {
      return <LoadingSpinner />
    }

    if (type === 'paypal') {
      return (
        <IconWithContentWrapper>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <PayPalIcon />
              {children}
            </>
          )}
        </IconWithContentWrapper>
      )
    }
    return children
  }, [children, loading, type])

  return (
    <StyledButton className={className} type={type}>
      <AntButton
        htmlType={htmlType}
        // eslint-disable-next-line no-undefined
        disabled={disabled !== undefined ? disabled : Boolean(loading)}
        onClick={(e) => debouncedOnClick(e)}
      >
        {content}
      </AntButton>
    </StyledButton>
  )
}

export default Button
