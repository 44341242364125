import TagManager from 'react-gtm-module'

import { CampaignTypeEnum } from 'src/Types'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const artistRegistrationCompleteEvent = ({
  platformName,
  userId,
  userRole,
  spotifyCampaignId,
  tiktokCampaignId,
  isFeed,
}: {
  platformName: string
  userId: number | string
  userRole: string
  spotifyCampaignId?: number | string
  tiktokCampaignId?: number | string
  isFeed?: boolean
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.RegistrationComplete,
      event_name: EventNameEnum.RegistrationComplete,
      'campaign-type': CampaignTypeEnum.FIRST,
      'campaign-platform': platformName,
      'campaign-number': '1',
      ...(spotifyCampaignId ? { campaign_id_spotify: spotifyCampaignId } : {}),
      ...(tiktokCampaignId ? { campaign_id_tiktok: tiktokCampaignId } : {}),
      userID: userId,
      'account-type': userRole,
      'user-role': userRole,
      is_feed: isFeed,
    },
  })
}

export const viewSignUpPopUpEvent = ({
  platformName,
}: {
  platformName: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.ViewSignUpPopUp,
      event_name: EventNameEnum.ViewSignUpPopUp,
      'campaign-type': CampaignTypeEnum.FIRST,
      'campaign-platform': platformName,
      'campaign-number': '1',
    },
  })
}

export const emailUncheckedEvent = (userID: string): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.EmailUnchecked,
      },
      user_properties: {
        userID,
      },
    },
  })
}

export const phoneEnteredEvent = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.PhoneEntered,
      },
    },
  })
}

export const phoneNumberAddedEvent = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      'campaign-type': CampaignTypeEnum.FIRST,
      event: EventTypeEnum.PhoneAdded,
    },
  })
}

export const curatorRegistrationStartEvent = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CuratorRegistrationStart,
      event_name: EventNameEnum.CuratorRegistrationStart,
      'account-type': 'curator',
      'campaign-platform': 'spotify',
    },
  })

  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CRegStart,
      'campaign-platform': 'spotify',
    },
  })
}

export const curatorRegistrationCompleteEvent = ({
  userId,
}: {
  userId: number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventNameEnum.RegistrationComplete,
      event_name: EventNameEnum.RegistrationComplete,
      'account-type': 'curator',
      userID: userId,
    },
  })
}

export const creatorRegistrationStartEvent = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CreatorRegistrationStart,
      event_name: EventNameEnum.CreatorRegistrationStart,
      'account-type': 'creator',
      'campaign-platform': 'tiktok',
    },
  })

  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CRegStart,
      'campaign-platform': 'tiktok',
    },
  })
}

export const fillingRegisterFormEvent = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.RegisterFormFilling,
    },
  })
}

export const influencerRegistered = ({
  userID,
}: {
  userID: number | string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventNameEnum.RegistrationComplete,
      event_name: EventNameEnum.RegistrationComplete,
      'account-type': 'creator',
      userID,
    },
  })
}
