import Cookies from 'js-cookie'
import dayjsDuration from 'dayjs/plugin/duration'
import { TFunction } from 'i18next'

import dayjs from 'src/Helpers/dayjs'
import { APP } from 'src/Configs/App'
import { ArtistRolesEnum, Roles } from 'src/Constants/enums'
import {
  CampaignPlatformTypeEnum,
  UtmObj,
  SpotifyCampaign,
  TiktokCampaign,
} from 'src/Types'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'

import { CURRENCY } from './currency'
import {
  DEFAULT_PERIOD_TO_START_CAMPAIGN,
  gaReferrerBlacklist,
} from './constants'

export const kFormatter = (value: number): string | number => {
  const MIN_NUMBER = 999
  const MAX_NUMBER = 1000000
  const ROUNDING_INDEX = 3
  const SLICE_END_INDEX = -1

  if (value > MIN_NUMBER && value < MAX_NUMBER) {
    return `${
      Math.sign(value) *
      parseFloat(
        (Math.abs(value) / 1000)
          .toFixed(ROUNDING_INDEX)
          .slice(0, SLICE_END_INDEX),
      )
    }k`
  }
  if (value > MAX_NUMBER) {
    return `${
      Math.sign(value) *
      parseFloat(
        (Math.abs(value) / MAX_NUMBER)
          .toFixed(ROUNDING_INDEX)
          .slice(0, SLICE_END_INDEX),
      )
    }m`
  }
  return Math.sign(value) * Math.abs(value)
}

export const currencySymbolConverter = (currency: string): string => {
  // eslint-disable-next-line no-prototype-builtins
  if (CURRENCY.hasOwnProperty(currency)) {
    return CURRENCY[currency as keyof typeof CURRENCY]
  }

  return currency
}

export const getCookieByName = (value: string): string | undefined => {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${value.replace(
        // eslint-disable-next-line no-useless-escape
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        '\\$1',
      )}=([^;]*)`,
    ),
  )

  // eslint-disable-next-line no-undefined
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export const checkReferrerUrl = (url: string): boolean => {
  let res = true

  for (const element of gaReferrerBlacklist) {
    if (url.includes(element)) {
      res = false
    }
  }

  return res
}

export const getUtmParameters = (value?: string): UtmObj => {
  const obj: { [key: string]: string } = {}
  const fbp = getCookieByName('_fbp')
  const fbc = getCookieByName('_fbc')
  const { userAgent } = window.navigator
  const isReferrerUrlValid = checkReferrerUrl(document.referrer)

  if (document.referrer && isReferrerUrlValid) {
    obj.referrer_url = document.referrer
  }

  if (fbp) {
    obj.fbp = fbp
  }
  if (fbc) {
    obj.fbc = fbc
  }
  if (userAgent) {
    obj.client_user_agent = userAgent
  }

  if (typeof value === 'string') {
    const slicedAndSplitValues = value.slice(1).split('&')

    for (const element of slicedAndSplitValues) {
      const splitElement = element.split('=')
      obj[splitElement[0]] = decodeURIComponent(splitElement[1])
    }
  }

  return obj
}

export const numberFormatSpaces = (number: number | undefined): string | 0 =>
  number ? number.toLocaleString('fr', { maximumFractionDigits: 2 }) : 0

export const numberFormatCommas = (number: number | undefined): string | 0 =>
  number ? number.toLocaleString('en-US', { maximumFractionDigits: 2 }) : 0

export const hidePartOfEmailWithStars = (arg0: string): string => {
  if (!arg0) {
    return ''
  }
  const ROUNDING_INDEX = 0.3
  const percentageOfString = Math.round(arg0.length * ROUNDING_INDEX)
  const firstLetterOfEmail = arg0.slice(0, percentageOfString)
  const hiddenPartOfEmail = arg0
    .substring(0, arg0.indexOf('@') - percentageOfString)
    .replace(/./gi, '*')
  const secondPartOfEmail = arg0.substring(arg0.indexOf('@') + 1)
  return `${firstLetterOfEmail}${hiddenPartOfEmail}@${secondPartOfEmail}`
}

export function renderRole(
  userRole: Roles,
  t: TFunction,
  artistRole?: string,
): string {
  if (userRole === Roles.curator) {
    return t('sidebar.curator')
  }

  if (userRole === Roles.influencer) {
    return t('sidebar.creator')
  }

  if (userRole === Roles.artist) {
    if (artistRole === ArtistRolesEnum.AGENT) {
      return t('createCampaignPage.agent')
    }
    if (artistRole === ArtistRolesEnum.LABEL) {
      return t('createCampaignPage.label')
    }
    if (artistRole === ArtistRolesEnum.MANAGER) {
      return t('createCampaignPage.manager')
    }
    if (artistRole === ArtistRolesEnum.PUBLISHER) {
      return t('createCampaignPage.publisher')
    }
    if (artistRole === ArtistRolesEnum.ADMIN) {
      return t('createCampaignPage.admin')
    }
    return t('sidebar.artist')
  }

  return ''
}

export const removeCookiesHandler = (): void => {
  Cookies.remove('_ga')
  Cookies.remove(`${APP.GA_CONTAINER}`)
  Cookies.remove('sondcsrd')
  Cookies.remove('soundCampaignUtm')
  Cookies.remove('_fbp')
  Cookies.remove('_fbc')
}

export const calculateDaysLeft = (endDate: string | Date): number =>
  dayjs(endDate).diff(dayjs(), 'days') + 1

export const getIsMoreThenDaysLeft = (
  futureDate?: string | Date | null,
): boolean => {
  const periodToStartCampaignInHours = DEFAULT_PERIOD_TO_START_CAMPAIGN * 24

  if (!futureDate) {
    return false
  }
  const date = dayjs(futureDate)
  const now = dayjs()

  const diffInHours = date.diff(now, 'hour')
  return diffInHours > periodToStartCampaignInHours
}

export const handleDefinePlatform = (params: {
  isSpotifyTrack: boolean
  isTikTokTrack: boolean
}): CampaignPlatformTypeEnum => {
  if (params.isSpotifyTrack && params.isTikTokTrack) {
    return CampaignPlatformTypeEnum.SPOTIFY_TIKTOK
  }
  if (!params.isSpotifyTrack && params.isTikTokTrack) {
    return CampaignPlatformTypeEnum.TIKTOK
  }
  return CampaignPlatformTypeEnum.SPOTIFY
}

export const convertTimeHandler = (params: {
  value: number
  type: 'ms' | 's'
}): string => {
  dayjs.extend(dayjsDuration)
  const duration = dayjs
    .duration({
      milliseconds: params.type === 'ms' ? params.value : 0,
      seconds: params.type === 's' ? params.value : 0,
    })
    .asMilliseconds()

  if (duration < 0) {
    return '00:00'
  }

  const minutes = new Intl.NumberFormat('en-IN', {
    minimumIntegerDigits: 2,
  }).format(dayjs(duration).get('minutes'))

  const seconds = new Intl.NumberFormat('en-IN', {
    minimumIntegerDigits: 2,
  }).format(dayjs(duration).get('seconds'))

  return `${minutes}:${seconds}`
}

export const compareCampaignsByDate = (
  a: SpotifyCampaign | TiktokCampaign,
  b: SpotifyCampaign | TiktokCampaign,
): number =>
  new Date(b?.createdAt || 0).getTime() - new Date(a?.createdAt || 0).getTime()

export const formattedNumber = (amount: number): string =>
  amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const handleTakePlatformFromUrl = (
  url: string,
): CampaignPlatformTypeEnum | string => {
  if (url.includes('scheduleTikTok')) {
    return CampaignPlatformTypeEnum.TIKTOK
  }
  if (url.includes('schedule')) {
    return CampaignPlatformTypeEnum.SPOTIFY
  }
  return ''
}

export const toReversed = <T>(arr: T[]): T[] => [...arr].reverse()

export const getDefaultStartDate = (isToday?: boolean): string =>
  dayjs()
    .add(isToday ? 0 : DEFAULT_PERIOD_TO_START_CAMPAIGN, 'days')
    .toISOString()
export const getValueOrZero = (value?: number): number => value || 0
export const getIsTikTokTrackUrl = (value?: string): boolean => {
  if (!value) {
    return false
  }

  return (
    value.startsWith('https://vm.tiktok.com/') ||
    value.startsWith('https://www.tiktok.com/music/') ||
    value.startsWith('https://vt.tiktok.com/') ||
    value.startsWith('https://www.tiktok.com/t/')
  )
}

export const getSpotifyTrackId = (url?: string): string => {
  if (!url) {
    return ''
  }
  const urlParts = url.split('/')
  return urlParts[urlParts.length - 1]
}

export const transformArrayStrings = (array: string[] | undefined): string => {
  if (!array) {
    return ''
  }
  const capitalizedArray = array.map((el) => capitalizeFirstLetter(el))
  return capitalizedArray.join(', ')
}

const FIRST_SORT_PRIORITY = -1
const SECOND_SORT_PRIORITY = 1

export const filterAndSortGenres = <
  T extends { genre_name?: string; genreName?: string },
>(
  genres: T[],
  search: string,
  genreKey: 'genre_name' | 'genreName',
): T[] => {
  const searchValue = search.trim().toLowerCase()

  const exactMatchPriority = (aName: string, bName: string): number => {
    if (aName === searchValue) {
      return FIRST_SORT_PRIORITY
    }
    if (bName === searchValue) {
      return SECOND_SORT_PRIORITY
    }
    return 0
  }

  const startsWithPriority = (aName: string, bName: string): number => {
    if (aName.startsWith(searchValue)) {
      return FIRST_SORT_PRIORITY
    }
    if (bName.startsWith(searchValue)) {
      return SECOND_SORT_PRIORITY
    }
    return 0
  }

  const containsPriority = (aName: string, bName: string): number => {
    if (aName.includes(searchValue) && !bName.includes(searchValue)) {
      return FIRST_SORT_PRIORITY
    }
    if (!aName.includes(searchValue) && bName.includes(searchValue)) {
      return SECOND_SORT_PRIORITY
    }
    return 0
  }

  const sortGenres = (a: T, b: T): number => {
    const aName = a?.[genreKey]?.toLowerCase() || ''
    const bName = b?.[genreKey]?.toLowerCase() || ''

    const exactMatch = exactMatchPriority(aName, bName)
    if (exactMatch !== 0) {
      return exactMatch
    }

    const startsWith = startsWithPriority(aName, bName)
    if (startsWith !== 0) {
      return startsWith
    }

    const contains = containsPriority(aName, bName)
    if (contains !== 0) {
      return contains
    }

    return 0
  }

  return genres
    .filter((item) => item[genreKey]?.toLowerCase().includes(searchValue))
    .sort(sortGenres)
}
