import React from 'react'

import { PayoutCardWrapper, Title } from './styles'

export type BadgeType = 'danger' | 'regular' | 'disabled' | 'warning'

export interface BadgeProps {
  title: string
  type: BadgeType
}

export const Badge: React.FC<BadgeProps> = ({ title, type }) => (
  <PayoutCardWrapper type={type}>
    <Title type={type}>{title}</Title>
  </PayoutCardWrapper>
)
