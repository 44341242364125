import { useDebouncedCallback } from 'use-debounce'

import { useGetFreeSpotifyTokenQuery } from 'src/Redux/freeSpotifyTokenApi'
import {
  SpotifyTrack,
  useClearSpotifyTracksMutation,
  useLazyGetSpotifyTracksQuery,
} from 'src/Redux/spotifyTracksApi'
import { DEBOUNCE_TIMER_1000 } from 'src/Constants/constants'

interface SpotifyWidgetSearchProps {
  onSearchTrack: (searchInput: string) => void
  tracks?: SpotifyTrack[]
  loading?: boolean
  error?: string
}

export const useSpotifyWidgetSearch = (): SpotifyWidgetSearchProps => {
  const { data: tokenData } = useGetFreeSpotifyTokenQuery()
  const [searchTrack, { data: tracks, isLoading: loading, error, isSuccess }] =
    useLazyGetSpotifyTracksQuery()
  const [clearTracks] = useClearSpotifyTracksMutation()

  const onSearchTrack = useDebouncedCallback((searchInput: string) => {
    if (tokenData?.accessToken && searchInput) {
      searchTrack({ search: searchInput, token: tokenData.accessToken })
    } else {
      clearTracks()
    }
  }, DEBOUNCE_TIMER_1000)

  const getError = (): string => {
    if (tracks && tracks.length === 0 && isSuccess) {
      return 'No tracks were found'
    }
    return (error as { message?: string })?.message || ''
  }

  return { onSearchTrack, tracks, loading, error: getError() }
}
