import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from 'src/Constants/formatDate'
import dayjs from 'src/Helpers/dayjs'
import Alert from 'src/Components/Alert'
import { TiktokCampaignStatus } from 'src/Types'

export interface EngagementDataUpdatedAlertProps {
  dateInfo: string
  status?: TiktokCampaignStatus
  isMonitoringVideosAvailable?: boolean
}

export const EngagementDataUpdatedAlert: FunctionComponent<
  EngagementDataUpdatedAlertProps
> = ({ dateInfo, status, isMonitoringVideosAvailable }) => {
  const { t } = useTranslation()
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const displayedDate = dayjs(dateInfo)
    .tz(timezone)
    .format(formatDate.formatDateWithTime2)

  const isEndedCampaign =
    status === TiktokCampaignStatus.ENDED ||
    status === TiktokCampaignStatus.STOPPED

  const getText = (): { title: string | null } => {
    if (isMonitoringVideosAvailable && isEndedCampaign) {
      return {
        title: t('campaignResultsPage.engagementDataWillBeUpdated'),
      }
    }

    if (!isMonitoringVideosAvailable && isEndedCampaign) {
      return {
        title: t('campaignResultsPage.engagementDataNoUpdates'),
      }
    }

    return { title: null }
  }

  const { title } = getText()

  if (!title) {
    return null
  }

  return (
    <Alert color='warning' noBorder icon unclosable middleGap>
      {title}
      {displayedDate}
    </Alert>
  )
}
