import { FunctionComponent, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { OrderedListProps } from './OrderedListProps'

import {
  OrderedListContainer,
  OrderedListNumber,
  OrderedListTitle,
  OrderedItem,
  OrderedBlock,
  OrderedText,
  ImageWrapper,
} from './styles'

export const OrderedList: FunctionComponent<OrderedListProps> = ({ list }) => {
  const { t } = useTranslation()

  const renderList = useMemo(
    () =>
      list.map((item) => (
        <OrderedItem key={item.id}>
          <OrderedListNumber>{item.id}</OrderedListNumber>
          <OrderedBlock>
            <OrderedListTitle>{t(item.title)}</OrderedListTitle>
            <OrderedText>{t(item.text)}</OrderedText>
            {item.img && (
              <ImageWrapper>
                <img
                  src={item.img}
                  alt={item.altName}
                  height={item.imgHeight}
                  width={item.imgWidth}
                />
              </ImageWrapper>
            )}
            {item?.text2 ? <OrderedText>{t(item.text2)}</OrderedText> : null}
          </OrderedBlock>
        </OrderedItem>
      )),
    [list, t],
  )

  return <OrderedListContainer>{renderList}</OrderedListContainer>
}
