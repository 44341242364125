import TagManager from 'react-gtm-module'

import { CampaignPlatformTypeEnum, CampaignTypeEnum, ID } from 'src/Types'
import { Roles } from 'src/Constants/enums'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const tiktokAddTrackHintEvent = ({
  campaignType,
  userId,
}: {
  campaignType: CampaignTypeEnum
  userId?: ID | null
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.TiktokAddTrackHint,
        campaign_platform: CampaignPlatformTypeEnum.TIKTOK,
        campaign_type: campaignType,
      },
      user_properties: {
        ...(userId ? { user_ID: userId } : {}),
        account_type: Roles.artist,
      },
    },
  })
}
