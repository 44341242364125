import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Player as RootPlayer } from 'src/Components/Player'
import { colors, variables } from 'src/Styled/variables'

export const PlayerWrapper = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`

export const StyledPlayer = styled(RootPlayer)`
  width: 100%;
`

export const StyledLink = styled(Link)`
  color: ${colors.green};
  text-align: center;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  border-bottom: 1px dashed ${colors.mainText};

  &:hover,
  &:active,
  &:focus {
    color: ${colors.green};
  }
`

interface PlayerProps {
  trackAuthorName: string
  trackTitle: string
  trackPlayUrl: string
  trackCoverUrl: string
  trackUrl: string
}

export const Player: FunctionComponent<PlayerProps> = ({
  trackAuthorName,
  trackTitle,
  trackPlayUrl,
  trackCoverUrl,
  trackUrl,
}) => {
  const { t } = useTranslation()
  return (
    <PlayerWrapper>
      <StyledPlayer
        url={trackPlayUrl}
        authorName={trackAuthorName}
        trackName={trackTitle}
        cover={trackCoverUrl}
      />
      <LinkWrapper>
        <span>{t('soundsPage.orOpenOn')}</span>
        <StyledLink target='_blank' to={trackUrl}>
          {t('soundsPage.tikTok')}
        </StyledLink>
      </LinkWrapper>
    </PlayerWrapper>
  )
}
