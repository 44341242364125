import { Drawer } from 'antd'
import styled from 'styled-components'
import { DrawerProps } from 'antd/lib'

import { colors } from 'src/Styled/variables'

interface StyledComponentsProps {
  isMenuOpened: boolean
  isAlertMode?: boolean
}

export const StyledScrollbar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-height: 550px) {
    overflow: auto;
  }
`
export const StyledDrawer = styled((props: DrawerProps) => (
  <Drawer rootClassName={props.className} {...props} />
))`
  z-index: 200 !important;
  .ant-drawer-content-wrapper {
    width: 240px !important;
  }
`

export const SidebarWrapper = styled.div<StyledComponentsProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  width: ${({ isMenuOpened }) => (isMenuOpened ? '240px' : '60px')};
  padding-top: ${({ isAlertMode }) => (isAlertMode ? '93px' : '60px')};
  box-shadow: 0 1px 30px 1px rgb(0 0 0 / 11%);
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  transition: all 1s ease-out;
`

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  overscroll-behavior-y: contain;
`
