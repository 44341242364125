import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { viewSignUpPopUpEvent } from 'src/Helpers/TagManager'
import { FirstChosenTrackParams } from 'src/Hooks/useFirstChosenTrack'

export const useViewSignup = (): void => {
  const { platform } = useParams<FirstChosenTrackParams>()
  useEffect(() => {
    if (platform) {
      viewSignUpPopUpEvent({
        platformName: platform,
      })
    }
  }, [platform])
}
