import { createAsyncThunk } from '@reduxjs/toolkit'
import { User } from '@sentry/react'
import axios, { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { Roles } from 'src/Constants/enums'
import { FormDataProps } from 'src/Containers/CreateCampaignWithoutSignup/components/UserRegisterForm/UserRegisterFormProps'
import { NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'
import {
  CampaignPlatformTypeEnum,
  CampaignStatusEnum,
  TiktokCampaignStatus,
  Track,
  UtmObj,
} from 'src/Types'
import { sendSourceBusterEvent } from 'src/Redux/sourcebuster-process/api-actions'
import { SpotifyTrackResponse } from 'src/Redux/firstTrackApi'

interface SignInBody {
  email: string
  password: string
}

interface SignInResponse {
  inBlacklist: boolean
  isArtist: boolean
  role: Roles
  token: string
  user: User
}

export const signIn = createAsyncThunk<SignInResponse, SignInBody>(
  `${NameSpace.Credentials}/signIn`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<SignInResponse>(
        `${APP.SERVER}/auth/login`,
        payload,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

interface CreateCampaignWithoutSignupBody {
  captchaToken: string
  artist: FormDataProps
  spotify_campaign: {
    spotify_track: SpotifyTrackResponse
    language_ids: Array<number>
    genre_ids: Array<number>
    url: string
  } | null
  tiktok_campaign: {
    languages: Array<number>
    genres: Array<number>
  } | null
  gcid: string
  utmObj: UtmObj
  referral_code: string
  internal_referral_code: string
}

export interface CreateCampaignWithoutSignupResponse {
  user: {
    id: string
    role: string
    email: string
  }
  spotify_campaign: {
    id: string
    is_feed?: boolean
    type: string
    Track: {
      id: number
    }
    status: CampaignStatusEnum
  }
  tiktok_campaign: {
    id: string
    type: string
    status: TiktokCampaignStatus
  }
  token: string
  isArtist: boolean
  role: Roles
  utmObj: {
    ref: unknown
  }
}
export const createCampaignWithoutSignup = createAsyncThunk<
  CreateCampaignWithoutSignupResponse,
  CreateCampaignWithoutSignupBody
>(
  `${NameSpace.Credentials}/createCampaignWithoutSignup`,
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post<CreateCampaignWithoutSignupResponse>(
        `${APP.SERVER}/campaign/create`,
        {
          wqewq: payload.captchaToken,
          artist: payload.artist,
          spotify_campaign: payload.spotify_campaign || null,
          // remove isDanceRequested after the backend will remove this field
          tiktok_campaign: payload.tiktok_campaign
            ? { ...payload.tiktok_campaign, isDanceRequested: false }
            : null,
          gcid: payload.gcid,
          utmObj: payload.utmObj,
          referral_code: payload.referral_code,
          internal_referral_code: payload.internal_referral_code,
        },
      )
      const campaignId =
        Number(data.spotify_campaign?.id) || Number(data.tiktok_campaign?.id)

      const campaignPlatform = data.spotify_campaign
        ? CampaignPlatformTypeEnum.SPOTIFY
        : CampaignPlatformTypeEnum.TIKTOK

      await dispatch(
        sendSourceBusterEvent({
          campaignId,
          campaignPlatform,
          eventName: 'create_first_campaign',
          token: data.token,
          gclid: payload.utmObj.gclid || '',
        }),
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

interface SignUpArtistBody {
  firstName: string
  lastName: string
  password: string
  email: string
  gcid: string
  utmObj: UtmObj
}

interface SignUpArtistResponse {
  token: string
  role: Roles
  isArtist: boolean
  user: User
}

export const signUpArtist = createAsyncThunk<
  SignUpArtistResponse,
  SignUpArtistBody
>(
  `${NameSpace.Credentials}/signUpArtist`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<SignUpArtistResponse>(
        `${APP.SERVER}/auth/signup-artist`,
        {
          first_name: payload.firstName,
          last_name: payload.lastName,
          password: payload.password,
          email: payload.email,
          gcid: payload.gcid,
          utmObj: payload.utmObj,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

interface SignUpUserResponse {
  token: string
  email: string
}

export const changeEmail = createAsyncThunk<SignUpUserResponse, string>(
  `${NameSpace.Credentials}/changeEmail`,
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await api.put<SignUpUserResponse>(
        `${APP.SERVER}/user/email`,
        {
          email,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

interface SetRoleToUserBody {
  spotify_id: string
  userType: Roles
  gcid: string
  utmObj: UtmObj
  access_token?: string
  email?: string
  is_email_confirmed?: boolean
  refresh_token?: string
  user_photo_url?: string | null
  first_name?: string
  last_name?: string
}

interface SetRoleToUserResponse {
  token: string
  role: Roles
  isArtist: boolean
  user: User
}
export const setRoleToUser = createAsyncThunk<
  SetRoleToUserResponse,
  SetRoleToUserBody
>(
  `${NameSpace.Credentials}/setRoleToUser`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await api.post<SetRoleToUserResponse>(
        `${APP.SERVER}/auth/who-are-you`,
        body,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data,
        status: response?.status,
      })
    }
  },
)

interface SignInAsBody {
  id: number
  isArtist: boolean
}

interface SignInAsResponse {
  token: string
  isArtist: boolean
  role: Roles
  user: User
}

export const signInAs = createAsyncThunk<SignInAsResponse, SignInAsBody>(
  `${NameSpace.Credentials}/signInAs`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await api.post<SignInAsResponse>(
        `${APP.SERVER}/admin/login`,
        body,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)
