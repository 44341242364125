import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'

import Alert from 'src/Components/Alert'
import { variables } from 'src/Styled/variables'
import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import { TextList } from 'src/Containers/Monetization/components/TextList'

export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
  margin-bottom: 5px;
`
export const AlertSubTitle = styled.div`
  margin-bottom: 8px;
`
export const TextListWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`
export const AlertStyled = styled(Alert)`
  margin-bottom: 0 !important;
  width: 100%;
`
export const LinkWrapper = styled.div`
  margin-top: 28px;
  padding-bottom: 6px;
`
export const ProblemsWithTikTokErrorAlert: FC = () => {
  const { t } = useTranslation()

  const { show } = useIntercom()

  const listItems = [
    t('monetization.stayActiveError'),
    t('monetization.ensureYourProfile'),
  ]

  const handleClickSupport = (): void => {
    show()
  }

  return (
    <AlertStyled color='danger' icon unclosable={true}>
      <AlertTitle>{t('monetization.problemsWithProfile')}</AlertTitle>
      <AlertSubTitle>{t('monetization.profileNotMeet')}</AlertSubTitle>
      <TextListWrapper>
        <TextList listItems={listItems} />
      </TextListWrapper>

      <AlertSubTitle>{t('monetization.faceChallenges')}</AlertSubTitle>
      <LinkWrapper>
        <DottedBtn
          onClick={handleClickSupport}
          content={t('monetization.contactSupport')}
        />
      </LinkWrapper>
    </AlertStyled>
  )
}
