import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

import { ButtonType } from './DottedButtonProps'

interface StyledComponentsProps {
  type: ButtonType
}

const getColor = (type: ButtonType): string => {
  if (type === 'regular') {
    return colors.mainText
  }
  if (type === 'danger') {
    return colors.messegesRed
  }

  if (type === 'green') {
    return colors.green
  }

  if (type === 'softRegular') {
    return colors.greyTextWhiteBg
  }

  if (type === 'warning') {
    return colors.badgeOrange
  }

  return colors.mainText
}

export const StyledButton = styled.div`
  width: auto;

  .ant-btn[disabled] {
    background: transparent;
  }

  button {
    width: 100%;
    outline: none;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    height: 40px;
    padding: 1px;
    cursor: pointer;
    transition: all 0.2s;
    background: transparent;

    &:hover {
      background: transparent;
    }
    &:active {
      background: transparent;
    }
    &:focus {
      background: transparent;
    }
  }
`

export const ButtonTitle = styled.span<StyledComponentsProps>`
  font-size: 14px;
  line-height: 24px;
  color: ${({ type }) => getColor(type)};
  border-bottom: 1px dashed ${({ type }) => getColor(type)};
`
