import { FC } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TiktokCampaignStatus } from 'src/Types'
import SurveyForEndedCampaign from 'src/Components/SideBarSurveys/SurveyForEndedCampaign'
import { getTiktokCampaignDetails } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { getUserEmail } from 'src/Redux/auth-process/userSlice/selectors'
import { getClosedTypeformIds } from 'src/Redux/typeform-process'
import { useAppSelector } from 'src/Hooks/redux'
import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'

export const EndedCampaignSurvey: FC = () => {
  const { t } = useTranslation()

  const userEmail = useAppSelector(getUserEmail)
  const closedTypeformIds = useSelector(getClosedTypeformIds)

  const campaignDetails = useSelector(getTiktokCampaignDetails)

  const isAppropriateStatus = Boolean(
    campaignDetails?.status &&
      [
        TiktokCampaignStatus.ENDED,
        TiktokCampaignStatus.CANCELED,
        TiktokCampaignStatus.FINISHED,
        TiktokCampaignStatus.STOPPED,
      ].includes(campaignDetails?.status),
  )

  const isClosedCampaign = Boolean(
    closedTypeformIds.includes(String(campaignDetails?.id)),
  )

  const isSurveyShown = isAppropriateStatus && !isClosedCampaign

  if (!isSurveyShown) {
    return null
  }

  return (
    <SurveyForEndedCampaign
      userEmail={userEmail}
      campaignId={`${campaignDetails?.id ?? ''}`}
      campaignType={`${getValueOrEmptyString(campaignDetails?.type)}`}
      campaignName={`${getValueOrEmptyString(campaignDetails?.track?.title) || ''}`}
      isFeed={false}
      id='Dddzi36c'
      text={t('sidebar.shareFeedback')}
    />
  )
}
