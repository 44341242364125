import React, { FunctionComponent, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Alert from 'src/Components/Alert'
import {
  getIsEmailBounced,
  getIsEmailConfirmed,
  getUserEmail,
} from 'src/Redux/auth-process/userSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { colors, variables } from 'src/Styled/variables'
import { settings } from 'src/Router/routes'
import DottedButton from 'src/Components/DottedButton'
import { useLazySendEmailToConfirmAccountQuery } from 'src/Redux/auth-process/emailApi'

export const DescriptionText = styled.span`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
`

export const TitleText = styled.span`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`

export const HighlightedLink = styled(Link)`
  color: ${colors.badgeOrange};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};

  &:hover,
  &:focus,
  &:active {
    color: ${colors.badgeOrange};
  }
`

export const StyledDottedLink = styled(Link)`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  line-height: 1.5;
  padding-bottom: 2px;
  border-bottom: 1px dashed ${colors.mainText};

  &:hover,
  &:visited {
    color: ${colors.mainText};
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 24px;
`

const CHANGE_EMAIL_LINK = `${settings}/email`

export const EmailNotConfirmedAlert: FunctionComponent = () => {
  const { t } = useTranslation()
  const [isMailSent, setIsMailSent] = useState(false)
  const [sendEmail, { isLoading }] = useLazySendEmailToConfirmAccountQuery()

  const isEmailBounced = useAppSelector(getIsEmailBounced)
  const isEmailConfirmed = useAppSelector(getIsEmailConfirmed)
  const email = useAppSelector(getUserEmail)

  const onSendAgain = (): void => {
    sendEmail().then(() => {
      setIsMailSent(true)
    })
  }

  if (isEmailConfirmed || isEmailBounced) {
    return null
  }

  return (
    <Alert color='warning' icon>
      <div>
        <TitleText>{t('campaignsPage.emailConfirmation')}</TitleText>
        <TitleWrapper>
          <DescriptionText>
            {t('campaignsPage.checkEmail', { email })}
          </DescriptionText>
          <HighlightedLink to={CHANGE_EMAIL_LINK}>
            {t('campaignsPage.hereButton')}
          </HighlightedLink>
        </TitleWrapper>
      </div>

      <ButtonsWrapper>
        {!isMailSent && (
          <>
            <DottedButton
              title={'Send again'}
              type={'warning'}
              onClick={onSendAgain}
              disabled={isLoading}
            />
            <StyledDottedLink to={CHANGE_EMAIL_LINK}>
              {t('campaignsPage.changeEmailButton')}
            </StyledDottedLink>
          </>
        )}
        {isMailSent && (
          <DescriptionText>
            {t('campaignsPage.checkEmailSuccess')}
          </DescriptionText>
        )}
      </ButtonsWrapper>
    </Alert>
  )
}
