import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { colors } from 'src/Styled/variables'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { SmartImage } from 'src/Components/SmartImage'
import track from 'src/Assets/Png/track.png'

export const Wrapper = styled.div`
  display: flex;
  max-width: 600px;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: ${colors.white};
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
`

export const TrackNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`

export const TrackInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

export const TrackLink = styled(Link)`
  display: flex;
  height: 40px;
  padding: 0 40px;
  align-items: center;
  border-radius: 27px;
  background: ${colors.red};
  color: ${colors.white};

  &:hover {
    color: ${colors.white};
  }
`

export const TrackCover = styled(SmartImage)`
  width: 70px;
  height: 70px;
  border-radius: 4px;
  overflow: hidden;
`

interface TrackInfoProps {
  trackCoverUrl: string
  trackAuthorName: string
  trackName: string
  trackTiktokUrl: string
}

export const TrackInfo: FunctionComponent<TrackInfoProps> = ({
  trackCoverUrl,
  trackAuthorName,
  trackName,
  trackTiktokUrl,
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <TrackCover src={trackCoverUrl} alt='Track cover' fallbackSrc={track} />
      <ContentWrapper>
        <TrackInfoWrapper>
          <TrackNameWrapper>
            <span>{trackAuthorName}</span>
            <span>{trackName}</span>
          </TrackNameWrapper>
          <TikTokIcon width={14.4} height={16} />
        </TrackInfoWrapper>

        <TrackLink
          to={trackTiktokUrl}
          target={'_blank'}
          rel='noopener noreferrer'
        >
          {t('soundsPage.openOnTikTok')}
        </TrackLink>
      </ContentWrapper>
    </Wrapper>
  )
}
