import { ReviewFormValues } from 'src/Containers/TrackToReview/TrackToReviewForm/helpers'
import {
  reviewMadeEvent,
  trackAddedToPlaylistEvent,
} from 'src/Helpers/TagManager'
import { ReviewType } from 'src/Types'
import { useAppSelector } from 'src/Hooks/redux'

import { getUserId } from '../auth-process/userSlice/selectors'

import { useSendFeedReviewMutation } from '.'

type Body = ReviewFormValues & {
  id?: string
  reward?: number
  trackId?: number
}

interface SendReview {
  sendReview: (data: Body) => Promise<void>
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
}

export const useSendReview = (): SendReview => {
  const userId = useAppSelector(getUserId)
  const [sendFeedReview, { isLoading, isError, isSuccess }] =
    useSendFeedReviewMutation()

  const sendReview = async (data: Body): Promise<void> => {
    await sendFeedReview({
      position: Number(data.reviewPlaylistPositionSelect) - 1,
      campaign_id: String(data.id),
      comment: String(data.reviewText),
      value: data.reviewPlaylistSelect,
      recommendations: data.reviewRecommendations || null,
    })
      .unwrap()
      .then((res) => {
        const addAction = data?.reviewPlaylistSelect?.type === ReviewType.add
        userId &&
          reviewMadeEvent(
            String(data.id),
            addAction,
            String(userId),
            new Date().getTime(),
            Number(data.reward),
            !addAction && Number(data.reviewPlaylistSelect.id),
            String(data.reviewText),
          )
        addAction &&
          userId &&
          trackAddedToPlaylistEvent(
            String(data.id),
            String(userId),
            new Date().getTime(),
            Number(data.reward),
            Number(data.trackId),
            Number(data.reviewPlaylistPositionSelect),
          )
        return res
      })
  }
  return { sendReview, isLoading, isError, isSuccess }
}
