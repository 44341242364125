import { FC } from 'react'

import { useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useNavigate } from 'react-router-dom'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { getTracks } from 'src/Redux/spotifyTracks-process/selectors'
import { getTikTokTracksSelector } from 'src/Redux/tiktokTrack-process/selectors'
import { create } from 'src/Router/routes'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import CheckTrackFromSearch from '../../../CheckTrackFromSearch'

import { WrapperRelative } from './styles'

interface DropdownResultProps {
  platform?: CampaignPlatformTypeEnum
  setTracksToInitialStateHandler: () => void
}

export function extractIdFromUrl(url?: string): string | null {
  const spotifyPattern =
    /https:\/\/api\.spotify\.com\/v1\/tracks\/([a-zA-Z0-9]+)/
  const tiktokPattern = /https:\/\/www\.tiktok\.com\/music\/[^-]+-(\d+)/

  let match = url?.match(spotifyPattern)
  if (match) {
    return match[1]
  }

  match = url?.match(tiktokPattern)
  if (match) {
    return match[1]
  }

  return null
}

export const DropdownResult: FC<DropdownResultProps> = ({
  platform,
  setTracksToInitialStateHandler,
}) => {
  const tikTokTracks = useSelector(getTikTokTracksSelector)
  const spotifyTracks = useSelector(getTracks)
  const navigate = useNavigate()
  const { search } = useCustomSearchParams()

  const isTracksAvailable = (spotifyTracks || tikTokTracks) && platform

  const currentTracks =
    platform === CampaignPlatformTypeEnum.SPOTIFY ? spotifyTracks : tikTokTracks

  if (!isTracksAvailable) {
    return null
  }

  const handleChooseTrack = (id: string): void => {
    if (!id) {
      return
    }
    navigate(`${create}/${platform}/${id}?${search.toString()}`)
  }

  return (
    <OutsideClickHandler onOutsideClick={setTracksToInitialStateHandler}>
      <WrapperRelative>
        <CheckTrackFromSearch
          onCheckTrack={handleChooseTrack}
          tracks={currentTracks}
          platform={platform}
        />
      </WrapperRelative>
    </OutsideClickHandler>
  )
}
