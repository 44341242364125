import { FC } from 'react'

import { formatDate } from 'src/Constants/formatDate'
import dayjs from 'src/Helpers/dayjs'

import { DateWrapper } from './styles'

interface CampaignStoppedProps {
  endDate?: string | Date
  startDate?: string | Date
}

export const CampaignStopped: FC<CampaignStoppedProps> = ({
  endDate,
  startDate,
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return (
    <DateWrapper>
      {dayjs(startDate).tz(timezone).format(formatDate.formatDateMonthFull)}
      &nbsp;-&nbsp;
      {dayjs(endDate).tz(timezone).format(formatDate.formatDateMonthFull)}
    </DateWrapper>
  )
}
