import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { EarningRange } from 'src/Containers/Monetization/components/EarningRange'
import { colors, variables } from 'src/Styled/variables'

import { EarningRangeUpdatedAlert } from '../../../EarningRangeUpdatedAlert'

export const Title = styled.h3`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export const Description = styled.p`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`

export const RangeWrapper = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

interface RangeProps {
  isRangeOutdated?: boolean
  minViews: number
  maxViews: number
  maxCreatorReward: number
  minCreatorReward: number
}

export const Range: FunctionComponent<RangeProps> = ({
  isRangeOutdated,
  minViews,
  maxViews,
  maxCreatorReward,
  minCreatorReward,
}) => {
  const { t } = useTranslation()

  const formattedMaxCreatorReward = `$${maxCreatorReward.toFixed(2)}`

  return (
    <>
      {isRangeOutdated && <EarningRangeUpdatedAlert />}

      {!isRangeOutdated && (
        <RangeWrapper>
          <InfoWrapper>
            <Title>
              {t('soundsPage.earnUpTo')} {formattedMaxCreatorReward}
            </Title>
            <Description>
              {t('soundsPage.rewardDescription', {
                formattedMaxCreatorReward,
              })}
            </Description>
          </InfoWrapper>
          <EarningRange
            minViews={minViews}
            maxViews={maxViews}
            minCreatorReward={minCreatorReward}
            maxCreatorReward={maxCreatorReward}
          />
        </RangeWrapper>
      )}
    </>
  )
}
