import { FunctionComponent, ReactNode } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'

import SoonInfluencerScreenIcon from 'src/Assets/Svg/soonInfluencerScreen.svg?react'
import InfinityLoader from 'src/Components/InfinityLoader'

import {
  LoaderWrapper,
  SoonContainer,
  SoundsListContainer,
  Text,
} from './styles'

interface SoundsListProps {
  soundsAmount: number
  onLoadMore: () => void
  isLoading: boolean
  hasNextPage: boolean
  children: ReactNode
}

export const SoundsList: FunctionComponent<SoundsListProps> = ({
  soundsAmount,
  onLoadMore,
  isLoading,
  hasNextPage,
  children,
}) => {
  const { t } = useTranslation()

  const loadMore = (): void => {
    onLoadMore()
  }

  const hasSounds = soundsAmount > 0

  if (soundsAmount === 0 && isLoading) {
    return (
      <SoundsListContainer>
        <LoaderWrapper>
          <InfinityLoader isLoading={true} />
        </LoaderWrapper>
      </SoundsListContainer>
    )
  }

  return (
    <SoundsListContainer>
      {!hasSounds && (
        <SoonContainer>
          <SoonInfluencerScreenIcon />
          <Text>{t('soundsPage.noVideosMessage')}</Text>
        </SoonContainer>
      )}

      {hasSounds && (
        <InfiniteScroll
          dataLength={soundsAmount}
          next={loadMore}
          hasMore={hasNextPage}
          loader={
            <LoaderWrapper>
              <InfinityLoader isLoading={true} />
            </LoaderWrapper>
          }
        >
          {children}
        </InfiniteScroll>
      )}
    </SoundsListContainer>
  )
}
