import { FC } from 'react'

import { CloseIconGrey, SoundCampaignLogo } from 'src/Assets/Svg'

import { PlatformModalHeader, CloseButtonInPlatform } from './styles'

interface PlatformHeaderProps {
  onBackClick: () => void
}

export const PlatformHeader: FC<PlatformHeaderProps> = ({ onBackClick }) => (
  <PlatformModalHeader>
    <SoundCampaignLogo />
    <CloseButtonInPlatform onClick={onBackClick}>
      <CloseIconGrey />
    </CloseButtonInPlatform>
  </PlatformModalHeader>
)
