import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Button from 'src/Components/Buttons/Button'
import { WhatArtistsThink } from 'src/Components/WhatArtistsThink'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { ButtonWrapper } from './styles'

export const InfoForNotAuthUser: FC = () => {
  const { t } = useTranslation()
  const { platform } = useParams<{
    platform: CampaignPlatformTypeEnum
  }>()

  const scrollToScrollElement = (): void => {
    const scrollElement = document.getElementById('scroll')

    if (scrollElement) {
      scrollElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }
  if (platform === CampaignPlatformTypeEnum.TIKTOK) {
    return null
  }

  return (
    <>
      <WhatArtistsThink />
      <ButtonWrapper>
        <Button type='green' onClick={scrollToScrollElement}>
          {t('campaignsPage.createNewCampaignBtn')}
        </Button>
      </ButtonWrapper>
    </>
  )
}

InfoForNotAuthUser.displayName = 'InfoForNotAuthUser'
