import { useParams } from 'react-router-dom'

import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import {
  EventDismissReasonsEnum,
  useSubmittedSoundByIdQuery,
} from 'src/Redux/creatorSubmittedSoundsApi'

import { SoundStatus } from '../../../../../../hooks/useSoundStatus'

interface IAlerts {
  isMonitoringFailedAlert: boolean
  isTaskNotCompletedAlert: boolean
  isViewsNotAchievedAlert: boolean
  isViewsAchievedAlert: boolean
  isTaskCompletedAlert: boolean
}

export const useAlerts = (): IAlerts => {
  const { soundId } = useParams<{ soundId: string }>()

  const { data: soundData } = useSubmittedSoundByIdQuery(Number(soundId))

  const { data } = useGetMonetizationDataQuery()

  const isMonitoringFailedAlert =
    soundData?.status === SoundStatus.SUBMISSION_REJECTED

  const isTaskNotCompletedAlert =
    soundData?.status === SoundStatus.VIDEO_DISMISSED &&
    soundData?.dismissReason === EventDismissReasonsEnum.VIDEO_DELETED

  const isViewsNotAchievedAlert =
    soundData?.status === SoundStatus.VIDEO_DISMISSED &&
    soundData?.dismissReason === EventDismissReasonsEnum.VIEWS_FAILED

  const isViewsAchieved = (soundData?.views || 0) >= (data?.minViews || 0)

  const isViewsAchievedAlert =
    isViewsAchieved &&
    soundData?.isMilestoneDaysPassed &&
    !soundData?.isPaymentReceived &&
    !isTaskNotCompletedAlert

  const isTaskCompletedAlert = isViewsAchieved && soundData?.isPaymentReceived

  return {
    isMonitoringFailedAlert,
    isTaskNotCompletedAlert,
    isViewsNotAchievedAlert,
    isViewsAchievedAlert: Boolean(isViewsAchievedAlert),
    isTaskCompletedAlert: Boolean(isTaskCompletedAlert),
  }
}
