import { FC, HTMLProps, MouseEvent } from 'react'

import { useDebouncedCallback } from 'use-debounce'
import styled from 'styled-components'

import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { colors } from 'src/Styled/variables'

interface CrossButtonProps extends HTMLProps<HTMLButtonElement> {
  loading?: boolean
}

const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.2s all linear;

  svg {
    path {
      transition: 0.2s all linear;
      fill: ${colors.mainText};
      opacity: 0.5;
    }
  }

  &:hover {
    svg {
      path {
        fill: ${colors.mainText};
        opacity: 1;
      }
    }
    background: rgba(204, 204, 204, 0.2);
  }

  &:active {
    background: rgba(204, 204, 204, 0.3);
  }
`

export const CrossButton: FC<CrossButtonProps> = (props) => {
  const { onClick, className } = props

  const debouncedClick = useDebouncedCallback(
    (event) => {
      onClick?.(event as MouseEvent<HTMLButtonElement>)
    },
    0,
    { trailing: true },
  )

  return (
    <CloseButton onClick={debouncedClick} className={className}>
      <CloseCross width={16} height={16} />
    </CloseButton>
  )
}
