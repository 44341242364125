import React, { FC } from 'react'

import { Skeleton } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-rows: 40px repeat(2, auto);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  height: 322px;
  width: 100%;
`
const StyledSkeleton = styled(Skeleton.Button)`
  &.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
    height: 100%;
    width: 100%;
  }
`

const Title = styled(StyledSkeleton)`
  grid-area: 1 / 1 / 2 / 3;
  &.ant-skeleton.ant-skeleton-block {
    height: 40px;
  }
`
const Bar = styled(StyledSkeleton)`
  grid-area: 2 / 1 / 4 / 2;
  overflow: hidden;
`
const FirstContainer = styled(StyledSkeleton)`
  grid-area: 2 / 2 / 3 / 3;
`
const SecondContainer = styled(StyledSkeleton)`
  grid-area: 3 / 2 / 4 / 3;
`

type CampaignBudgetSkeletonProps = React.HTMLAttributes<HTMLDivElement>

export const CampaignBudgetSkeleton: FC<CampaignBudgetSkeletonProps> = (
  props,
) => (
  <Container {...props}>
    <Title active block />
    <Bar active block />
    <FirstContainer active block />
    <SecondContainer active block />
  </Container>
)
