import { useCallback, useEffect, useRef } from 'react'

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { paymentCancelledEvent } from 'src/Helpers/TagManager'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { getPaymentMethod } from 'src/Redux/payment-process/selectors'
import { useAppDispatch } from 'src/Hooks/redux'
import { getCampaignStatus } from 'src/Redux/campaign-process/mainOldProcessSlice/api-actions'
import { getAllCampaignsCount, useMetadataQuery } from 'src/Redux/metadataApi'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { usePaymentMethod } from 'src/Helpers/usePaymentMethod'

import { useResetQueryParams } from '../Helpers/useResetQueryParams'

import { useCustomSearchParams } from './useCustomSearchParams'

interface PaymentCancelledEvents {
  handlePaymentCancelledSpotifyEvent: (params: { platformName: string }) => void
}

export const usePaymentCancelledSpotifyEvents = (): PaymentCancelledEvents => {
  const dispatch = useAppDispatch()
  const { data } = useMetadataQuery()
  const param = useParams()
  const campaignId = Number(param.number) || 0
  const user = useSelector(getUserSelector)
  const paymentMethod = useSelector(getPaymentMethod)
  const amountOfCampaigns = getAllCampaignsCount(data)
  const { search } = useCustomSearchParams()
  const paymentCancelled = search.get('payment')
  const priceFromQuery = Number(search.get('price'))
  const isPayPalToken = search.get('token')
  const isPayPalSaved = search.get('isPayPalSaved')

  const initialized = useRef<boolean>(false)

  const resetQueryParams = useResetQueryParams([
    'payment',
    'token',
    'price',
    'isPayPalSaved',
  ])

  const paymentMethodFromFunction = usePaymentMethod(
    isPayPalToken,
    isPayPalSaved,
    paymentMethod,
  )

  const handlePaymentCancelledSpotifyEvent = useCallback(
    async (params: { platformName: string }): Promise<void> => {
      await dispatch(getCampaignStatus({ campaignId }))
        .unwrap()
        .then((res) => {
          paymentCancelledEvent({
            campaignId,
            paymentMethod: paymentMethodFromFunction,
            genresList:
              res.campaign.genres.map((genre) => genre.name).join(', ') || '',
            languagesList:
              res.campaign.languages
                .map((language) => language.name)
                .join(', ') || '',
            userId: user.id,
            userRole: user.role,
            campaignNumber: amountOfCampaigns,
            platformName: params.platformName,
            campaignType: res.campaign.type,
            campaignValue: res.campaign.price ?? priceFromQuery ?? 0,
            paymentAmount: res.campaign.price ?? priceFromQuery ?? 0,
          })

          resetQueryParams()

          return null
        })
    },
    [
      dispatch,
      campaignId,
      isPayPalToken,
      paymentMethod,
      user.id,
      user.role,
      amountOfCampaigns,
      priceFromQuery,
      resetQueryParams,
    ],
  )

  useEffect(() => {
    if (paymentCancelled === 'cancelled' && !initialized.current) {
      initialized.current = true

      void handlePaymentCancelledSpotifyEvent({
        platformName: CampaignPlatformTypeEnum.SPOTIFY,
      })
    }
  }, [handlePaymentCancelledSpotifyEvent, paymentCancelled])

  return {
    handlePaymentCancelledSpotifyEvent,
  }
}
