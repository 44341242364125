import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import {
  MobileBlockWrapper,
  NormalText,
  SmallText,
  ValuesContainer,
} from '../../styles'

interface EarnInfoProps {
  maxCreatorReward?: number
}

export const EarnInfo: FunctionComponent<EarnInfoProps> = ({
  maxCreatorReward,
}) => {
  const { t } = useTranslation()

  const formattedEarnUpToAmount = maxCreatorReward
    ? `$${maxCreatorReward.toFixed(2)}`
    : t('soundsPage.noData')

  return (
    <MobileBlockWrapper>
      <ValuesContainer>
        <SmallText>{t('soundsPage.earnUpTo')}</SmallText>
        <NormalText>{formattedEarnUpToAmount}</NormalText>
      </ValuesContainer>
    </MobileBlockWrapper>
  )
}
