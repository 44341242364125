import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

interface TokenData {
  accessToken: string
  country: string
}
type TokenDataResponse = Pick<TokenData, 'country'> & { access_token: string }

const freeSpotifyTokenApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getFreeSpotifyToken: builder.query<TokenData, void>({
      query: () => `${APP.SERVER}/auth/free-access-spotify-token`,
      transformResponse: (response: TokenDataResponse) => ({
        accessToken: response.access_token,
        country: response.country,
      }),
    }),
  }),
})

export const { useGetFreeSpotifyTokenQuery } = freeSpotifyTokenApi
