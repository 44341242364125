import React, { FC } from 'react'

import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

interface PotentialViewsProps {
  minViews: string
  maxViews: string
}
const StyledNumbers = styled.div`
  color: ${colors.mainText};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
export const PotentialViews: FC<PotentialViewsProps> = ({
  minViews,
  maxViews,
}) => {
  if (!minViews && !maxViews) {
    return <StyledNumbers>-</StyledNumbers>
  }
  if (minViews === maxViews) {
    return <StyledNumbers>{`~${minViews}`}</StyledNumbers>
  }
  return <StyledNumbers>{`${minViews} - ${maxViews}`}</StyledNumbers>
}
