import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { Description, InfoWrapper, Title } from '../../styles'

interface FollowInfoProps {
  comment?: string
}

export const FollowInfo: FunctionComponent<FollowInfoProps> = ({ comment }) => {
  const { t } = useTranslation()
  return (
    <InfoWrapper>
      <Title>{t('soundsPage.followTask')}</Title>
      <Description>{comment || ''}</Description>
    </InfoWrapper>
  )
}
