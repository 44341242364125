import { FC, SVGProps } from 'react'

import i18n from 'i18next'

import {
  admin,
  balance,
  campaignsList,
  referAndEarn,
  influencerBoard,
  playlists,
  rank,
  rate,
  tracksFeed,
  subscription,
  monetization,
  sounds,
} from 'src/Router/routes'
import SoundsIcon from 'src/Assets/Svg/sounds.svg?react'
import TracksIcon from 'src/Assets/Svg/tracks.svg?react'
import RateIcon from 'src/Assets/Svg/rate.svg?react'
import IconCampaigns from 'src/Assets/Svg/campaigns.svg?react'
import IconRefer from 'src/Assets/Svg/refer.svg?react'
import IconFeed from 'src/Assets/Svg/feed.svg?react'
import IconAdminPanel from 'src/Assets/Svg/adminPanel.svg?react'
import IconBalance from 'src/Assets/Svg/balance.svg?react'
import IconRank from 'src/Assets/Svg/rank.svg?react'
import IconPlaylists from 'src/Assets/Svg/playlists.svg?react'
import IconMonetization from 'src/Assets/Svg/monetization.svg?react'
import { Roles } from 'src/Constants/enums'
import { APP } from 'src/Configs/App'
import { StarIcon } from 'src/Assets/Svg'

interface Values {
  awaitingReviewTotal: number
  feedReviewsTotal: number
  isNewFeed: boolean
  balance: string
  level: number | null
  awaitingReviewTracksCount: number
}

export interface NavigationItem {
  title: string
  navigationIcon: FC<SVGProps<SVGSVGElement>>
  route: string
  reviewsLength?: number
  isNew?: boolean
  balance?: string
  level?: number | null
  isGreen?: boolean
  isPremiumFeature?: boolean
  isHidden?: boolean
}
const BALANCE = 'sidebar.balance'
export const getCuratorNavigationList = (values: Values): NavigationItem[] => [
  {
    navigationIcon: IconFeed,
    title: i18n.t('sidebar.tracksFeed'),
    route: tracksFeed,
    reviewsLength: values.feedReviewsTotal,
    isNew: values.isNewFeed,
  },
  {
    title: i18n.t('sidebar.rank'),
    navigationIcon: IconRank,
    route: rank,
  },
  {
    title: i18n.t(BALANCE),
    navigationIcon: IconBalance,
    route: balance,
    balance: values.balance,
  },

  {
    title: i18n.t('sidebar.playlists'),
    navigationIcon: IconPlaylists,
    route: playlists,
  },
  {
    title: 'Admin Panel',
    navigationIcon: IconAdminPanel,
    route: admin,
  },
]

export const getArtistNavigationList = (): NavigationItem[] => [
  {
    title: i18n.t('sidebar.premium'),
    navigationIcon: StarIcon,
    route: subscription,
    isGreen: true,
    isPremiumFeature: true,
  },
  {
    title: i18n.t('sidebar.campaigns'),
    navigationIcon: IconCampaigns,
    route: campaignsList,
  },
  {
    title: i18n.t('sidebar.referAndEarn'),
    navigationIcon: IconRefer,
    route: referAndEarn,
  },

  {
    title: i18n.t(BALANCE),
    navigationIcon: IconBalance,
    route: balance,
  },
  {
    title: 'Admin Panel',
    navigationIcon: IconAdminPanel,
    route: admin,
  },
]

export const getInfluencerNavigationList = (
  values: Values,
): NavigationItem[] => [
  {
    title: i18n.t('sidebar.tracks'),
    navigationIcon: TracksIcon,
    route: influencerBoard,
  },
  {
    title: i18n.t('sidebar.sounds'),
    navigationIcon: SoundsIcon,
    route: sounds,
    isNew: true,
  },
  {
    title: i18n.t(BALANCE),
    navigationIcon: IconBalance,
    route: balance,
    balance: values.balance,
  },
  {
    title: i18n.t('sidebar.rate'),
    navigationIcon: RateIcon,
    route: rate,
    level: values.level,
    isHidden: true,
  },
  {
    title: i18n.t('sidebar.monetization'),
    navigationIcon: IconMonetization,
    route: monetization,
    isNew: true,
  },
]

interface Params {
  userRole: Roles
  email: string
  values: Values
  hasPlan: boolean
}

export const getMenu = (params: Params): NavigationItem[] => {
  const { userRole: role, email, values, hasPlan } = params
  let menu: NavigationItem[] = []

  switch (role) {
    case Roles.artist:
      menu = getArtistNavigationList()
      break
    case Roles.curator:
      menu = getCuratorNavigationList(values)
      break
    case Roles.influencer:
      menu = getInfluencerNavigationList(values)
      break
    default:
      break
  }

  if (role === Roles.artist && hasPlan) {
    menu = menu.filter((item) => item.route !== subscription)
  }

  if (!APP?.admin_email?.includes(email)) {
    menu = menu.filter((item) => item.route !== admin)
  }

  return menu.filter((item) => !item.isHidden)
}
