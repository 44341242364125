import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/Hooks/redux'
import avatar from 'src/Assets/Png/ava.png'
import TiktokIcon from 'src/Assets/Svg/tiktok.svg?react'
import dayjs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'
import { SmartImage } from 'src/Components/SmartImage'
import { getVideo } from 'src/Redux/campaignVideo-process/video-process/selectors'

import {
  CustomDivider,
  InfoWrapper,
  StyledFollowers,
  SubmissionDate,
  SubmissionTitle,
  TopDetailsWrapper,
  UserPictureContainer,
} from './styles'

export const CreatorInfo: FC = () => {
  const { t } = useTranslation()
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const video = useAppSelector(getVideo)

  const submissionDate = dayjs(video?.videoUploadDate || dayjs())
    .tz(timezone)
    .format(formatDate.formatDateFirst2)

  return (
    <TopDetailsWrapper>
      <InfoWrapper>
        <UserPictureContainer>
          <SmartImage
            src={video?.userPhotoUrl}
            alt='userpic'
            fallbackSrc={avatar}
          />
        </UserPictureContainer>
        <div>
          <div>{video?.nickname}</div>
          <StyledFollowers>
            {video?.followerCount} {t('campaignResultsPage.followers')}
          </StyledFollowers>
        </div>
        <TiktokIcon width='21.6px' height='24px' />
      </InfoWrapper>
      <CustomDivider />
      <div>
        <SubmissionTitle>
          {t('campaignResultsPage.submissionDate')}
        </SubmissionTitle>
        <SubmissionDate>{submissionDate}</SubmissionDate>
      </div>
    </TopDetailsWrapper>
  )
}
