import styled from 'styled-components'
import { Modal } from 'antd'

import { colors, device } from 'src/Styled/variables'

export const SpotifyForArtistButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto 20px;
  @media ${device.mobileMin} {
    & > div {
      width: 40%;
    }
  }
`
export const GoToSpotifyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

export const StyledModal = styled(Modal)`
  top: 0;

  .ant-modal-content {
    border-radius: 8px;
    padding: 0;
  }
  .ant-modal-header {
    padding: 16.5px 24px;
    background-color: ${colors.green};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-modal-title {
    color: ${colors.white};
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media ${device.mobileMin} {
    top: 50px;
  }
  @media ${device.smallScreen} {
    width: 600px !important;
  }
`

export const Wrapper = styled.div`
  margin: 32px 25px;
  text-align: left;
  padding-bottom: 32px;
`
export const Text = styled.p`
  line-height: 150%;
  margin-bottom: 42px !important;
`
export const Block = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;

  @media screen and (min-width: 1330px) {
    flex-direction: row;
    padding-left: 44px;
  }
`
export const LeftBlock = styled.div`
  min-width: 260px;
`
export const List = styled.ol`
  line-height: 150%;
  margin-bottom: 14px;
`
export const TextCopied = styled.div`
  color: ${colors.green};
`
export const SuccessButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const Video = styled.div`
  text-align: center;
  margin-bottom: 10px;
  @media (min-width: 1330px) {
    padding-right: 44px;
    padding-left: 44px;
  }
`
export const CopyButton = styled.button`
  margin: 0 auto 10px;
  border-bottom: 1px dashed ${colors.mainText};
  background-color: ${colors.white};
  padding-left: 0px;
  @media screen and (min-width: 680px) {
    margin-bottom: 2px;
  }
`
export const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  background-color: ${colors.white};
  height: 42px;
  padding: 10px 16px;
  border: 1px solid ${colors.greyStroke};
  border-radius: 8px;
  color: ${colors.greyTextWhiteBg};
  outline: none;
`
