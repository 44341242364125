import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import { ProblemsWithTikTokErrorAlert } from 'src/Components/ProblemsWithTikTokErrorAlert'
import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import { getValueOrZero } from 'src/Constants/functions'

import { DetailsTitle } from '../DetailsTitle'
import { Player } from '../Player'
import { FollowInfo } from '../FollowInfo'
import { Range } from '../Range'
import { Container, Content, StyledButton } from '../../styles'

import { useNewSoundDetails } from './useNewSoundDetails'

export const NewSoundDetails = (): ReactElement => {
  const { t } = useTranslation()

  const { data: monetizationData } = useGetMonetizationDataQuery()

  const {
    monetizationErrors,
    onClickAcceptSound,
    newSoundData,
    isDataOutdated,
  } = useNewSoundDetails()

  return (
    <Container>
      <DetailsTitle
        badgeTitle={t('soundsPage.newSound')}
        badgeType={'regular'}
        title={t('soundsPage.detailsTitle')}
      />

      {monetizationErrors?.eligibleMedian && <ProblemsWithTikTokErrorAlert />}

      <Content>
        {newSoundData && (
          <Player
            trackAuthorName={newSoundData.trackAuthorName}
            trackPlayUrl={newSoundData.trackPlayUrl}
            trackTitle={newSoundData.trackTitle}
            trackCoverUrl={newSoundData.trackCoverUrl}
            trackUrl={newSoundData.trackTiktokUrl}
          />
        )}

        <FollowInfo comment={newSoundData?.comment} />
        <Range
          isRangeOutdated={isDataOutdated}
          maxCreatorReward={getValueOrZero(monetizationData?.maxCreatorReward)}
          maxViews={getValueOrZero(monetizationData?.maxViews)}
          minCreatorReward={getValueOrZero(monetizationData?.minCreatorReward)}
          minViews={getValueOrZero(monetizationData?.minViews)}
        />
        <StyledButton
          disabled={monetizationErrors?.eligibleMedian || isDataOutdated}
          type={'green'}
          onClick={onClickAcceptSound}
        >
          {t('soundsPage.acceptSound')}
        </StyledButton>
      </Content>
    </Container>
  )
}
