import { FC, useMemo } from 'react'

import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { monetization, sounds } from 'src/Router/routes'
import { getIsCreator } from 'src/Redux/auth-process/credentialsSlice/selectors'

import { Link, Wrapper } from './styles'

const HELP_CENTER_LINK =
  'https://help.soundcamps.com/en/articles/9799252-views-based-reward-model'

export const SoundMonetizationAlert: FC = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const isCreator = useSelector(getIsCreator)

  // For exclude alert from details modal
  const isNotificationDisplayed = useMemo(() => {
    const pathArray = pathname.split('/')
    const firstPathName = `/${pathArray[1]}`
    const isRoute = [monetization, sounds].includes(firstPathName)
    const lastPath = Number(pathArray[pathArray.length - 1])
    return isRoute && isNaN(lastPath)
  }, [pathname])

  const alertContent = useMemo(() => {
    const helpCenterLink = (
      <Link href={HELP_CENTER_LINK} target='_blank'>
        {t('levelsPage.helpCenter')}
      </Link>
    )

    if (isNotificationDisplayed) {
      return {
        text: (
          <span>
            {t('soundsPage.introAlert')} {helpCenterLink}
          </span>
        ),
        type: 'green',
      }
    }

    return null
  }, [t, isNotificationDisplayed])

  if (!isCreator) {
    return null
  }

  if (alertContent) {
    return (
      <Wrapper alertType={alertContent.type}>
        <span>{alertContent.text} </span>
      </Wrapper>
    )
  }
  return null
}
