import { FC } from 'react'

import styled from 'styled-components'

import track from 'src/Assets/Png/track.png'
import { SmartImage } from 'src/Components/SmartImage'
import { colors, variables } from 'src/Styled/variables'

import { TrackDetailsProps } from './TrackDetails'

const Body = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
`
const Cover = styled(SmartImage)`
  width: 80px;
  height: 80px;
  border-radius: 4px;
`

const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 0 0;
`

const TrackInfoText = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const TrackInfoNameText = styled(TrackInfoText)`
  font-weight: ${variables.fontWeight400};
`
const TrackInfoTimeText = styled(TrackInfoNameText)`
  color: ${colors.greyTextWhiteBg};
`

type Props = Pick<
  TrackDetailsProps,
  'coverUrl' | 'trackName' | 'trackAuthorName' | 'duration'
>

export const TrackDetailsBody: FC<Props> = ({
  coverUrl,
  trackName,
  trackAuthorName,
  duration,
}) => (
  <Body>
    <Cover src={coverUrl} alt='track cover' fallbackSrc={track} />
    <TrackInfo>
      <TrackInfoText>{trackName}</TrackInfoText>
      <TrackInfoNameText>{trackAuthorName}</TrackInfoNameText>
      <TrackInfoTimeText>{duration}</TrackInfoTimeText>
    </TrackInfo>
  </Body>
)
