import styled from 'styled-components'

import { TiktokCampaignStatus } from 'src/Types/index'
import { CampaignStatus } from 'src/Constants/enums'
import { colors, device, variables } from 'src/Styled/variables'

interface Props {
  status: string
}

const handleStatusColor = (status: string): string => {
  const redStatuses = [
    CampaignStatus.ENDED,
    CampaignStatus.FINISHED,
    CampaignStatus.CANCELED,
    CampaignStatus.STOPPED,
    TiktokCampaignStatus.CANCELED_BY_ADMIN,
    TiktokCampaignStatus.STOPPED_BY_ADMIN,
  ]

  const yellowStatuses = [
    CampaignStatus.PENDING_APPROVAL,
    CampaignStatus.AWAITING_REVIEW,
  ]

  const green1Statuses = [CampaignStatus.APPROVED, CampaignStatus.PENDING]

  const greenStatuses = [
    CampaignStatus.SCHEDULED,
    CampaignStatus.RUNNING,
    CampaignStatus.INPROCESS,
  ]

  if (redStatuses.includes(status as CampaignStatus)) {
    return colors.red
  }

  if (yellowStatuses.includes(status as CampaignStatus)) {
    return colors.messegesYellow1
  }

  if (green1Statuses.includes(status as CampaignStatus)) {
    return colors.messegesGreen1
  }

  if (greenStatuses.includes(status as CampaignStatus)) {
    return colors.green
  }

  return colors.mainText
}

export const StyledLi = styled.li`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: repeat(4, minmax(0, auto));
  grid-row-gap: 24px;
  grid-column-gap: 16px;
  align-items: start;
  padding: 24px;
  margin-bottom: 8px;
  background-color: ${colors.white};
  border-radius: 4px;
  @media ${device.mobileMin} {
    grid-template-rows: repeat(4, minmax(0, auto));
  }
  @media ${device.smallScreen} {
    grid-template-columns: 80px 1fr 180px 1fr 194px;
    grid-template-rows: none;
    align-items: center;
    background-color: ${colors.greyBg};
    margin-bottom: 2px;
  }
`

export const StyledIcon = styled.div`
  display: grid;
  gap: 8px;
  justify-items: center;
  align-items: center;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row: 1 / -1;
  @media ${device.smallScreen} {
    grid-auto-flow: column;
    gap: 16px;
  }
`
interface PropsStatus {
  withoutReviews?: boolean
}

export const StyledItem = styled.div<PropsStatus>`
  line-height: 19px;
  display: ${(props: PropsStatus) => (props.withoutReviews ? 'none' : 'block')};
  @media ${device.smallScreen} {
    display: block;
  }
`

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  @media ${device.smallScreen} {
    grid-column-start: 1;
  }
`

export const ImgWrapper = styled.div`
  padding-top: 3px;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  & img {
    border-radius: 4px;
  }
  @media ${device.smallScreen} {
    padding-top: 0px;
  }
`

export const NamesWrapper = styled.div`
  display: grid;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
`

export const Name = styled.div`
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export const Artist = styled.div`
  font-size: ${variables.fontSize10};
  line-height: 150%;
`

export const Status = styled.div<{ status: string }>`
  color: ${(props: Props) => handleStatusColor(props.status)};
`
