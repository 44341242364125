import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

interface CheckReferralCodeResponse {
  firstName: string
}

interface CheckReferralCodeParams {
  referralCode: string | null
}

const signupReferalCodeApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    checkReferralCode: builder.query<
      CheckReferralCodeResponse,
      CheckReferralCodeParams
    >({
      query: ({ referralCode }) => ({
        url: `${APP.TIKTOK_SERVER}/users/noauth/${referralCode}`,
        method: 'get',
      }),
      transformResponse(
        baseQueryReturnValue: CheckReferralCodeResponse,
        _,
        arg,
      ) {
        return {
          firstName: baseQueryReturnValue.firstName,
          code: arg.referralCode,
        }
      },
    }),
  }),
})
export const { useCheckReferralCodeQuery } = signupReferalCodeApi
