import { FC } from 'react'

import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import { getTimeByDuration } from 'src/Helpers/layoutHelpers'
import tikTokIcon from 'src/Assets/Png/TikTok-icon.png'
import { CampaignTypeEnum, TikTokTrack } from 'src/Types'
import { onPlaySearchedTrack } from 'src/Helpers/TagManager/TrackEvent'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'

import { SmartImage } from '../SmartImage'
import { TrackCoverWrapper } from '../TrackAudioPlayer/TrackCoverWrapper'
import { TrackAudioPlayer } from '../TrackAudioPlayer'

import { CheckTrackFromSearchProps } from './CheckTrackFromSearchProps'

import {
  Duration,
  SearchResultsDescription,
  SearchResultsItem,
  SearchResultsText,
  SearchResultsTitle,
} from './styles'

export const SearchResultsTiktokImg = styled(SmartImage)`
  width: 40px;
  height: 40px;
  border-radius: 4px;
`

type SearchResultTiktokItemProps = Pick<
  CheckTrackFromSearchProps,
  'onCheckTrack' | 'tracks'
>

export const SearchResultTiktokItem: FC<SearchResultTiktokItemProps> = ({
  tracks,
  onCheckTrack,
}) => {
  const userId = useAppSelector(getUserId)
  const role = useAppSelector(getUserRole)
  const { pathname } = useLocation()
  const handleEvent = (): void => {
    const campaignType = pathname.includes('campaigns')
      ? CampaignTypeEnum.BASIC
      : CampaignTypeEnum.FIRST
    onPlaySearchedTrack(campaignType, role, userId)
  }
  return (
    <TrackAudioPlayer
      render={({ isPlaying, loading, onClick, currentTrackUrl }) => (
        <>
          {((tracks as TikTokTrack[]) || []).map((item) => (
            <SearchResultsItem
              key={item.trackTiktokId}
              onClick={() => onCheckTrack(item.trackTiktokId)}
            >
              <TrackCoverWrapper
                isPlaying={isPlaying && currentTrackUrl === item.trackPlayUrl}
                loading={loading && currentTrackUrl === item.trackPlayUrl}
                onClick={(e) => {
                  onClick(e, item.trackPlayUrl)
                  if (!isPlaying) {
                    handleEvent()
                  }
                }}
              >
                <SearchResultsTiktokImg
                  src={item.trackCoverUrl}
                  fallbackSrc={tikTokIcon}
                  alt={item.trackTitle}
                  width='40'
                />
              </TrackCoverWrapper>
              <SearchResultsText>
                <SearchResultsTitle>{item.trackTitle}</SearchResultsTitle>
                <SearchResultsDescription>
                  {item.trackAuthorName}
                </SearchResultsDescription>
              </SearchResultsText>
              <Duration>{getTimeByDuration(item.trackDuration)}</Duration>
            </SearchResultsItem>
          ))}
        </>
      )}
    />
  )
}
