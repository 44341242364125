import { createSelector } from '@reduxjs/toolkit'

import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { getValueOrZero } from 'src/Constants/functions'

type PaymentStatuses = 'payed' | 'completed' | 'charged-back'
type SpotifyCampaignsStatuses =
  | 'payment_pending'
  | 'pending_approval'
  | 'approved'
  | 'running'
  | 'scheduled'
  | 'ended'
  | 'canceled'
  | 'stopped'

type TiktokCampaignsStatuses =
  | 'awaiting_review'
  | 'canceled'
  | 'canceled_by_admin'
  | 'ended'
  | 'finished'
  | 'in_progress'
  | 'pending'
  | 'running'
  | 'scheduled'
  | 'stopped'
  | 'stopped_by_admin'

export interface Metadata {
  paymentsCountByStatus: Record<PaymentStatuses, number>
  spotifyCampaignsCountByStatus: Record<SpotifyCampaignsStatuses, number>
  tiktokCampaignsCountByStatus: Record<TiktokCampaignsStatuses, number>
  isNextTimeBonusReceived: boolean
}

const metadataApi = rtkApi.injectEndpoints({
  endpoints(build) {
    return {
      metadata: build.query<Metadata, void>({
        query: () => `${APP.TIKTOK_SERVER}/any-campaign/metadata`,
      }),
    }
  },
})

export const { useMetadataQuery } = metadataApi

export const selectMetadataResult = metadataApi.endpoints.metadata.select()

export const selectPaidCampaignsAmount = createSelector(
  selectMetadataResult,
  ({ data }) =>
    getValueOrZero(data?.paymentsCountByStatus.completed) +
    getValueOrZero(data?.paymentsCountByStatus.payed),
)

const CHARGE_BACK = 'charged-back'

export const getAllCampaignsCount = (state?: Metadata): number => {
  const tikTokCampaignsCount = Object.entries(
    state?.tiktokCampaignsCountByStatus || {},
  ).reduce((accumulator, [, value]) => accumulator + getValueOrZero(value), 0)

  const spotifyCampaignsCount = Object.entries(
    state?.spotifyCampaignsCountByStatus || {},
  ).reduce((accumulator, [, value]) => accumulator + getValueOrZero(value), 0)

  return tikTokCampaignsCount + spotifyCampaignsCount
}

export const getHasPaidCampaignMetadata = (state?: Metadata): boolean => {
  if (!state) {
    return false
  }
  return (
    getValueOrZero(state?.paymentsCountByStatus?.completed) > 0 ||
    getValueOrZero(state?.paymentsCountByStatus?.payed) > 0
  )
}

export const getHasOnePaidCampaignMetadata = (state?: Metadata): boolean => {
  if (!state) {
    return false
  }
  return (
    getValueOrZero(state?.paymentsCountByStatus?.completed) +
      getValueOrZero(state?.paymentsCountByStatus?.payed) ===
    1
  )
}

const MINIMAL_ENDED_CAMPAIGNS_COUNT = 3
export const getIsSubscriptionsUpsellAvailableSelector = (
  state?: Metadata,
): boolean => {
  if (!state) {
    return false
  }

  const hasChargebacks = state?.paymentsCountByStatus[CHARGE_BACK] > 0
  const tiktokEnded = state.tiktokCampaignsCountByStatus?.ended || 0
  const spotifyEnded = state.spotifyCampaignsCountByStatus?.ended || 0
  const hasMinimalEndedCampaigns =
    tiktokEnded + spotifyEnded >= MINIMAL_ENDED_CAMPAIGNS_COUNT
  return hasMinimalEndedCampaigns && !hasChargebacks
}

export const getIsCreateTikTokCampaignAvailableSelector = (
  state?: Metadata,
): boolean => {
  if (!state) {
    return false
  }

  const payedSpotifyCampaignsNumbers = [
    state.spotifyCampaignsCountByStatus?.ended,
    state.spotifyCampaignsCountByStatus?.running,
    state.spotifyCampaignsCountByStatus?.scheduled,
  ].reduce((accumulator, value) => accumulator + getValueOrZero(value), 0)

  const payedTikTokCampaignsNumbers = [
    state.tiktokCampaignsCountByStatus?.ended,
    state.tiktokCampaignsCountByStatus?.scheduled,
    state.tiktokCampaignsCountByStatus?.running,
    state.tiktokCampaignsCountByStatus?.in_progress,
    state.tiktokCampaignsCountByStatus?.canceled,
    state.tiktokCampaignsCountByStatus?.stopped,
  ].reduce((accumulator, value) => accumulator + getValueOrZero(value), 0)

  const isStoppedCanceledCampaignsNumbers = [
    state.spotifyCampaignsCountByStatus?.canceled,
    state.spotifyCampaignsCountByStatus?.stopped,
    state.tiktokCampaignsCountByStatus?.canceled,
    state.tiktokCampaignsCountByStatus?.stopped,
  ].reduce((accumulator, value) => accumulator + getValueOrZero(value), 0)

  const isNoStoppedCanceledCampaigns = isStoppedCanceledCampaignsNumbers === 0
  const isNoPayedTikTokCampaign = payedTikTokCampaignsNumbers === 0
  const isPayedSpotifyCampaign = payedSpotifyCampaignsNumbers > 1
  const noChargebacks = state?.paymentsCountByStatus[CHARGE_BACK] === 0

  return (
    noChargebacks &&
    isNoPayedTikTokCampaign &&
    isNoStoppedCanceledCampaigns &&
    isPayedSpotifyCampaign
  )
}

export const hasPayedTikTokCampaigns = (state?: Metadata): boolean => {
  if (!state) {
    return false
  }
  const payedTikTokCampaignsNumbers = [
    state.tiktokCampaignsCountByStatus?.ended,
    state.tiktokCampaignsCountByStatus?.scheduled,
    state.tiktokCampaignsCountByStatus?.running,
    state.tiktokCampaignsCountByStatus?.in_progress,
    state.tiktokCampaignsCountByStatus?.canceled,
    state.tiktokCampaignsCountByStatus?.stopped,
  ].reduce((accumulator, value) => accumulator + getValueOrZero(value), 0)
  return payedTikTokCampaignsNumbers > 0
}

export const getIsDiscoverTikTokCampaignAvailableSelector = (
  state?: Metadata,
): boolean => {
  if (!state) {
    return false
  }

  const payedSpotifyCampaignsStatuses = [
    state.spotifyCampaignsCountByStatus?.ended,
    state.spotifyCampaignsCountByStatus?.running,
    state.spotifyCampaignsCountByStatus?.scheduled,
  ].reduce((accumulator, value) => accumulator + getValueOrZero(value), 0)

  return payedSpotifyCampaignsStatuses === 1 && !hasPayedTikTokCampaigns(state)
}
