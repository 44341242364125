import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const WrapperRelative = styled.div`
  border-radius: 8px;
  width: 100%;
  background-color: ${colors.white};
  @media ${device.mobileMin} {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  }
`
