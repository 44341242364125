import { FC, useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import {
  getIsPageVisited,
  getSidebar,
} from 'src/Redux/sidebar-process/selectors'
import { campaignsList, influencerBoard, tracksFeed } from 'src/Router/routes'
import { getPremiumFeaturesEvent } from 'src/Helpers/TagManager'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { changeMobileSidebarVisibility } from 'src/Redux/sidebar-process'
import { useAppDispatch } from 'src/Hooks/redux'
import { PremiumFeaturesButtonTypeEnum } from 'src/Helpers/TagManager/tagManagerEnums'

import { SidebarLinkProps } from './SidebarLinkProps'
import { useSidebarLink } from './useSidebarLink'
import { SideBarBadge } from './SideBarBadge/SideBarBadge'

import {
  SidebarLinkItem,
  SidebarLinkText,
  SidebarIconBlock,
  NewLabel,
  StyledNavLink,
} from './styles'

const SidebarLink: FC<SidebarLinkProps> = ({
  title,
  route,
  navigationIcon: NavigationIcon,
  reviewsLength,
  balance,
  rank,
  isNew,
  level,
  isGreen,
  isPremiumFeature,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const userId = useSelector(getUserId)
  const { collapse } = useSelector(getSidebar)

  const { pathname } = useLocation()

  const isVisitedNewPage = useSelector(getIsPageVisited(route))
  const isTablet = useMediaQuery({ maxWidth: 1023 })

  useSidebarLink({
    route,
    isNew: Boolean(isNew),
  })

  const handleClick = (): void => {
    if (isTablet) {
      dispatch(changeMobileSidebarVisibility())
    }
    if (isPremiumFeature) {
      getPremiumFeaturesEvent({
        userId,
        buttonType: PremiumFeaturesButtonTypeEnum.LeftSide,
      })
    }
  }

  const isRoute = [campaignsList, influencerBoard, tracksFeed].includes(route)

  const getClassName = useCallback(
    () =>
      (pathname === '/' || pathname === '/login/') && isRoute ? 'active' : '',
    [pathname, isRoute],
  )

  const isNewLabelShowing = isNew && !isVisitedNewPage && !collapse
  const isAdditionInformationShowing = !isNewLabelShowing && !collapse

  return (
    <StyledNavLink
      to={route}
      className={getClassName()}
      $isGreen={isGreen}
      onClick={handleClick}
    >
      <SidebarLinkItem collapse={collapse}>
        <SidebarIconBlock>
          <NavigationIcon />
        </SidebarIconBlock>
        <SidebarLinkText isGreen={isGreen} collapse={!collapse}>
          {title}
        </SidebarLinkText>
        {isNewLabelShowing && (
          <NewLabel collapse={collapse}>{t('sidebar.new')}</NewLabel>
        )}
        {isAdditionInformationShowing && (
          <SideBarBadge
            reviewsLength={reviewsLength}
            level={level}
            rank={rank}
            balance={balance}
          />
        )}
      </SidebarLinkItem>
    </StyledNavLink>
  )
}

export default SidebarLink
