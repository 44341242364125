import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'
import { SliceStatus } from '../types'

import { TrackState } from './index'

const getTrackState = (state: RootState): TrackState => state.spotifyTrack

export const getSpotifyTrackSelector = createDraftSafeSelector(
  getTrackState,
  ({ track }) => track,
)
export const getSpotifyTrackLoadingSelector = createDraftSafeSelector(
  getTrackState,
  ({ loading }) =>
    loading === SliceStatus.Loading || loading === SliceStatus.Idle,
)
