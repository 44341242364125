import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

interface SetupIntent {
  link: string
}
interface CreateSetupIntent {
  successUrl: string
  cancelUrl: string
}

const setupIntentApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    createSetupIntent: builder.mutation<SetupIntent, CreateSetupIntent>({
      query: (body) => ({
        url: `${APP.TIKTOK_SERVER}/payment/stripe/create-setup-intent`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useCreateSetupIntentMutation } = setupIntentApi
